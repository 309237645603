import {inject, Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {ApiUsageEntity, freeTierCredits} from "../../../../.common/usage";
import {docRefToObservable} from "../../../../.common/firestore/observable";
import {isPermissionDeniedError} from "../../../../.common/firestore/errors";
import {ApiUsageCollectionProvider} from "../../../../.common/collections/api-usage-collection-provider";

@Injectable({
  providedIn: "root",
})
export class ApiUsageService {
  apiUsageProvider = new ApiUsageCollectionProvider(inject(AngularFirestore).firestore);

  public getUserUsages(userId: string): Observable<ApiUsageEntity> {
    return docRefToObservable(this.apiUsageProvider.getApiUsageCollection().doc(userId)).pipe(
      catchError((err) =>
        isPermissionDeniedError(err)
          ? of({currentUsage: 0, currentLimit: freeTierCredits} as ApiUsageEntity)
          : of(err),
      ),
    );
  }
}
