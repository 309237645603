import {AiApi} from "./types";
import {AnthropicApi, Cohere, DallE, GooglePalm, OpenAIChat} from "./";
import {ApiType} from "../ai-prompt";
import {StabilityAI} from "./stability-ai";
import {MistralApi} from "./mistral";

export const aiApis = [
  new MistralApi(),
  new AnthropicApi(),
  new DallE(),
  new Cohere(),
  new OpenAIChat(),
  new GooglePalm(),
  new StabilityAI(),
]
  .map((api) => [api.id, api] as [string, AiApi])
  .reduce(
    (acc, [id, api]) => {
      if (acc[id]) {
        throw new Error(`Duplicate model id ${id}`);
      }
      acc[id] = api;
      return acc;
    },
    {} as Record<string, AiApi>,
  );
export const getApiById = <T extends AiApi>(apiType: ApiType): T => {
  const aiApi = aiApis[apiType];
  if (!aiApi) {
    throw new Error(`Api ${apiType} not found`);
  }
  return aiApi as T;
};

export const aiApiExists = (modelId: ApiType): boolean => !!aiApis[modelId];
