import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MatSelectChange} from "@angular/material/select";
import {AiApi, AiModel} from "../../../../../../../../.common/ai-models";

@Component({
  selector: "app-models",
  templateUrl: "./models.component.html",
  styleUrls: ["./models.component.scss"],
})
export class ModelsComponent {
  _api!: AiApi;
  models!: AiModel[];
  @Output() selectedModelChange = new EventEmitter<AiModel>();
  @Input() selectedValue?: string;
  @Input()
  get api() {
    return this._api;
  }
  set api(value: AiApi) {
    this._api = value;
    // we need to do it to avoid infinity loop of detection changes
    this.models = value.models;
  }

  onSelectChange($event: MatSelectChange) {
    const model = this._api.models.filter((m) => m.id === $event.value)[0];
    this.selectedModelChange.emit(model);
  }
}
