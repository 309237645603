import {AiApi, AiModel, AiParameter} from "./types";
import {AiContentType, ApiType} from "../";

import {maxContextLength} from "./model-parameters";
import {ModelCategoryTextGeneration} from "./model-category";

const paramTemperature: AiParameter = {
  key: "temperature",
  displayName: "Temperature",
  description:
    "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperature results in more random completions.",
  type: "range",
  limits: {
    min: 0,
    max: 2,
    step: 0.1,
  },
  defaultValue: 1,
  displayIcon: "device_thermostat",
};

const paramMaxTokens: AiParameter = {
  key: "max_tokens",
  displayName: "Maximum output tokens",
  description:
    "The maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by model. (One token is roughly 4 characters for standard English text)",
  type: "range",
  limits: {
    min: 1,
    max: 4097,
    step: 1,
  },
  defaultValue: 1500,
  displayIcon: "short_text",
};

export class OpenAIChat implements AiApi {
  id: ApiType = "openai-chat";
  contentType: AiContentType = "chat";
  name = "OpenAI";
  description =
    "OpenAI's chat completion model is a language model that is optimized for engaging in conversations. It is capable of generating realistic text and can ask follow-up questions, admit mistakes it has made, and challenge incorrect premises. The chat completion model is designed to reduce the possibility of harmful or deceitful responses. It is a variant of the GPT-3 model and is available through the Chat Completions API. The Chat Completions API is the preferred method for interacting with the GPT-3.5 Turbo and GPT-4 models.";
  models: AiModel[] = [
    {
      id: "gpt-4-0125-preview",
      displayName: "GPT-4",
      description:
        "With broad general knowledge and domain expertise, GPT-4 can follow complex instructions in natural language and solve difficult problems with accuracy.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 0.01, "1K-output": 0.03},
      contentType: "chat",
      parameters: [
        paramTemperature,
        {
          ...paramMaxTokens,
          limits: {
            ...paramMaxTokens.limits,
            max: 4096,
          },
          defaultValue: 4096,
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 128000,
          },
          defaultValue: 128000,
        },
      ],
    },
    {
      id: "gpt-3.5-turbo-0125",
      displayName: "GPT-3.5",
      description:
        "gpt-3.5-turbo is the flagship model of this family, supports a 16K context window and is optimized for dialog.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 0.001, "1K-output": 0.002},
      contentType: "chat",
      parameters: [
        paramTemperature,
        {
          ...paramMaxTokens,
          limits: {
            ...paramMaxTokens.limits,
            max: 4096,
          },
          defaultValue: 4096,
        },
        {
          ...maxContextLength,
          limits: {
            step: 10,
            min: 1,
            max: 16385,
          },
          defaultValue: 16385,
        },
      ],
    },
    {
      id: "gpt-4-1106-preview",
      displayName: "GPT-4 1106 (Outdated)",
      description:
        "With broad general knowledge and domain expertise, GPT-4 can follow complex instructions in natural language and solve difficult problems with accuracy.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 0.01, "1K-output": 0.03},
      contentType: "chat",
      outdated: true,
      parameters: [
        paramTemperature,
        {
          ...paramMaxTokens,
          limits: {
            ...paramMaxTokens.limits,
            max: 4096,
          },
          defaultValue: 4096,
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 128000,
          },
          defaultValue: 128000,
        },
      ],
    },
    {
      id: "gpt-3.5-turbo-1106",
      displayName: "GPT-3.5 1106 (Outdated)",
      description:
        "gpt-3.5-turbo is the flagship model of this family, supports a 16K context window and is optimized for dialog.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 0.001, "1K-output": 0.002},
      outdated: true,
      contentType: "chat",
      parameters: [
        paramTemperature,
        {
          ...paramMaxTokens,
          limits: {
            ...paramMaxTokens.limits,
            max: 4096,
          },
          defaultValue: 4096,
        },
        {
          ...maxContextLength,
          limits: {
            step: 10,
            min: 1,
            max: 16385,
          },
          defaultValue: 16385,
        },
      ],
    },
    // {
    //   id: "gpt-3.5-turbo",
    //   displayName: "GPT-3.5 Turbo (Outdated)",
    //   description:
    //     "GPT-3.5 Turbo is a variant of the GPT-3 model that is optimized for chat completion. It is capable of generating realistic text and can ask follow-up questions, admit mistakes it has made, and challenge incorrect premises. The chat completion model is designed to reduce the possibility of harmful or deceitful responses. It is available through the Chat Completions API.",
    //   prices: {"1K-input": 0.0015, "1K-output": 0.002},
    //   contentType: "chat",
    //   parameters: [paramTemperature, paramMaxTokens],
    // },
    // {
    //   id: "gpt-3.5-turbo-16k",
    //   displayName: "GPT-3.5 Turbo 16k",
    //   description:
    //     "GPT-3.5 Turbo 16k is a variant of the GPT-3 model that is optimized for chat completion. It is capable of generating realistic text and can ask follow-up questions, admit mistakes it has made, and challenge incorrect premises. The chat completion model is designed to reduce the possibility of harmful or deceitful responses. It is available through the Chat Completions API.",
    //   prices: {"1K-input": 0.003, "1K-output": 0.004},
    //   contentType: "chat",
    //   parameters: [
    //     paramTemperature,
    //     {
    //       ...paramMaxTokens,
    //       limits: {
    //         ...paramMaxTokens.limits,
    //         max: 16385,
    //       },
    //     },
    //   ],
    // },
    // {
    //   id: "gpt-4",
    //   displayName: "GPT-4",
    //   description:
    //     "With broad general knowledge and domain expertise, GPT-4 can follow complex instructions in natural language and solve difficult problems with accuracy.",
    //   prices: {"1K-input": 0.03, "1K-output": 0.06},
    //   contentType: "chat",
    //   parameters: [
    //     paramTemperature,
    //     {
    //       ...paramMaxTokens,
    //       limits: {
    //         ...paramMaxTokens.limits,
    //         max: 8192,
    //       },
    //     },
    //   ],
    // },
    // {
    //   id: "gpt-4-32k",
    //   displayName: "GPT-4 32k",
    //   description: "With broad general knowledge and domain expertise, GPT-4 can follow complex instructions in natural language and solve difficult problems with accuracy. 32k tokens limit.",
    //   prices: {"1K": 0.12, "1K-input": 0.06, "1K-output": 0.12}
    // },
  ];
  readonly defaultModelId: string = "gpt-3.5-turbo";

  getModel(modelId: string): AiModel {
    return this.models.find((model) => model.id === modelId) as AiModel;
  }
}

export type OpenAIChatModelParameterKey = "temperature" | "max_tokens";

export type OpenAIChatModel =
  | "gpt-4"
  | "gpt-4-0613"
  | "gpt-4-32k"
  | "gpt-4-32k-0613"
  | "gpt-3.5-turbo"
  | "gpt-3.5-turbo-0613"
  | "gpt-3.5-turbo-16k"
  | "gpt-3.5-turbo-16k-0613";
