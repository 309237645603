<h2>Test responses</h2>
<div *ngIf="apiResponses$ | async as apiResponses; else loading">
  <mat-accordion>
    <div *ngFor="let response of apiResponses">
      <mat-expansion-panel *ngIf="api?.contentType === 'image'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <img
              class="preview"
              *ngIf="isObservable(response.content.tmpUrl)"
              [src]="response.content.tmpUrl | async"
              alt="preview"
            />
            <img
              class="preview"
              *ngIf="!isObservable(response.content.tmpUrl)"
              [src]="response.content.tmpUrl"
              alt="preview"
            />
            <span *ngIf="response.created">
              {{ $any(response.created).seconds + "000" | date: "yyyy/MM/dd h:mm:ss a" }}
            </span>
            <span *ngIf="!response.created"> New response </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="response.usage">
          <i>Api credits usage: {{ response.usage }}</i>
        </div>
        <br />
        <img *ngIf="response.content.url" [src]="response.content.url" alt="image" />
        <div *ngIf="!response.content.url && response.content.tmpUrl">
          <img
            *ngIf="isObservable(response.content.tmpUrl)"
            [src]="response.content.tmpUrl | async"
            alt="image"
          />
          <img
            *ngIf="!isObservable(response.content.tmpUrl)"
            [src]="response.content.tmpUrl"
            alt="image"
          />
        </div>
        <p *ngIf="!response.content.url && !response.content.tmpUrl">
          <mat-icon>error</mat-icon>
          <span>Response image not found</span>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="api?.contentType === 'text'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span *ngIf="response.created">
              {{ $any(response.created).seconds + "000" | date: "yyyy/MM/dd h:mm:ss a" }}:
              {{
                response.content.length > 20
                  ? (response.content | slice: 0 : 20) + "..."
                  : response.content
              }}
            </span>
            <i *ngIf="!response.created"> New response </i>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="response.usage">
          <i>Api credits usage: {{ response.usage }}</i>
        </div>
        <br />
        <markdown [data]="response.content"></markdown>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
