import {inject, Injectable} from "@angular/core";
import {UserSubscriptionsDatabase} from "../../../../../.common/subscriptions/user-subscriptions.database";
import {StripeUserSubscription} from ".common/subscriptions/stripe-user-subscription";
import {Observable} from "rxjs/internal/Observable";
import {UserCollectionProvider} from "../../../../../.common/collections/user-collection-provider";
import {queryToObservable} from "../../../../../.common/firestore/observable";
import {defaultIfEmpty, merge, Subject} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserSubscriptionWebDb implements UserSubscriptionsDatabase {
  #userCollectionProvider = inject(UserCollectionProvider);
  getActiveSubscription(userId: string): Observable<StripeUserSubscription | undefined> {
    const query = this.#userCollectionProvider
      .getActiveUserSubscriptionsCollection(userId)
      .where("status", "==", "active");
    const ifEmpty = new Subject<undefined>();
    query.get().then((snapshot) => (snapshot.empty ? ifEmpty.next(undefined) : ifEmpty.complete()));
    return merge(ifEmpty, queryToObservable(query).pipe(defaultIfEmpty(undefined)));
  }
}
