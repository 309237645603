import {Component, inject, ViewEncapsulation} from "@angular/core";
import {AuthService} from "../../../auth/auth.service";
import {filter, Observable, switchMap, takeUntil, tap} from "rxjs";
import {SubscriptionService} from "../../../../services/subscription.service";
import {Destroyer} from "../../../_helpers/Destroyer";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "../../../../services/user.service";
import {PublicUserInfo} from "../../../../../../.common";
import {createSelector, Store} from "@ngrx/store";
import {authFeature} from "../../../auth/state/auth.state";

@Component({
  selector: "ui-header-profile-menu-icon",
  templateUrl: "./profile-header-menu-icon.component.html",
  styleUrls: ["./profile-header-menu-icon.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileHeaderMenuIconComponent extends Destroyer {
  authService = inject(AuthService);
  subscriptionService = inject(SubscriptionService);
  dialog = inject(MatDialog);
  auth = inject(AuthService);
  userService = inject(UserService);
  // @deprecated
  publicUserData$: Observable<PublicUserInfo | undefined>;
  userId?: string;
  vm = inject(Store).selectSignal(
    createSelector({
      loaded: authFeature.selectLoaded,
      user: authFeature.selectUser,
      publicUserInfo: authFeature.selectPublicUserInfo,
    }),
  );

  constructor() {
    super();
    this.publicUserData$ = this.auth.user$.pipe(
      takeUntil(this.destroy$),
      tap((user) => (this.userId = user?.id)),
      filter((user) => !!user),
      switchMap((user) => this.userService.getPublicUserData(user!.id)),
    );
  }

  async redirectToCustomerPortal() {
    await this.subscriptionService.toCustomerPortal();
  }

  openAuthWindow() {
    return this.authService.openAuthWindow();
  }
}
