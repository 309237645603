<a routerLink="/prompts/{{ prompt.id }}" *ngIf="promptType === 'text'" class="custom-mat-card">
  <mat-card class="text-prompt">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>
          <ui-ai-model [api]="prompt.api"> </ui-ai-model>
          <div class="prompt-title">
              {{ prompt.displayName || prompt.id }}
          </div>
        </mat-card-title>
        <div>
          <mat-icon class="moreIcon colorPrimary80" (click)="openMenu($event)" [matMenuTriggerFor]="moreActions">
            more_horiz
          </mat-icon>
          <mat-menu #moreActions="matMenu">
            <button mat-menu-item>
              <mat-icon class="material-icons-outlined">chevron_right</mat-icon>
              <a routerLink="/prompts/{{ prompt.id }}"> Full description </a>
            </button>
            <button mat-menu-item>
              <mat-icon class="material-icons-outlined">share</mat-icon>
              <a routerLink="/prompts/{{ prompt.id }}"> Share </a>
            </button>
            <button mat-menu-item *ngIf="prompt.ownerId === userId">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
              <a routerLink="/prompts/{{ prompt.id }}/edit"> Edit </a>
            </button>
          </mat-menu>
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="prompt.description" class="description bodySmall">{{ prompt.description }}</div>
    </mat-card-content>
    <mat-card-footer>
      <div class="payedSettingContainer">
        <div class="payedSetting" *ngIf="prompt.publishSettings?.isPayed">
          <div class="fixedPrice colorOrange100" *ngIf="prompt.publishSettings?.fixedPriceAvailable">
            ${{ prompt.publishSettings?.fixedPrice }}
          </div>
          <div
            class="textLabel subscriptionInfo"
            *ngIf="prompt.publishSettings?.subscriptionAvailable"
          >
            Available with a subscription
          </div>
        </div>
      </div>

      <ui-prompt-footer></ui-prompt-footer>
    </mat-card-footer>
  </mat-card>
</a>
<a routerLink="/prompts/{{ prompt.id }}" *ngIf="promptType === 'image'" class="custom-mat-card">
  <mat-card
    [ngClass]="{'no-image': !hasImage}"
    class="image-prompt"
    [style.background-image]="hasImage ? 'url(' + prompt.image + ')' : 'none'"
  >
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>
          <ui-ai-model [api]="prompt.api" [colorClass]="'white-text'"> </ui-ai-model>
          <div class="prompt-title">
              {{ prompt.displayName || prompt.id }}
          </div>
        </mat-card-title>
        <div>
          <mat-icon class="moreIcon colorPrimary80" (click)="openMenu($event)" [matMenuTriggerFor]="moreActions">
            more_horiz
          </mat-icon>
          <mat-menu #moreActions="matMenu">
            <button mat-menu-item>
              <mat-icon class="material-icons-outlined">chevron_right</mat-icon>
              <a routerLink="/prompts/{{ prompt.id }}"> Full description </a>
            </button>
            <button mat-menu-item>
              <mat-icon class="material-icons-outlined">share</mat-icon>
              <a routerLink="/prompts/{{ prompt.id }}"> Share </a>
            </button>
            <button mat-menu-item *ngIf="prompt.ownerId === userId">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
              <a routerLink="/prompts/{{ prompt.id }}/edit"> Edit </a>
            </button>
          </mat-menu>
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="!hasImage" class="replace-image" [ngClass]="{hide: hasDescription}">
        <img src="assets/img/landing/external/no_photography.svg" alt="No image" />
        <p>NO IMAGE AVAILABLE</p>
      </div>
      <div *ngIf="prompt.description" class="description bodySmall">{{ prompt.description }}</div>
    </mat-card-content>
    <mat-card-footer>
      <div class="payedSettingContainer">
        <div class="payedSetting" *ngIf="prompt.publishSettings?.isPayed">
          <div class="fixedPrice colorOrange100" *ngIf="prompt.publishSettings?.fixedPriceAvailable">
            ${{ prompt.publishSettings?.fixedPrice }}
          </div>
          <div
            class="textLabel subscriptionInfo"
            *ngIf="prompt.publishSettings?.subscriptionAvailable"
          >
            Available with a subscription
          </div>
        </div>
      </div>

      <ui-prompt-footer></ui-prompt-footer>
    </mat-card-footer>
  </mat-card>
</a>
