<div class="current-subscription-container">
  <div class="current-subscription-title block">
    <div class="titleLargeColor70">Current Plan</div>
    <div class="bodySmall colorNeutral70">
      Upgrade or downgrade your subscription plan
    </div>
  </div>

  <div class="subscription-current block">
    <div class="subscription-current-items">
      <p class="bodyMediumNeutral60">Current Plan:</p>
      <p class="bodyMediumNeutral60">Status:</p>
      <p class="bodyMediumNeutral60">Activated on:</p>
      <p class="bodyMediumNeutral60">Next billing date:</p>
      <p class="bodyMediumNeutral60">Payments: </p>
      <p class="bodyMediumNeutral60">Credits left</p>
    </div>

    <div *ngIf="userSubscription(); else noSub">
      <p class="bodyMediumNeutral60">{{ subscribedProduct()?.name }}</p>
      <p class="textStatusGreen100">{{ subscribedProduct()?.active ? "Active" : "Inactive" }}</p>
      <p class="bodyMediumNeutral60">{{ (userSubscription()?.current_period_start?.seconds || 0) * 1000 | date: "dd/MM/yyyy"}}</p>
      <p class="bodyMediumNeutral60">{{ (userSubscription()?.current_period_end?.seconds || 0) * 1000 | date: "dd/MM/yyyy"}}</p>
      <p class="bodyMediumNeutral60">{{userSubscription()?.items![0].plan.amount / 100}} {{userSubscription()?.items![0].plan.currency}} per {{userSubscription()?.items![0].plan.interval}}</p>
      <p *ngIf="usage$ | async as usage; else loadingTemplate" class="bodyMediumNeutral60">{{ totalCredits | roundDigits }}/{{ usage.currentLimit }}</p>
    </div>
  </div>
  <div class="need-assistance block">
    <a  href="https://forms.gle/frKqLYq7wJwCgyxc6" target="_blank"><p class="titleMediumPrimary80">I need assistance</p></a>
  </div>
</div>

<div class="select-subscriptions-container">
  <div class="title-subscriptions block">
    <div class="titleLargeColor70">Subscriptions</div>
  </div>
  <div class="change-plan block">
    <a *ngIf="!disablePlanManagement; else loadingTemplateSmall"  [hidden]="!subscribedProduct()" (click)="redirectToCustomerPortal()" ><p class="titleMediumPrimary80">Plan management</p></a>
  </div>
  <pricing-component class="subscriptions-list"></pricing-component>
</div>

<ng-template #selectSubscription>
</ng-template>
<ng-template #noSub>
  <div>
    <p class="bodyMediumNeutral60">Free</p>
    <p class="textStatusGreen100">Active</p>
    <p class="bodyMediumNeutral60">N/A</p>
    <p class="bodyMediumNeutral60">N/A</p>
    <p class="bodyMediumNeutral60">0$ per month</p>
    <p *ngIf="usage$ | async as usage; else loadingTemplate" class="bodyMediumNeutral60">{{ totalCredits | roundDigits }}/{{ usage.currentLimit }}</p>
  </div>
</ng-template>
<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

<ng-template #loadingTemplateSmall>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>
