import {AiApi, AiModel} from "./types";
import {AiContentType, ApiType} from "../";
import {ModelCategoryImageGeneration} from "./model-category";

export class DallE implements AiApi {
  contentType: AiContentType = "image";
  id: ApiType = "dall-e";
  name = "Dall-E";
  description =
    "DALL·E is a 12-billion parameter version of GPT-3 trained to generate images from text descriptions, using a dataset of text–image pairs. We’ve found that it has a diverse set of capabilities, including creating anthropomorphized versions of animals and objects, combining unrelated concepts in plausible ways, rendering text, and applying transformations to existing images.";
  models: DallEModel[] = [
    {
      id: "dall-e-2",
      displayName: "Dall-E 2",
      categories: [ModelCategoryImageGeneration],
      prices: {
        "256x256": 0.016,
        "512x512": 0.018,
        "1024x1024": 0.02,
      },
      contentType: {
        input: ["text"],
        output: ["image"],
      },
      parameters: [
        {
          key: "size",
          displayName: "Size",
          description: "Size of the image",
          type: "select",
          options: [
            {id: "256x256", displayName: "256x256"},
            {id: "512x512", displayName: "512x512"},
            {id: "1024x1024", displayName: "1024x1024"},
          ],
          defaultValue: "256x256",
          required: true,
        },
        // {
        //   key: "n",
        //   displayName: "Number of images",
        //   description: "Number of images to generate",
        //   type: "select",
        //   options: [
        //     {id: "1", displayName: "1"},
        //     {id: "2", displayName: "2"},
        //     {id: "3", displayName: "3"},
        //     {id: "4", displayName: "4"},
        //   ],
        //   defaultValue: "1",
        // },
      ],
    },
    {
      id: "dall-e-3",
      displayName: "Dall-E 3",
      categories: [ModelCategoryImageGeneration],
      prices: {
        "1024x1024": 0.04,
        "1024x1792": 0.08,
        "1792x1024": 0.08,
        "1024x1024-HD": 0.08,
        "1024x1792-HD": 0.12,
        "1792x1024-HD": 0.12,
      },
      contentType: {
        input: ["text"],
        output: ["image"],
      },
      parameters: [
        {
          key: "size",
          displayName: "Size",
          description: "Size of the image",
          type: "select",
          options: [
            {id: "1024x1024", displayName: "1024x1024"},
            {id: "1024x1792", displayName: "1024x1792"},
            {id: "1792x1024", displayName: "1792x1024"},
            {id: "1024x1024-HD", displayName: "1024x1024 (HD)"},
            {id: "1024x1792-HD", displayName: "1024x1792 (HD)"},
            {id: "1792x1024-HD", displayName: "1792x1024 (HD)"},
          ],
          defaultValue: "1024x1024",
          required: true,
        },
        // {
        //   key: "n",
        //   displayName: "Number of images",
        //   description: "Number of images to generate",
        //   type: "select",
        //   options: [
        //     {id: "1", displayName: "1"},
        //     {id: "2", displayName: "2"},
        //     {id: "3", displayName: "3"},
        //     {id: "4", displayName: "4"},
        //   ],
        //   defaultValue: "1",
        // },
      ],
    },
    // {id: "embed"},
    // {id: "classify"},
  ];
  readonly defaultModelId: string = "generate";

  getModel(modelId: string): AiModel {
    return this.models.find((model) => model.id === modelId) as AiModel;
  }
}

interface DallEModel extends AiModel {
  id: string;
  prices: Record<string, number>;
}

export type OpenAIDalleModelParameterKey = "size" | "n";
