import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {
  ChatActions,
  ChatAiApiActions,
  ChatContextMessagesActions,
  ChatCurrentMessageActions,
  ChatMessagesActions,
  ChatPageActions,
} from "./chat.actions";
import {Chat} from "../../../../../../.common/chat/chat";
import {ApiType} from "../../../../../../.common";
import {AiApi, AiModel} from "../../../../../../.common/ai-models";
import {getApiById} from "../../../../../../.common/ai-models/all-api";
import {
  ChatContent,
  ChatMessage,
  ChatMessageHuman,
  isChatTextContent,
} from "../../../../../../.common/chat/chat-message";
import {ModelParameterKey} from "../../../../../../.common/ai-models/model-parameters";
import {ChatListMode, getChatListMode} from "./chat-list-mode";

export interface ChatFilePreview {
  id: string;
  contentId?: string;
  url: string;
  chatId: string;
  userId: string;
  type: "image" | "video" | "audio" | "file";
  uploadProgress?: number;
}

export interface ChatState {
  currentChatId: string | undefined;
  currentChat: Chat | undefined;
  chatListMode: ChatListMode;
  chatList: Chat[];
  selectedAiApi: ApiType | undefined;
  selectedAiModel: AiModel | undefined;
  modelParameters: Record<ModelParameterKey, any>;
  modelSkippedParameters: Record<ModelParameterKey, any>;
  currentMessage: ChatMessageHuman;
  chatMessages: ChatMessage[];
  typingMessagesText: {[messageId: string]: string};
  chatReplyMessage: ChatMessage | undefined;

  // Chat context state
  useContextMode: "auto" | "manual" | "off";
  contextMessages: ChatMessage[];
  contextSize: number;

  // Chat page state
  chatAutoScroll: boolean;
  chatPageLoading: boolean;
  chatEmpty: boolean;
  permissionDenied: boolean;
  isChatlistSelectActive: boolean;
  navbarSelectedChats: {[key: string]: boolean};

  filePreviews: {[id: string]: ChatFilePreview};
  messageContent: {[id: string]: ChatContent};
}

export const initialState = {
  currentChatId: undefined,
  currentChat: undefined,
  chatListMode: getChatListMode(),
  chatList: [],
  selectedAiApi: undefined,
  selectedAiModel: undefined,
  modelParameters: {} as Record<ModelParameterKey, any>,
  modelSkippedParameters: {} as Record<ModelParameterKey, any>,
  currentMessage: {
    sender: {
      id: "human",
    },
    // TODO: remove after do migration: SD-837
    text: "",
    metadata: {
      modelParameters: {},
    },
  } as ChatMessageHuman,
  chatMessages: [],
  typingMessagesText: {},
  chatReplyMessage: undefined,
  useContextMode: "auto",
  contextMessages: [],
  contextSize: 0,
  chatAutoScroll: true,
  chatPageLoading: true,
  chatEmpty: false,
  permissionDenied: false,
  isChatlistSelectActive: false,
  navbarSelectedChats: {},
  filePreviews: {},
  messageContent: {},
} as ChatState;

const reducer = createReducer(
  initialState,
  on(ChatActions.loadChat, (state, {chatId}) => ({
    ...initialState,
    currentChatId: chatId,
    chatList: state.chatList,
    chatListMode: state.chatListMode,
  })),
  on(ChatActions.setChat, (state, {chat}) => ({
    ...state,
    currentChat: chat,
  })),
  on(ChatActions.setChatList, (state, {chatList}) => ({
    ...state,
    chatList,
  })),
  on(ChatActions.changeChatListMode, (state, {mode}) => ({
    ...state,
    chatListMode: mode,
  })),
  on(ChatActions.destroyChat, () => ({
    ...initialState,
  })),
  on(ChatMessagesActions.setChatMessages, (state, {chatMessages}) => ({
    ...state,
    chatMessages,
  })),
  on(ChatMessagesActions.appendTypingMessage, (state, {messageId, text}) => {
    // find the message by from the end and append text to chatMessage
    // const chatMessages = [...state.chatMessages];
    const chatMessages = state.chatMessages.map((message) =>
      messageId === message.id
        ? {
            ...message,
            content: !message.content
              ? [{id: "text", type: "text", text} as ChatContent]
              : message.content.map((content) =>
                  isChatTextContent(content)
                    ? {...content, text: content.text + text}
                    : {...content},
                ),
          }
        : {...message},
    );

    return {
      ...state,
      chatMessages,
      typingMessagesText: {
        ...state.typingMessagesText,
        [messageId]: (state.typingMessagesText[messageId] || "") + text,
      },
    };
  }),
  on(ChatMessagesActions.removeTypingMessage, (state, {messageId}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[messageId]: _, ...typingMessagesText} = state.typingMessagesText;
    return {
      ...state,
      typingMessagesText,
    };
  }),
  on(ChatMessagesActions.clearChatMessages, (state) => ({
    ...state,
    chatMessages: [],
  })),
  on(ChatMessagesActions.messageSent, (state) => ({
    ...state,
    chatReplyMessage: undefined,
  })),
  on(ChatContextMessagesActions.updateUseContext, (state, {useContextMode}) => ({
    ...state,
    useContextMode,
  })),
  on(ChatContextMessagesActions.updateContextMessages, (state, {contextMessages}) => ({
    ...state,
    contextMessages,
  })),
  on(ChatContextMessagesActions.setContextSize, (state, {contextSize}) => ({
    ...state,
    contextSize,
  })),
  on(ChatContextMessagesActions.setChatReplyMessage, (state, {message}) => ({
    ...state,
    chatReplyMessage: message,
  })),
  on(ChatCurrentMessageActions.updateCurrentMessage, (state, {currentMessage}) => ({
    ...state,
    currentMessage,
  })),
  on(ChatCurrentMessageActions.clearCurrentMessage, (state) => ({
    ...state,
    currentMessage: initialState.currentMessage,
    filePreviews: initialState.filePreviews,
    messageContent: initialState.messageContent,
  })),
  on(ChatCurrentMessageActions.updateModelParameters, (state, {modelParameters}) => ({
    ...state,
    modelParameters,
  })),
  on(ChatCurrentMessageActions.updateMessageModelParameter, (state, {key, value}) => ({
    ...state,
    modelParameters: {
      ...state.modelParameters,
      [key]: value,
    },
  })),
  on(ChatCurrentMessageActions.updateMessageModelSkippedParameter, (state, {key, value}) => ({
    ...state,
    modelSkippedParameters: {
      ...state.modelSkippedParameters,
      [key]: value,
    },
  })),
  on(ChatCurrentMessageActions.updateModelSkippedParameters, (state, {modelParameters}) => ({
    ...state,
    modelSkippedParameters: modelParameters,
  })),
  on(ChatCurrentMessageActions.updateCurrentMessageText, (state, {text}) => {
    text = text.trim();
    let messageContent: {[id: string]: ChatContent};
    if (text) {
      messageContent = {
        ...state.messageContent,
        prompt: {
          id: "prompt",
          type: "text",
          text,
        } as ChatContent,
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {["prompt"]: _, ...m} = state.messageContent;
      messageContent = m;
    }
    const currentMessage = {
      ...state.currentMessage,
      content: Object.values(messageContent),
    };
    return {
      ...state,
      messageContent,
      currentMessage,
    };
  }),
  on(ChatAiApiActions.setSelectedAiApi, (state, {apiId}) => ({
    ...state,
    selectedAiApi: apiId,
  })),
  on(ChatAiApiActions.setSelectedAiModel, (state, {model}) => ({
    ...state,
    contextMessages: [],
    selectedAiModel: model,
    contextSize: 0,
  })),
  on(ChatAiApiActions.setUndefinedAiApi, (state) => ({
    ...state,
    selectedAiApi: undefined,
  })),
  on(ChatAiApiActions.setUndefinedAiModel, (state) => ({
    ...state,
    selectedAiModel: undefined,
  })),
  on(ChatPageActions.setChatAutoScroll, (state, {autoScroll}) => ({
    ...state,
    chatAutoScroll: autoScroll,
  })),
  on(ChatPageActions.loading, (state, {loading}) => ({
    ...state,
    chatPageLoading: loading,
  })),
  on(ChatPageActions.setChatEmpty, (state) => ({
    ...state,
    chatEmpty: true,
  })),
  on(ChatPageActions.setPermissionDenied, (state, {permissionDenied}) => ({
    ...state,
    permissionDenied,
  })),
  on(ChatActions.setChatlistSelectActive, (state, {isActive}) => ({
    ...state,
    isChatlistSelectActive: isActive,
  })),
  on(ChatActions.navbarChatsSelectionToggle, (state, {chatId}) => {
    const navbarSelectedChats = {...state.navbarSelectedChats};
    if (navbarSelectedChats[chatId]) {
      delete navbarSelectedChats[chatId];
    } else {
      navbarSelectedChats[chatId] = true;
    }
    return {
      ...state,
      navbarSelectedChats,
    };
  }),
  on(ChatActions.navbarChatsSelectionClear, (state) => ({
    ...state,
    navbarSelectedChats: {},
  })),
  on(ChatMessagesActions.setFilePreviews, (state, {filePreview}) => ({
    ...state,
    filePreviews: {
      ...state.filePreviews,
      [filePreview.id]: filePreview,
    },
  })),
  on(ChatMessagesActions.deleteFilePreview, (state, {filePreview}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[filePreview.id]: _, ...updatedFilePreviews} = state.filePreviews;
    const resultState = {
      ...state,
      filePreviews: updatedFilePreviews,
    };
    if (filePreview.contentId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {[filePreview.contentId]: __, ...updatedMessageContent} = state.messageContent;
      resultState.messageContent = updatedMessageContent;
    }

    return resultState;
  }),
  on(ChatCurrentMessageActions.setCurrentMessageContent, (state, {content}) => ({
    ...state,
    messageContent: {
      ...state.messageContent,
      [content.id]: content,
    },
  })),
);
export const chatFeature = createFeature({
  name: "chatFeature",
  reducer,
  extraSelectors: ({
    selectNavbarSelectedChats,
    selectContextMessages,
    selectSelectedAiApi,
    selectTypingMessagesText,
  }) => ({
    selectNavbarSelectedChatsArray: createSelector(
      selectNavbarSelectedChats,
      (navbarSelectedChats) => Object.keys(navbarSelectedChats),
    ),
    selectContextMessageIds: createSelector(selectContextMessages, (contextMessages) =>
      contextMessages.map((it) => it.id),
    ),
    selectActiveAiApiInstance: createSelector(selectSelectedAiApi, (aiApiId): AiApi | undefined =>
      aiApiId ? getApiById(aiApiId) : undefined,
    ),
    selectTypingMessage: (messageId: string) =>
      createSelector(
        selectTypingMessagesText,
        (typingMessagesText) => typingMessagesText[messageId],
      ),
  }),
});
