import {createActionGroup, emptyProps} from "@ngrx/store";
import {PublicUserInfo, SymbiotUser, SymbiotUserLegalSettings} from "../../../../../.common";

export const AuthActions = createActionGroup({
  source: "Auth",
  events: {
    loadUser: emptyProps(),
    notAuthenticated: emptyProps(),
    setUser: (user: SymbiotUser) => user,
    setPublicUserInfo: (publicUserInfo: PublicUserInfo | undefined) => ({publicUserInfo}),
    updateLegalSettings: (legalSettings: SymbiotUserLegalSettings) => legalSettings,
    logout: emptyProps(),
  },
});
