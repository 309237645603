import {Pipe, PipeTransform} from "@angular/core";
import {StripeProduct} from "../../../../.common/subscriptions/stripe-product";

@Pipe({
  name: "sortSubscriptions",
})
export class SortSubscriptionsPipe implements PipeTransform {
  transform(value: StripeProduct[] | any): StripeProduct[] | undefined {
    if (!value) {
      return undefined;
    }
    const subscriptionsOrder = ["Free", "Standard", "Business", "Business Pro", "Custom"];
    return value.sort(
      (a: StripeProduct, b: StripeProduct) =>
        subscriptionsOrder.indexOf(a.name) - subscriptionsOrder.indexOf(b.name),
    );
  }
}
