import {AiApi, AiModel} from "./types";
import {AiContentType, ApiType} from "../";

import {maxContextLength} from "./model-parameters";
import {ModelCategoryTextGeneration} from "./model-category";

export class AnthropicApi implements AiApi {
  id: ApiType = "anthropic";
  contentType: AiContentType = "per-model";
  name = "Anthropic";
  description =
    "Anthropic AI is a research and safety-focused artificial intelligence company that specializes in developing large-scale machine learning models. Their goal is to build AI systems that are reliable, interpretable, and steerable, ensuring they align with human intentions and ethics. The company focuses on understanding and solving the technical challenges associated with AI alignment and governance, aiming to create AI technologies that can be trusted and that contribute positively to society.";
  // https://docs.anthropic.com/claude/docs/models-overview
  models: AiModel[] = [
    {
      id: "claude-3-opus-20240229",
      displayName: "Claude 3 Opus",
      description: "Most powerful model for highly complex tasks.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 15 / 1000, "1K-output": 75 / 1000},
      contentType: "chat",
      parameters: [
        {
          key: "temperature",
          displayName: "Temperature",
          description:
            "Amount of randomness injected into the response.\n" +
            "\n" +
            "Defaults to 1. Ranges from 0 to 1. Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.",
          type: "range",
          limits: {
            min: 0,
            max: 1,
            step: 0.1,
          },
          defaultValue: 1,
          required: false,
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 200000,
          },
          defaultValue: 200000,
        },
        {
          key: "max_tokens",
          displayName: "Maximum completion tokens",
          description:
            "The maximum number of tokens to generate before stopping. Note that our models may stop before reaching this maximum. This parameter only specifies the absolute maximum number of tokens to generate.",
          type: "range",
          limits: {
            min: 1,
            max: 4096,
            step: 10,
          },
          required: false,
          defaultValue: 4096,
        },
      ],
    },
    {
      id: "claude-3-sonnet-20240229",
      displayName: "Claude 3 Sonnet",
      description: "Ideal balance of intelligence and speed for enterprise workloads.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 3 / 1000, "1K-output": 15 / 1000},
      contentType: "chat",
      parameters: [
        {
          key: "temperature",
          displayName: "Temperature",
          description:
            "Amount of randomness injected into the response.\n" +
            "\n" +
            "Defaults to 1. Ranges from 0 to 1. Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.",
          type: "range",
          limits: {
            min: 0,
            max: 1,
            step: 0.1,
          },
          defaultValue: 1,
          required: false,
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 200000,
          },
          defaultValue: 200000,
        },
        {
          key: "max_tokens",
          displayName: "Maximum completion tokens",
          description:
            "The maximum number of tokens to generate before stopping. Note that our models may stop before reaching this maximum. This parameter only specifies the absolute maximum number of tokens to generate.",
          type: "range",
          limits: {
            min: 1,
            max: 4096,
            step: 10,
          },
          required: false,
          defaultValue: 4096,
        },
      ],
    },

    {
      id: "claude-instant-1",
      displayName: "Claude Instant 1",
      description: "low-latency, high throughput model",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 8 / 1000, "1K-output": 24 / 1000},
      contentType: "chat",
      outdated: true,
      parameters: [
        {
          key: "temperature",
          displayName: "Temperature",
          description:
            "Amount of randomness injected into the response.\n" +
            "\n" +
            "Defaults to 1. Ranges from 0 to 1. Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.",
          type: "range",
          limits: {
            min: 0,
            max: 1,
            step: 0.1,
          },
          defaultValue: 1,
          required: false,
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 100000,
          },
          defaultValue: 100000,
        },
        {
          key: "max_tokens_to_sample",
          displayName: "Maximum completion tokens",
          description:
            "The maximum number of tokens to generate before stopping. Note that our models may stop before reaching this maximum. This parameter only specifies the absolute maximum number of tokens to generate.",
          type: "range",
          limits: {
            min: 1,
            max: 4096,
            step: 10,
          },
          required: false,
          defaultValue: 4096,
        },
      ],
    },
    {
      id: "claude-2.1",
      displayName: "Claude 2.1",
      description: "Performance on tasks that require complex reasoning.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 8 / 1000, "1K-output": 24 / 1000},
      contentType: "chat",
      outdated: true,
      parameters: [
        {
          key: "temperature",
          displayName: "Temperature",
          description:
            "Amount of randomness injected into the response.\n" +
            "\n" +
            "Defaults to 1. Ranges from 0 to 1. Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.",
          type: "range",
          limits: {
            min: 0,
            max: 1,
            step: 0.1,
          },
          defaultValue: 1,
          required: false,
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 200000,
          },
          defaultValue: 200000,
        },
        {
          key: "max_tokens_to_sample",
          displayName: "Maximum completion tokens",
          description:
            "The maximum number of tokens to generate before stopping. Note that our models may stop before reaching this maximum. This parameter only specifies the absolute maximum number of tokens to generate.",
          type: "range",
          limits: {
            min: 1,
            max: 4096,
            step: 10,
          },
          required: false,
          defaultValue: 4096,
        },
      ],
    },
  ];
  readonly defaultModelId: string = this.models[0].id;

  getModel(modelId: string): AiModel {
    return this.models.find((model) => model.id === modelId) as AiModel;
  }

  readonly parameters = this.models[0].parameters!;
}

export type AnthropicParameterKey =
  | "max_tokens_to_sample"
  | "temperature"
  | "top_p"
  | "top_k"
  | "stop_sequences";
