import {
  users,
  users_credits,
  users_credits_usageDoc,
  users_legal,
  users_legal_versionsDoc,
  users_notifications,
  users_publicData,
  users_publicData_infoDoc,
  users_subscriptions,
} from "../firestore-collections";
import {ToType, WithId} from "../firestore/dataconverters";
import {
  NotificationSettings,
  PublicUserInfo,
  SymbiotUser,
  SymbiotUserLegalSettings,
} from "../index";
import {Credits} from "../credits/credits";
import firebase from "firebase/compat";
import {StripeUserSubscription} from "../subscriptions/stripe-user-subscription";
import {MergedFirestore} from "../firestore/merged-firestore";

export class UserCollectionProvider {
  private readonly firestore: MergedFirestore;

  constructor(firestore: firebase.firestore.Firestore | FirebaseFirestore.Firestore) {
    this.firestore = firestore as MergedFirestore;
  }
  getUserCollection() {
    return this.firestore.collection(users).withConverter(new WithId<SymbiotUser>());
  }

  getUserPublicDataDocRef(userId: string) {
    return this.getUserCollection()
      .doc(`${userId}/${users_publicData}/${users_publicData_infoDoc}`)
      .withConverter(new ToType<PublicUserInfo>());
  }

  getUserNotificationDoc(userId: string, type: "email") {
    return this.firestore
      .doc(`${users}/${userId}/${users_notifications}/${type}`)
      .withConverter(new ToType<NotificationSettings>());
  }
  getUserLegalSettingsDoc(userId: string) {
    return this.firestore
      .doc(`${users}/${userId}/${users_legal}/${users_legal_versionsDoc}`)
      .withConverter(new ToType<SymbiotUserLegalSettings>());
  }
  getUserCreditsUsageDoc(userId: string) {
    return this.firestore
      .doc(`${users}/${userId}/${users_credits}/${users_credits_usageDoc}`)
      .withConverter(new ToType<Credits>());
  }

  getActiveUserSubscriptionsCollection(userId: string) {
    return this.firestore
      .collection(`${users}/${userId}/${users_subscriptions}`)
      .withConverter(new WithId<StripeUserSubscription>());
  }
}
