import {Component, Input, OnInit} from "@angular/core";
import {map, Observable} from "rxjs";
import {AiPromptHistoryService} from "../../../../../services/ai-prompt-history.service";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {AiApi} from "../../../../../../../.common/ai-models";
import {
  AiPromptResponse,
  AiPromptResponseType,
} from "../../../../../../../.common/ai-prompt-response";

@Component({
  selector: "app-api-response-results",
  templateUrl: "./api-response-results.component.html",
  styleUrls: ["./api-response-results.component.scss"],
})
export class ApiResponseResultsComponent implements OnInit {
  @Input()
  promptId!: string;
  @Input()
  api?: AiApi;
  @Input({required: true})
  responseType!: AiPromptResponseType;
  apiResponses$?: Observable<AiPromptResponse<any>[]>;

  constructor(
    private aiPromptHistoryService: AiPromptHistoryService,
    private afs: AngularFireStorage,
  ) {}

  ngOnInit(): void {
    let responses$ = this.aiPromptHistoryService.getPromptResponses(
      this.promptId,
      this.responseType,
    );
    if (this.api?.contentType === "image") {
      // Get download url from responses and put it in tmpUrl
      responses$ = responses$.pipe(
        map((responses) => {
          return responses.map((response) => {
            if (response.contentType === "image" && response.content.url) {
              response.content.tmpUrl = this.fromStorage(response.content.url);
              delete response.content.url;
            }
            return response;
          });
        }),
      );
    }
    this.apiResponses$ = responses$;
  }

  fromStorage(url: string) {
    return this.afs
      .ref(`promptsExecutionResponses/${this.promptId}/${this.responseType}/${url}`)
      .getDownloadURL();
  }

  isObservable(tmpUrl: any) {
    return tmpUrl instanceof Observable;
  }
}
