import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  QueryDocumentSnapshot as GCQueryDocumentSnapshot,
  SetOptions,
} from "@google-cloud/firestore";
import firebase from "firebase/compat";
import {isFirestoreTimestamp} from "./types.converter";
import SnapshotOptions = firebase.firestore.SnapshotOptions;

type UnifiedQueryDocumentSnapshot<T> =
  | GCQueryDocumentSnapshot<T>
  | firebase.firestore.QueryDocumentSnapshot<T>;

export class WithId<T extends {id?: string}>
  implements
    FirebaseFirestore.FirestoreDataConverter<T>,
    firebase.firestore.FirestoreDataConverter<T>
{
  toFirestore(modelObject: T): DocumentData;
  toFirestore(modelObject: Partial<T>, options: SetOptions): DocumentData;
  toFirestore(modelObject: T | Partial<T>, options?: SetOptions): DocumentData {
    const newDoc = {...modelObject};
    delete newDoc.id;
    return newDoc;
  }

  fromFirestore(snapshot: UnifiedQueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): T;
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): T;
  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData> | firebase.firestore.QueryDocumentSnapshot,
    options?: SnapshotOptions,
  ): T {
    const data = snapshot.data();
    //check fields if firestore timestamp and convert to js date
    convertTypes(data);
    // Add the 'id' field to the document data
    return {id: snapshot.id, ...data} as T;
  }
}

export class ToType<T extends {[key: string]: any}>
  implements FirestoreDataConverter<T>, firebase.firestore.FirestoreDataConverter<T>
{
  toFirestore(modelObject: T): DocumentData;
  toFirestore(modelObject: Partial<T>, options: SetOptions): DocumentData;
  toFirestore(modelObject: T | Partial<T>, options?: SetOptions): DocumentData {
    return modelObject;
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): T;
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): T;
  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData> | firebase.firestore.QueryDocumentSnapshot,
    options?: SnapshotOptions,
  ): T {
    const data = snapshot.data() as T;
    convertTypes(data);
    return data;
  }
}

function convertTypes(data: FirebaseFirestore.DocumentData) {
  Object.keys(data).forEach((key) => {
    const obj = data[key];
    if (isFirestoreTimestamp(obj)) {
      data[key] = obj.toDate();
    }
  });
}
// Usage:
// const docRef = firestore.doc('col/doc').withConverter(new WithId<MyModel>());
// const docRef = firestore.doc('col/doc').withConverter(new ToType<MyModel>());
