export const environment = {
  production: false,
  useEmulators: false,
  firebase: {
    apiKey: "AIzaSyCsoeWgu4myiExaE0yrc1Nt5KJpFE_aqtY",
    authDomain: "symbiotai-dev.firebaseapp.com",
    projectId: "symbiotai-dev",
    storageBucket: "symbiotai-dev.appspot.com",
    messagingSenderId: "560309634042",
    appId: "1:560309634042:web:4bf54023f189a2941b5a43",
    measurementId: "G-PPYR8433H1",
    databaseURL: "https://symbiotai-dev-default-rtdb.europe-west1.firebasedatabase.app",
    locationId: "europe-west3",
  },
  apiUrl: "https://europe-west3-symbiotai-dev.cloudfunctions.net/",
};
