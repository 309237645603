<ng-container *ngIf="vm$() as vm">
  <ul class="chat-list" data-cy="chat-menu-list">
    <li *ngFor="let chat of vm.chatList; trackBy: chatIdentity" data-cy="chat-list-item">
      <a
        mat-button
        *ngIf="vm.isChatlistSelectActive"
        class="chat-item"
        (click)="toggleSelectChat(chat.id)"
      >
        <mat-checkbox [checked]="vm.navbarSelectedChats[chat.id]" *ngIf="vm.isChatlistSelectActive"></mat-checkbox>
        <ng-container *ngTemplateOutlet="chatItem"></ng-container>
      </a>
      <a
        mat-button
        *ngIf="!vm.isChatlistSelectActive"
        class="chat-item"
        [routerLink]="'/chat/' + chat.id"
        routerLinkActive="active-chat"
      >
        <ng-container *ngTemplateOutlet="chatItem"></ng-container>
      </a>
      <ng-template #chatItem>
        <ng-container *ngIf="hasChatParticipant(chat)">
          <ui-profile-icon
                  [showName]="false"
                  [profileIconSize]="48"
                  [chatParticipant]="{id: chat.modelParticipants![0], type: 'model'}"
                  displayNameClasses="bodyMedium"
          ></ui-profile-icon>
        </ng-container>
        <img *ngIf="!hasChatParticipant(chat)" class="chat-image-dummy" ngSrc="https://picsum.photos/seed/{{chat.id}}/48/48" alt="chat image" height="48" width="48"/>
        <div class="item-content">
          <div class="name-date">
            <span class="titleMediumNeutral80" *ngIf="chat.displayName">{{
              chat.displayName
              }}</span>
            <span class="titleMediumNeutral80" *ngIf="!chat.displayName && chat.createdAt">{{
              chatService.toDate(chat.createdAt) | date: "MMM d, y"
              }}</span>
            <span class="titleMediumNeutral80" *ngIf="!chat.displayName && !chat.createdAt">
              New Chat
            </span>
            <mat-icon *ngIf="chat.visibility === 'public'" class="colorPrimary60">public</mat-icon>
          </div>
          <div class="last-message bodyMediumNeutral60">{{chatService.getLastMessageToDisplay(chat)}}</div>
        </div>
      </ng-template>
      <mat-divider></mat-divider>
    </li>
  </ul>

  <ng-template #loading>
    <mat-progress-spinner></mat-progress-spinner>
  </ng-template>

</ng-container>
