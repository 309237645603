import {Component, inject, OnInit} from "@angular/core";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {Destroyer} from "../../_helpers/Destroyer";
import {ChatService} from "../../pages/chat/services/chat.service";
import {Observable, of, switchMap} from "rxjs";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {
  AsyncPipe,
  DatePipe,
  JsonPipe,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from "@angular/common";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {createSelector, Store} from "@ngrx/store";
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {chatFeature} from "../../pages/chat/state/chat.state";
import {authFeature} from "../../auth/state/auth.state";
import {MatDividerModule} from "@angular/material/divider";
import {ChatActions} from "../../pages/chat/state/chat.actions";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Chat} from "../../../../../.common/chat/chat";
import {ChatTypesSelectorComponent} from "./chat-types-selector/chat-types-selector.component";

@Component({
  selector: "side-navbar-header",
  templateUrl: "./side-navbar-header.component.html",
  styleUrls: ["./side-navbar-header.component.scss"],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    DatePipe,
    AsyncPipe,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    NgIf,
    NgForOf,
    RouterLink,
    RouterLinkActive,
    NgTemplateOutlet,
    ReactiveFormsModule,
    JsonPipe,
    FormsModule,
    MatDividerModule,
    MatTooltipModule,
    ChatTypesSelectorComponent,
  ],
})
export class SideNavbarHeaderComponent extends Destroyer implements OnInit {
  store = inject(Store);
  chats$?: Observable<Chat[]>;
  disableCreateChatButton = false;
  form: any;

  vm$ = this.store.selectSignal(
    createSelector({
      chat: chatFeature.selectCurrentChat,
      user: authFeature.selectUser,
      isChatlistSelectActive: chatFeature.selectIsChatlistSelectActive,
      navbarSelectedChats: chatFeature.selectNavbarSelectedChatsArray,
    }),
  );

  constructor(
    private router: Router,
    public authService: AuthService,
    public chatService: ChatService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.chats$ = this.authService.user$.pipe(
      switchMap((currentUser) =>
        currentUser ? this.chatService.getChatsForUser(currentUser.id) : of([]),
      ),
    );
  }

  async deleteChats() {
    this.store.dispatch(ChatActions.navbarChatsDeleteSelected());
  }

  async createChat() {
    this.disableCreateChatButton = true;
    try {
      await this.chatService.createNewChat(this.authService.requireUser().id);
    } finally {
      this.disableCreateChatButton = false;
    }
  }

  async selectItems() {
    this.store.dispatch(ChatActions.setChatlistSelectActive(true));
    this.disableCreateChatButton = true;
  }

  resetSelection() {
    this.store.dispatch(ChatActions.setChatlistSelectActive(false));
    this.disableCreateChatButton = false;
  }
}
