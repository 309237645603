<div class="userProfileIcon" *ngIf="publicUserData as user">
  <div>
    <div *ngIf="user.photoURL && showImage; then withImage; else withoutImage"></div>
    <ng-template #withImage>
      <div [class]="iconClasses">
        <img
          class="user-img"
          ngSrc="{{ user.photoURL }}"
          width="{{ profileIconSize }}"
          height="{{ profileIconSize }}"
          alt="{{ user.displayName ? user.displayName : 'User Name' }}"
        />
        <span [ngClass]="displayNameClasses" [className]="displayNameClasses" *ngIf="showName">{{
          user.displayName ? user.displayName : 'User Name'
          }}</span>
        <div class="active-line"></div>
      </div>
    </ng-template>
    <ng-template #withoutImage>
      <div class="profile-icon">
        <img
          class="user-img"
          *ngIf="showImage"
          ngSrc="https://picsum.photos/seed/{{ user.displayName }}/{{ profileIconSize }}/{{profileIconSize}}"
          width="{{ profileIconSize }}"
          height="{{ profileIconSize }}"
          alt="{{ user.displayName }}"
        />
        <span [ngClass]="displayNameClasses" *ngIf="showName">{{ user.displayName ? user.displayName : 'User Name' }}</span>
        <div class="active-line"></div>
      </div>
    </ng-template>
  </div>
</div>
