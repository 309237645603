import {Component, Input} from "@angular/core";

@Component({
  selector: "ui-main-menu-item",
  templateUrl: "./main-menu-item.component.html",
  styleUrls: ["./main-menu-item.component.scss"],
})
export class MainMenuItemComponent {
  @Input({required: false}) link?: string;
  @Input() icon?: string;
  @Input() label?: string;
}
