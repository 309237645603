<ng-container *ngIf="vm$() as vm">
  <ng-container *ngIf="vm.user">
    <div class="top-menu">
      <ng-container *ngIf="!vm.isChatlistSelectActive; else selectMultiple">
        <button mat-icon-button color="primary" [matMenuTriggerFor]="chatsMenu" [disabled]="!vm.user">
          <mat-icon class="material-symbols-outlined">menu</mat-icon>
        </button>
        <chat-types-selector></chat-types-selector>
        <button mat-icon-button *ngIf="!!vm.user"
                matTooltip="Create chat"
                matTooltipPosition="above"
                [matTooltipShowDelay]="200"
                data-cy="create-chat-button"
                [disabled]=disableCreateChatButton
                color="primary"
                (click)=createChat()>
          <mat-icon class="material-symbols-outlined">edit_square</mat-icon>
        </button>
        <button mat-icon-button *ngIf="!vm.user"
                color="primary"
                (click)="authService.openAuthWindow()">
          <mat-icon class="material-symbols-outlined">edit_square</mat-icon>
        </button>
      </ng-container>
    </div>

    <!-- Header during selecting chats -->
    <ng-template #selectMultiple>
      <button mat-icon-button (click)=resetSelection()>
        <mat-icon class="material-symbols-outlined" color="primary">chevron_left</mat-icon>
      </button>
      <span>Select Chats</span>

      <button [disabled]="vm.navbarSelectedChats.length < 1" color="primary" mat-icon-button (click)=deleteChats()>
        <mat-icon class="material-symbols-outlined">delete</mat-icon>
      </button>
    </ng-template>

    <!-- Dropdown "Select chats" -->
    <mat-menu #chatsMenu="matMenu">
      <button mat-menu-item (click)=selectItems()>
        <span>Select chats</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-container>
