import {Component, inject} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {createSelector, Store} from "@ngrx/store";
import {chatFeature} from "../../../pages/chat/state/chat.state";
import {NgForOf, NgIf, TitleCasePipe} from "@angular/common";
import {ChatActions} from "../../../pages/chat/state/chat.actions";
import {ChatListMode} from "../../../pages/chat/state/chat-list-mode";

@Component({
  selector: "chat-types-selector",
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatMenuModule, TitleCasePipe, NgIf, NgForOf],
  templateUrl: "./chat-types-selector.component.html",
  styleUrl: "./chat-types-selector.component.scss",
})
export class ChatTypesSelectorComponent {
  chatTypes: ChatListMode[] = ["private", "public", "shared"];
  #store = inject(Store);
  vm$ = this.#store.selectSignal(
    createSelector({
      chatListMode: chatFeature.selectChatListMode,
    }),
  );

  changeChatListMode(chatListMode: ChatListMode) {
    this.#store.dispatch(ChatActions.changeChatListMode(chatListMode));
  }
}
