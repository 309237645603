import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {firstValueFrom, map, Observable} from "rxjs";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {StripePrice, StripeProduct} from "../../../.common/subscriptions/stripe-product";
import {stripeProducts, users, users_subscriptions} from "../../../.common/firestore-collections";
import {StripeUserSubscription} from "../../../.common/subscriptions/stripe-user-subscription";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private stripeProductsCollection = this.afs.collection<StripeProduct>(stripeProducts);

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
  ) {}

  getProductsWithPrices(): Observable<StripeProduct[]> {
    return this.stripeProductsCollection.get().pipe(
      map((snapshot) => {
        const filtered = snapshot.docs.filter((doc) => {
          return doc.data().active;
        });
        return filtered.map((doc) => {
          const stripeProduct = doc.data();
          stripeProduct.id = doc.id;
          stripeProduct.prices = this.getPricesByProductId(doc.id);
          return stripeProduct;
        });
      }),
    );
  }

  getProductById(id: string): Observable<StripeProduct> {
    return new Observable((observer) => {
      this.afs.firestore
        .collection(stripeProducts)
        .doc(id)
        .onSnapshot({
          next: (snapshot) => {
            observer.next(snapshot.data() as StripeProduct);
          },
        });
    });
  }

  async toCustomerPortal() {
    const functionRef = this.fns.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const data = await firstValueFrom(
      functionRef({
        returnUrl: window.location.origin + window.location.pathname,
        locale: "auto", // Optional, defaults to "auto"
      }),
    );
    window.location.assign(data.url);
  }

  private getPricesByProductId(docId: string): Observable<StripePrice[]> {
    return this.stripeProductsCollection
      .doc<StripePrice>(docId)
      .collection("prices")
      .get()
      .pipe(
        map((snapshot) => {
          return snapshot.docs.map((doc) => {
            const price = doc.data() as StripePrice;
            price.id = doc.id;
            return price;
          });
        }),
      );
  }

  public getSubscriptionsForUser(userId: string): Observable<StripeUserSubscription | undefined> {
    return new Observable((observer) => {
      this.afs.firestore
        .collection(users)
        .doc(userId)
        .collection(users_subscriptions)
        .where("status", "==", "active")
        .limit(1)
        .onSnapshot({
          next: (snapshot) => {
            if (snapshot.docs.length > 0) {
              observer.next(snapshot.docs[0].data() as StripeUserSubscription);
            }
          },
        });
    });
  }
  public getPriceByReference(docRef: string): Observable<StripePrice> {
    return new Observable((observer) => {
      this.afs.firestore.doc(docRef).onSnapshot({
        next: (snapshot) => {
          observer.next(snapshot.data() as StripePrice);
        },
      });
    });
  }
}
