<ng-container *ngIf="!loading; else loadingTemplate">
  <ng-container *ngIf="!notFound; else notFoundTemplate">
    <ng-container *ngIf="isMyPage; then myPageTemplate; else publicProfileTemplate"></ng-container>
  </ng-container>
</ng-container>
<ng-template #myPageTemplate>
  <h1>Your page profile</h1>
  <p *ngIf="userData() as u">Email: {{ u.email }}</p>
  <p *ngIf="publicUserData() as pub">
    <ui-profile-icon [publicUserData]="pub" [showName]="false"></ui-profile-icon>
    <ui-profile-icon [publicUserData]="pub" [showImage]="false"></ui-profile-icon>
  </p>
</ng-template>
<ng-template #publicProfileTemplate>
  <h1>Public profile</h1>
  <p *ngIf="publicUserData() as pub">
    <ui-profile-icon [publicUserData]="pub" [showName]="false"></ui-profile-icon>
    <ui-profile-icon [publicUserData]="pub" [showImage]="false"></ui-profile-icon>
  </p>
</ng-template>
<ng-template #notFoundTemplate>
  <h1>User {{ userId }} not found</h1>
</ng-template>

<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>
