import {Component, inject} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {MarkdownModule} from "ngx-markdown";
import {Store} from "@ngrx/store";
import {legalFeature} from "../../../state/legal.state";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
  standalone: true,
  imports: [MarkdownModule],
})
export class TermsComponent {
  version = inject(Store).selectSignal(legalFeature.selectTermsVersion);
  constructor(private titleService: Title) {
    this.titleService.setTitle("Terms - Symbiot AI");
  }
}
