import {Injectable} from "@angular/core";
import firebase from "firebase/compat/app";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AuthService} from "../components/auth/auth.service";
import {Observable} from "rxjs";
import {aiPromptResponses} from "../../../.common/firestore-collections";
import {AiPromptResponse, AiPromptResponseType} from "../../../.common/ai-prompt-response";
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: "root",
})
export class AiPromptHistoryService {
  private aiPromptResponseCollection = this.afs.firestore.collection(aiPromptResponses);
  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
  ) {}

  async createTest(
    promptResponse: Omit<AiPromptResponse<any>, "id">,
  ): Promise<AiPromptResponse<any>> {
    if (promptResponse.responseType !== "test" && promptResponse.responseType !== "example") {
      throw new Error("Not a test prompt response");
    }

    promptResponse.ownerId = this.authService.requireUserId();
    promptResponse.created = FieldValue.serverTimestamp();
    const id = this.afs.createId();
    const respRef = this.aiPromptResponseCollection.doc(id);
    await respRef.set(promptResponse);
    return {id, ...promptResponse};
  }

  getPromptResponses(
    promptId: string,
    responseType: AiPromptResponseType,
  ): Observable<AiPromptResponse<any>[]> {
    return new Observable((observer) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        observer.error("Not authorized");
        return;
      }
      this.aiPromptResponseCollection
        .where("ownerId", "==", currentUser.uid)
        .where("promptId", "==", promptId)
        .where("responseType", "==", responseType)
        .limit(10)
        .orderBy("created", "desc")
        .onSnapshot({
          next: (snapshot) => {
            observer.next(
              snapshot.docs.map((doc) => {
                return {id: doc.id, ...doc.data()} as AiPromptResponse<any>;
              }),
            );
          },
        });
    });
  }
  // async savePromptResult(promptId: string, promptVersion: string, response: Blob): Promise<void> {
  //   const userId = firebase.auth().currentUser!.uid;
  //   const prefix = this.generateReverseSortPrefix();
  //   const path = this.getFilePath(userId, promptId, promptVersion, prefix);
  //   await this.storage.upload(path, response, {
  //     customMetadata: {
  //       version: promptVersion,
  //     },
  //   });
  // }

  // async clearTestPromptHistory(): Promise<void> {
  //   const userId = firebase.auth().currentUser!.uid;
  //   // this.getFilePath(userId, );
  //
  // }
  //
  // private getFilePath(userId: string, promptId: string, promptVersion: string, prefix: string) {
  //   return `templates/ai-prompt-results/${userId}/${promptId}/${promptVersion}/${prefix}`;
  // }

  private generateReverseSortPrefix(): string {
    // 33230900107000 is Jan 17 3023 21:35:07 GMT+0000. We take 1000 years from the date of development.
    // This is a way to create a reverse version of timestamp. Each next prefix will be less than previous.
    // We use it for reverse sorting in GCP store which uses lexicographical sorting.
    return 33230900107000 - Date.now() + "";
  }
}
