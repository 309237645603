<div class="accept-cookies bodySmall">
  <button mat-button (click)="back.emit()">
    <mat-icon>arrow_back_ios</mat-icon>
    back
  </button>
  <p class="titleMedium colorNeutral70">Categories of Cookies and Tracking</p>
  <form [formGroup]="form" (ngSubmit)="submit()" class="bodySmall">

    <div class="item">
      <span class="titleSmall colorNeutral70">Strictly Necessary Cookies</span>
      <div>These are essential for the website to function properly, like session management and security.</div>
      <mat-checkbox formControlName="necessary">Enable</mat-checkbox>
      <mat-error *ngIf="hasError('necessary')">
        We can't process your request without this
      </mat-error>
    </div>
    <div class="item">
      <span class="titleSmall colorNeutral70">Analytics Cookies</span>
      <div> These cookies help us understand how visitors interact with our website, providing information about
        the areas visited, time spent, and any issues encountered, like error messages.</div>
      <mat-checkbox formControlName="analytics">Enable Analytics Cookies</mat-checkbox>
    </div>
    <div class="item">
      <span class="titleSmall colorNeutral70">Session Recording</span>
      <div>We use session recording tools like Smartlook.com to capture user interactions on our site. This helps
        us identify usability issues and improve the user experience.</div>
      <mat-checkbox formControlName="sessionRecording">Enable Session Recording</mat-checkbox>
    </div>
    <div class="item">
      <span class="titleSmall colorNeutral70">Marketing and Advertising Cookies</span>
      <div>Marketing cookies are used to track visitors across websites. The intention is to display ads that are
        relevant and engaging for the individual user, thereby more valuable for publishers and third-party advertisers.</div>
      <mat-checkbox formControlName="marketing">Enable Marketing Cookies</mat-checkbox>
    </div>
    <button mat-flat-button color="primary" (click)="acceptAll()" type="button">Accept All</button>
    <button mat-button type="submit" class="colorNeutral50" (click)="form.markAllAsTouched()">Save preferences</button>
  </form>
</div>
