<div class="chat-icon">
  <a
    *ngIf="sidenavBarService.open"
    href="https://discord.gg/eAquGJ3SBF"
    target="_blank"
    class="join-discord"
  >
    <mat-icon>discord</mat-icon>
    <mat-icon
      *ngIf="!sidenavBarService.open"
      matTooltip="Join our Discord"
      matTooltipPosition="right"
      >discord</mat-icon
    >
    <span *ngIf="sidenavBarService.open" class="titleSmall">Join our Discord</span>
  </a>
  <a
    mat-icon-button
    *ngIf="!sidenavBarService.open"
    href="https://discord.gg/eAquGJ3SBF"
    target="_blank"
    class="join-discord"
  >
    <mat-icon
      *ngIf="!sidenavBarService.open"
      matTooltip="Join our Discord"
      matTooltipPosition="right"
      >discord</mat-icon
    >
  </a>
</div>
