import {Subject} from "rxjs";
import {Injectable, OnDestroy} from "@angular/core";

@Injectable()
/** @deprecated
 * @see takeUntilDestroyed */
export class Destroyer implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
