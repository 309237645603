import {Component, inject} from "@angular/core";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Store} from "@ngrx/store";
import {AuthActions} from "./components/auth/state/auth.actions";
import {TrackingService} from "./components/pages/system/legal/service/tracking.service";
import {legalFeature} from "./components/pages/system/legal/state/legal.state";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {tap} from "rxjs";
import {AppActions} from "./state/app.actions";
import {selectRouteUtmParam} from "./routing/state/selectors";
import {Router} from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  #store = inject(Store);
  #trackingService = inject(TrackingService);
  #router = inject(Router);

  constructor(
    private matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    this.#store.dispatch(AppActions.loadApp());
    this.#store.dispatch(AuthActions.loadUser());
    this.initCustomIcons(sanitizer);
    this.#store
      .select(legalFeature.selectCookiePreferences)
      .pipe(
        takeUntilDestroyed(),
        tap((cookiePreferences) => {
          if (!cookiePreferences) return;

          const isProductionHost = window.location.host === "symbiotai.com";
          const isAnalyticEnabledForDev = !!localStorage.getItem("symbiotai-dev-test-analytic");

          if (isProductionHost || isAnalyticEnabledForDev) {
            cookiePreferences.sessionRecording && this.#trackingService.initializeSmartlook();
            cookiePreferences.analytics && this.#trackingService.initializeGoogleAnalytics();
            cookiePreferences.marketing && this.#trackingService.initializeMetaPixel();
          }
        }),
      )
      .subscribe();
    this.#store
      .select(selectRouteUtmParam)
      .pipe(takeUntilDestroyed())
      .subscribe((utm) => {
        if (utm) {
          this.#trackingService.setUtm(utm);
          setTimeout(() => this.removeUrlParam("utm_source"), 0);
        }
      });
  }

  private removeUrlParam(utmParam: string) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.delete(utmParam);
    const u =
      [window.location.pathname, `${params}`].filter((v) => v !== "").join("?") +
      window.location.hash;
    this.#router.navigate([u], {
      replaceUrl: true,
    });
    // window.location.replace(`${window.location.pathname}?${params}${window.location.hash}`);
  }

  private initCustomIcons(sanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      "icon_google",
      sanitizer.bypassSecurityTrustResourceUrl("../assets/img/external-icons/google.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "icon_microsoft",
      sanitizer.bypassSecurityTrustResourceUrl("../assets/img/external-icons/microsoft.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "icon_openai",
      sanitizer.bypassSecurityTrustResourceUrl("../assets/img/ai-api/openai.svg"),
    );
  }
}
