import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";

@Component({
  selector: "confirmation-dialgue",
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, MatListModule],
  templateUrl: "./confirmation-dialgue.component.html",
  styleUrl: "./confirmation-dialgue.component.scss",
})
export class ConfirmationDialgueComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialgueComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      onCancel: () => Promise<void>;
      onConfirm: () => Promise<void>;
    },
  ) {}

  async onConfirm() {
    if (this.data.onConfirm) {
      await this.data.onConfirm();
    }
    this.dialogRef.close();
  }
  async onCancel() {
    if (this.data.onCancel) {
      await this.data.onCancel();
    }
    this.dialogRef.close();
  }
}
