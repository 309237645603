import {createFeature, createReducer, on} from "@ngrx/store";
import {AuthActions} from "./auth.actions";
import {PublicUserInfo, SymbiotUser} from "../../../../../.common";

export interface AuthState {
  loaded: boolean;
  user: SymbiotUser | undefined;
  publicUserInfo: PublicUserInfo | undefined;
}

const initialState = {
  loaded: false,
  user: undefined,
  publicUserInfo: undefined,
} as AuthState;

const reducer = createReducer(
  initialState,
  on(AuthActions.setUser, (state, user) => ({
    ...state,
    user,
    // loaded: true,
  })),
  on(AuthActions.setPublicUserInfo, (state, {publicUserInfo}) => ({
    ...state,
    publicUserInfo,
    loaded: true,
  })),
  on(AuthActions.notAuthenticated, (state) => ({
    ...state,
    user: undefined,
    publicUserInfo: undefined,
    loaded: true,
  })),
);

export const authFeature = createFeature({
  name: "authFeature",
  reducer,
});
