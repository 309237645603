<ui-main-menu-item link="/" icon="home" label="Home"></ui-main-menu-item>
<ui-main-menu-item link="/chat" icon="forum" label="AI Chat"></ui-main-menu-item>
<ui-main-menu-item link="/pricing" icon="currency_exchange" label="Pricing"></ui-main-menu-item>
<ui-main-menu-item>
  <ui-header-profile-menu-icon></ui-header-profile-menu-icon>
</ui-main-menu-item>
<!--<ui-main-menu-item link="/pricing" icon="currency_exchange" label="Pricing"></ui-main-menu-item>-->

<!--temporarily disabled-->
<!--<ui-main-menu-item link="/prompts/catalog" icon="import_contacts" label="Catalog">-->
<!--</ui-main-menu-item>-->
<!--<ui-main-menu-item link="/prompts/my" icon="bookmark_border" label="My prompts">-->
<!--</ui-main-menu-item>-->
<!--<ui-main-menu-item link="/prompts/create" icon="handyman" label="Create"> </ui-main-menu-item>-->
