import {NgModule} from "@angular/core";
import {CreatePromptPageModule} from "./create-prompt-page/create-prompt-page.module";
import {PromptCommonModule} from "./common/prompt-common.module";

@NgModule({
  declarations: [],
  imports: [PromptCommonModule],
  exports: [CreatePromptPageModule],
})
export class PromptModule {}
