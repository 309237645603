import {Component, EventEmitter, inject, Output} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {JsonPipe, NgIf} from "@angular/common";
import {Store} from "@ngrx/store";
import {LegalActions} from "../../../state/legal.actions";
import {legalFeature} from "../../../state/legal.state";

@Component({
  selector: "accept-cookies-customize",
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    NgIf,
    JsonPipe,
  ],
  templateUrl: "./accept-cookies-customize.component.html",
  styleUrl: "./accept-cookies-customize.component.scss",
})
export class AcceptCookiesCustomizeComponent {
  #store = inject(Store);
  cookiesPolicyVersion = this.#store.selectSignal(legalFeature.selectCookiesPolicyVersion);
  @Output() back = new EventEmitter();
  form = new FormBuilder().group({
    necessary: new FormControl(false, [Validators.requiredTrue]),
    sessionRecording: false,
    analytics: false,
    marketing: false,
    socialMedia: false,
  });

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.#store.dispatch(
      LegalActions.setCookiesPreferences({
        version: this.cookiesPolicyVersion(),
        sessionRecording: !!this.form.value.sessionRecording,
        analytics: !!this.form.value.analytics,
        marketing: !!this.form.value.marketing,
      }),
    );
  }

  hasError(fieldName: string) {
    return this.form.touched && !!this.form.get(fieldName)?.hasError("required");
  }

  acceptAll() {
    this.#store.dispatch(
      LegalActions.setCookiesPreferences({
        version: this.cookiesPolicyVersion(),
        sessionRecording: true,
        analytics: true,
        marketing: true,
      }),
    );
  }
}
