import {effect, inject, Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  TwitterAuthProvider,
} from "firebase/auth";
import {ToastrService} from "ngx-toastr";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import firebase from "firebase/compat";
import {Store} from "@ngrx/store";
import {authFeature} from "./state/auth.state";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AuthDialogComponent} from "../ui/login/dialog/auth-dialog-component";
import {PublicUserInfo, SymbiotUser} from "../../../../.common";
import {UserCollectionProvider} from "../../../../.common/collections/user-collection-provider";
import {AlertService} from "../../services/alert.service";
import UserInfo = firebase.UserInfo;

@Injectable({providedIn: "root"})
export class AuthService {
  u = inject(Store).selectSignal(authFeature.selectUser);
  readonly #user$ = new BehaviorSubject<SymbiotUser | undefined>(undefined);
  /** @deprecated use auth state from the ngrx store */
  readonly user$ = this.#user$.asObservable();
  #loginDialog = inject(MatDialog);
  #alertService = inject(AlertService);

  constructor(
    private afAuth: AngularFireAuth,
    private toastr: ToastrService, //TODO: replace with alert service
    private analytics: AngularFireAnalytics,
    private userCollectionProvider: UserCollectionProvider,
  ) {
    // tmp, while migrate
    effect(() => {
      this.#user$.next(this.u());
    });
  }

  requireUserId() {
    return this.requireUser().id;
  }

  requireUser() {
    const user = this.#user$.value;
    if (user) {
      return user;
    }
    throw new Error("User is not logged in");
  }

  private toSymbiotUser(firebaseUser: UserInfo): SymbiotUser {
    return {
      id: firebaseUser.uid,
      email: firebaseUser.email,
    } as SymbiotUser;
  }

  private updateAnalytics(user: SymbiotUser | undefined) {
    const email = user?.email;
    const promises = user
      ? [
          this.analytics.setUserId(user.id),
          this.analytics.setUserProperties({
            email,
          }),
        ]
      : [this.analytics.setUserId(""), this.analytics.setUserProperties({})];
    Promise.all(promises).then(() =>
      console.info(`Updated analytics user info. User id: ${user?.id}, email: ${email}`),
    );
  }

  async socialLogin(providerName: "google" | "facebook" | "twitter" | "microsoft") {
    const provider = this.getProvider(providerName);
    const credential = await this.afAuth.signInWithPopup(provider);
    await this.analytics.logEvent("login", {method: providerName}).catch(console.error);
    if (credential.user) {
      await this.updateUserData(credential.user);
      return;
    }
    throw new Error("User is not logged in");
  }

  private getProvider(providerName: "google" | "facebook" | "twitter" | "microsoft") {
    switch (providerName) {
      case "google":
        return new GoogleAuthProvider();
        break;
      case "facebook":
        return new FacebookAuthProvider();
        break;
      case "twitter":
        return new TwitterAuthProvider();
        break;
      case "microsoft":
        const provider = new OAuthProvider("microsoft.com");
        provider.addScope("mail.read");
        return provider;
      default:
        throw new Error("Unknown provider");
    }
  }

  async emailAuth(email: string) {
    const host = [
      window.location.hostname,
      window.location.port === "80" ? undefined : window.location.port,
    ]
      .filter((value) => !!value)
      .join(":");
    const actionCodeSettings = {
      url: `http://${host}/finishEmailSignIn`,
      handleCodeInApp: true,
    };
    const auth = getAuth();
    return await sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
      window.localStorage.setItem("emailForSignIn", email);
      console.info("Email sent");
    });
  }

  async emailAuthFinish() {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        this.toastr.error("Email is not provided");
        return "/";
      }
      try {
        const userCredential = await signInWithEmailLink(auth, email, window.location.href);
        console.info("email login", userCredential);
        const userInfo: firebase.UserInfo = userCredential.user;
        if (userInfo) {
          await this.updateUserData(userInfo);
          window.localStorage.removeItem("emailForSignIn");
          this.toastr.success(`Welcome ${userInfo.displayName || userInfo.email}!`);
          return "/chat";
        } else {
          this.#alertService.error("User is not logged in");
          return "/";
        }
      } catch (error) {
        if (error instanceof Error) {
          this.#alertService.error(error.message);
        }
        return "/";
      }
    } else {
      this.toastr.error("Invalid link");
      return "/";
    }
  }

  openAuthWindow() {
    const fullscreen = window.innerWidth < 1000;
    const config: MatDialogConfig = {
      autoFocus: false,
    };
    if (fullscreen) {
      config.maxWidth = "100vw";
      config.maxHeight = "100vh";
      config.height = "100%";
      config.width = "100%";
      config.backdropClass = "fullscreen-dialog";
      config.panelClass = "fullscreen-dialog";
    }
    this.#loginDialog.open(AuthDialogComponent, config);
    // matDialogRef
    //   .afterClosed()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((successful) => {
    //     // if (successful) {
    //     //   location.reload();
    //     // }
    //   });
  }

  private async updateUserData(firebaseUser: firebase.UserInfo) {
    // if ((await publicDataRef.get()).exists) {
    //   return;
    // }
    const data = {
      id: firebaseUser.uid,
      email: firebaseUser.email,
    } as SymbiotUser;

    const [userDoc] = await Promise.all([
      this.userCollectionProvider
        .getUserCollection()
        .doc(firebaseUser.uid)
        .set(data, {merge: true}),
      this.updateUserPublicInfo(firebaseUser),
    ]);
    return userDoc;
  }

  private updateUserPublicInfo(firebaseUser: firebase.UserInfo) {
    if (!firebaseUser.photoURL && !firebaseUser.displayName) {
      return;
    }
    const publicData = {
      photoURL: firebaseUser.photoURL,
      displayName: firebaseUser.displayName,
    } as PublicUserInfo;
    return this.userCollectionProvider
      .getUserPublicDataDocRef(firebaseUser.uid)
      .set(publicData, {merge: true});
  }

  async signOut() {
    await this.afAuth.signOut();
    this.analytics.logEvent("logout").catch(console.error);
    window.location.href = "/";
  }
}
