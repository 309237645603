import {Component, inject} from "@angular/core";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {AutofocusDirective} from "../../../../../../../shared/directives/autofocus.directive";
import {JsonPipe} from "@angular/common";
import {RouterLink} from "@angular/router";
import {createSelector, Store} from "@ngrx/store";
import {legalFeature} from "../../../state/legal.state";
import {AuthActions} from "../../../../../../auth/state/auth.actions";
import {NotificationActions} from "../../../../../../notification/state/notification.actions";

@Component({
  selector: "accept-all-legal-dialog",
  standalone: true,
  imports: [
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    AutofocusDirective,
    JsonPipe,
    RouterLink,
  ],
  templateUrl: "./accept-all-legal-dialog.component.html",
  styleUrl: "./accept-all-legal-dialog.component.scss",
})
export class AcceptAllLegalDialogComponent {
  #store = inject(Store);
  #legalVersions = this.#store.selectSignal(
    createSelector({
      termsVersion: legalFeature.selectTermsVersion,
      privacyVersion: legalFeature.selectPrivacyVersion,
      acceptableUsePolicyVersion: legalFeature.selectAcceptableUsePolicyVersion,
    }),
  );
  form = new FormBuilder().group({
    terms: new FormControl(false, [Validators.requiredTrue]),
    privacy: new FormControl(false, [Validators.requiredTrue]),
    acceptableUse: new FormControl(false, [Validators.requiredTrue]),
    ageConfirmed: new FormControl(false, [Validators.requiredTrue]),
    marketing: false,
  });

  acceptAll() {
    this.form.patchValue({
      terms: true,
      privacy: true,
      acceptableUse: true,
      ageConfirmed: true,
      marketing: true,
    });
    this.acceptSelected();
  }

  acceptSelected() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    const {terms, privacy, acceptableUse, ageConfirmed, marketing} = this.form.value;
    const legalVersions = this.#legalVersions();
    this.#store.dispatch(
      AuthActions.updateLegalSettings({
        acceptedTermsVersion: terms ? legalVersions.termsVersion : undefined,
        acceptedPrivacyVersion: privacy ? legalVersions.privacyVersion : undefined,
        acceptedAcceptableUsePolicyVersion: acceptableUse
          ? legalVersions.acceptableUsePolicyVersion
          : undefined,
        ageConfirmed: ageConfirmed ? true : undefined,
      }),
    );
    if (marketing) {
      this.#store.dispatch(NotificationActions.acceptAllEmails());
    }
  }

  hasError(control: FormControl) {
    return this.form.touched && !control.valid;
  }

  logout() {
    this.#store.dispatch(AuthActions.logout());
  }
}
