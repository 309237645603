import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "roundDigits",
})
export class RoundDigitsPipe implements PipeTransform {
  transform(value: number | any, digits: number = 2): number {
    if (isNaN(value)) throw new Error("Value is not a number");
    const factor = Math.pow(10, digits);
    return Math.round(value * factor) / factor;
  }
}
