import {
  AfterViewChecked,
  Component,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import {MainLayoutService, ScrollPosition} from "./main-layout.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {tap} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {createSelector, Store} from "@ngrx/store";
import {authFeature} from "../../auth/state/auth.state";
import {getRouterSelectors} from "@ngrx/router-store";
import {Router} from "@angular/router";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements AfterViewChecked, OnDestroy {
  router = inject(Router);
  isChatListPage = this.router.url === "/chat";

  @ViewChild("content") content?: ElementRef;
  #mainLayoutService = inject(MainLayoutService);
  #store = inject(Store);
  userLoaded = this.#store.selectSignal(authFeature.selectLoaded);
  vm$ = this.#store.selectSignal(
    createSelector({
      displayFooterMenu: () => getRouterSelectors().selectRouteDataParam("displayFooterMenu"),
    }),
  );

  private scrollCheckInit = false;
  private prevScrollPosition?: ScrollPosition;
  private scrollUpdateRequested?: ScrollPosition;
  private resizeObserver?: ResizeObserver;
  private scrollSubscription: any;

  displaySideNav?: boolean;
  vm = inject(Store).selectSignal(
    createSelector({
      routerData: getRouterSelectors().selectRouteData,
      user: authFeature.selectUser,
    }),
  );
  displayFooterMenu = true;

  constructor() {
    this.#mainLayoutService.doScroll$
      .pipe(
        takeUntilDestroyed(),
        debounceTime(50),
        tap((scroll) => (this.scrollUpdateRequested = scroll)),
        tap(() => this.updateRequestedScroll()),
      )
      .subscribe();
    effect(() => {
      const vm = this.vm();
      this.displaySideNav = vm.user && vm.routerData && vm.routerData["displaySideNav"];
    });
  }

  ngAfterViewChecked(): void {
    if (!this.scrollCheckInit && this.content) {
      this.scrollSubscription = this.content.nativeElement.addEventListener(
        "scroll",
        (e: any) => e && e.target && this.updateScrollPosition(e.target),
      );
      this.resizeObserver = new ResizeObserver(() => {
        if (this.content) {
          this.updateScrollPosition(this.content?.nativeElement);
        }
      });
      this.resizeObserver.observe(this.content.nativeElement);
      this.scrollCheckInit = true;
    }
  }

  private updateScrollPosition(e: any) {
    this.updateRequestedScroll();
    let position: ScrollPosition;
    if (e.scrollTop + e.clientHeight >= e.scrollHeight) {
      position = "bottom";
    } else if (e.scrollTop === 0) {
      position = "top";
    } else {
      position = "middle";
    }
    if (this.prevScrollPosition !== position) {
      this.#mainLayoutService.updatePosition(position);
    }
  }

  private updateRequestedScroll() {
    switch (this.scrollUpdateRequested) {
      case "top":
        this.scrollToTop();
        break;
      case "bottom":
        this.scrollToBottom();
        break;
    }
    this.scrollUpdateRequested = undefined;
  }

  private scrollToBottom(): void {
    this.scrollTo(this.content?.nativeElement.scrollHeight);
  }

  private scrollToTop() {
    this.scrollTo(0);
  }

  private scrollTo(height: number) {
    if (this.content) {
      this.content.nativeElement.scrollTop = height;
    } else {
      console.error("can't scroll yet, content element not found or loaded");
    }
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
    this.scrollSubscription?.unsubscribe();
  }
}
