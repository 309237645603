import {inject, Injectable} from "@angular/core";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";

declare const initializeSmartlook: any;
declare const initializeMetaPixel: any;

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  #utmStorageKey = "utm_source";
  #analytics = inject(AngularFireAnalytics);
  #GAActive = false;
  constructor() {
    this.#analytics.setAnalyticsCollectionEnabled(false).catch(console.error);
  }
  initializeSmartlook(): void {
    initializeMetaPixel();
  }

  initializeMetaPixel(): void {
    initializeSmartlook();
  }

  initializeGoogleAnalytics(): void {
    this.#analytics.setAnalyticsCollectionEnabled(true).catch(console.error);
    this.#GAActive = true;
    this.#logUtmEvent();
  }

  setUtm(utm: string) {
    this.#GAActive ? this.#logUtmEvent(utm) : localStorage.setItem(this.#utmStorageKey, utm);
  }

  #logUtmEvent(utm?: string | null): void {
    console.log("logUtmEvent", utm);
    if (!this.#GAActive) return;
    if (!utm)
      (utm = localStorage.getItem(this.#utmStorageKey)) &&
        localStorage.removeItem(this.#utmStorageKey);
    if (utm) {
      this.#analytics.logEvent("utm_source", {source: utm}).catch(console.error);
    }
  }
}
