import {Component, Input} from "@angular/core";

@Component({
  selector: "ui-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"],
})
export class TabComponent {
  @Input() item: any;
}
