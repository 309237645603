import {CreditsSubscriptionPlan} from "../credits/credits";

export interface StripeSubscription {
  id: string;
  product: StripeProduct;
  status: string;
  quantity: 1;
  role: "standard" | "business-pro";
  customer: string;
  plan: StripePlan;
  price: string;
  items: StripeItem[];
  metadata: CreditsSubscriptionPlan;
}

export interface StripeItem {
  plan: StripeItemPlan;
}

export interface StripeItemPlan {
  amount: number;
  currency: string;
  interval: string;
}
export interface StripeTime {
  seconds: number;
  nanoseconds: number;
}

export interface StripePlan {
  product: string;
}

export interface StripeProduct {
  id: string;
}
