export function success<T>(data?: T): SuccessResponse<T> {
  const result = {
    success: true,
  } as SuccessResponse<T>;
  if (data) {
    result.data = data;
  }
  return result;
}
export function fail(error: Error | string): ErrorResponse {
  return {
    success: false,
    message: error instanceof Error ? error.message : error,
  };
}

export type FunctionResponse<T> = SuccessResponse<T> | ErrorResponse;

export interface FnResponse<T> {
  success: boolean;
  message?: string;
  data?: T;
}

export interface SuccessResponse<T> extends FnResponse<T> {
  success: true;
  data?: T;
}

export interface ErrorResponse extends FnResponse<void> {
  success: false;
  message: string;
}

export const isErrorResponse = (response: FunctionResponse<any>): response is ErrorResponse => {
  return !response.success;
};
