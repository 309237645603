export type ModelCategory = {
  readonly id: ModelCategoryKeys;
  readonly displayName: string;
  readonly description?: string;
  readonly icon?: string;
};

export type ModelCategoryKeys =
  | "text-generation"
  | "image-generation"
  | "image-captioning"
  | "speech-generation"
  | "speech-recognition"
  | "music-generation"
  | "video-generation";

export const ModelCategoryTextGeneration: ModelCategory = {
  id: "text-generation",
  displayName: "Text Generation",
  description: "Generate text from a prompt",
};

export const ModelCategoryImageGeneration: ModelCategory = {
  id: "image-generation",
  displayName: "Image Generation",
  description: "Generate images from a prompt",
};

export const ModelCategoryImageCaptioning: ModelCategory = {
  id: "image-captioning",
  displayName: "Image Captioning",
  description: "Generate captions for images",
};

export const ModelCategorySpeechGeneration: ModelCategory = {
  id: "speech-generation",
  displayName: "Speech Generation",
  description: "Generate speech from a prompt",
};

export const ModelCategorySpeechRecognition: ModelCategory = {
  id: "speech-recognition",
  displayName: "Speech Recognition",
  description: "Recognize speech from an audio file",
};

export const ModelCategoryMusicGeneration: ModelCategory = {
  id: "music-generation",
  displayName: "Music Generation",
  description: "Generate music from a prompt",
};

export const ModelCategoryVideoGeneration: ModelCategory = {
  id: "video-generation",
  displayName: "Video Generation",
  description: "Generate videos from a prompt",
};
