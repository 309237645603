<div class="login-form">
  <div *ngIf="userLoaded">
    <div *ngIf="auth.user$ | async; then authenticated; else guest">
      <!-- template will replace this div -->
    </div>
  </div>
  <div>
    <mat-spinner *ngIf="!userLoaded"></mat-spinner>
  </div>

  <!-- User NOT logged in -->
  <ng-template #guest>
    <!--  <div class="tabs">-->
    <!--    <div [ngClass]="{'active': selectedTab === 'tab-1'}" class="tab" (click)="selectTab('tab-1')">Sign In</div>-->
    <!--    <div [ngClass]="{'active': selectedTab === 'tab-2'}" class="tab" (click)="selectTab('tab-2')">Sign Up</div>-->
    <!--  </div>-->
    <!--  <div [ngClass]="{'active': selectedTab === 'tab-1'}" class="tab-content">-->
    <div class="login-buttons">
      <button *ngIf="!emailSent" mat-stroked-button (click)="auth.socialLogin('google')">
        <!--        <img ngSrc="assets/img/external-icons/google.png" height="14" width="14" alt="Google"/> -->
        Log in with Google
        <mat-icon svgIcon="icon_google"></mat-icon>
      </button>
      <button *ngIf="!emailSent" mat-stroked-button (click)="auth.socialLogin('microsoft')">
        <!--        <img ngSrc="assets/img/external-icons/google.png" height="14" width="14" alt="Google"/> -->
        Log in with Microsoft
        <mat-icon svgIcon="icon_microsoft"></mat-icon>
      </button>
    </div>
    <form *ngIf="!emailSent" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="separator bodySmall colorPrimary80">Or with your email</div>
      <div class="email">
        <mat-form-field class="no-padding dense-2" appearance="outline" [hideRequiredMarker]="true">
          <mat-label> Email </mat-label>
          <input
            type="email"
            matInput
            formControlName="email"
            placeholder="example@symbiotai.com"
          />
        </mat-form-field>
        <div class="bodySmall colorNeutral60">
          By login in you agree to all of these
          <a target="_blank" class="colorPrimary80" routerLink="/terms">Terms of Service</a>
          and
          <a target="_blank" class="colorPrimary80" routerLink="/privacy">Privacy policy</a>.
        </div>

        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="loginForm.invalid"
          (click)="sendEmail()"
        >
          Log in / Sign up
        </button>
      </div>
    </form>
    <div *ngIf="emailSent">
      <p>Please check your email to sign in</p>
    </div>

    <!--  </div>-->

    <!--    <button mat-button (click)="auth.socialLogin('facebook')">-->
    <!--      <i class="fa fa-google"></i> Connect Facebook-->
    <!--    </button>-->
    <!--    <button mat-button (click)="auth.socialLogin('twitter')">-->
    <!--      <i class="fa fa-google"></i> Connect Twitter-->
    <!--    </button>-->
  </ng-template>

  <!-- User logged in -->
  <ng-template #authenticated>
    <div *ngIf="auth.user$ | async as user">
      <h3>Hi, {{ user.displayName || user.email }}</h3>
      <div class="imgAndLogout">
        <div><img [src]="user.photoURL" alt="{{ user.displayName }}" /></div>
        <!--        <div>-->
        <!--          <button mat-stroked-button (click)="auth.signOut()">Logout</button>-->
        <!--        </div>-->
      </div>
    </div>
  </ng-template>
</div>
