import {Component, inject} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {Store} from "@ngrx/store";
import {LegalActions} from "../../../state/legal.actions";
import {legalFeature} from "../../../state/legal.state";
import {MatDialogRef} from "@angular/material/dialog";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter, tap} from "rxjs";
import {AcceptCookiesCustomizeComponent} from "../accept-cookies-customize/accept-cookies-customize.component";

@Component({
  selector: "accept-cookies-dialog",
  standalone: true,
  imports: [MatButtonModule, AcceptCookiesCustomizeComponent],
  templateUrl: "./accept-cookies-dialog.component.html",
  styleUrl: "./accept-cookies-dialog.component.scss",
})
export class AcceptCookiesDialogComponent {
  #store = inject(Store);
  #dialogRef = inject(MatDialogRef);
  cookiesPolicyVersion = this.#store.selectSignal(legalFeature.selectCookiesPolicyVersion);
  cookiePreferences$ = this.#store.select(legalFeature.selectCookiePreferences);
  customizeEnabled = false;

  constructor() {
    this.cookiePreferences$
      .pipe(
        takeUntilDestroyed(),
        filter((cookiePreferences) => !!cookiePreferences),
        tap(() => this.#dialogRef.close()),
      )
      .subscribe();
  }

  acceptAll() {
    this.#store.dispatch(
      LegalActions.setCookiesPreferences({
        version: this.cookiesPolicyVersion(),
        sessionRecording: true,
        analytics: true,
        marketing: true,
      }),
    );
  }

  customize() {
    this.customizeEnabled = true;
  }
}
