import {ErrorHandler, isDevMode, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";

import {routes} from "./routing/routes";
import {AppComponent} from "./app.component";
import {AngularFireModule} from "@angular/fire/compat";
import {environment} from "../environments/environment";
import {PagesModule} from "./components/pages/pages.module";
import {LandingLayoutComponent} from "./components/layouts/landing-layout/landing-layout.component";
import {MainLayoutComponent} from "./components/layouts/main-layout/main-layout.component";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ScreenTrackingService, UserTrackingService} from "@angular/fire/analytics";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatCardModule} from "@angular/material/card";
import {ToastrModule} from "ngx-toastr";
import {GlobalErrorHandler} from "./error-handlers/GlobalErrorHandler";
import {UiModule} from "./components/ui/ui.module";

import {USE_EMULATOR as USE_AUTH_EMULATOR} from "@angular/fire/compat/auth";
import {USE_EMULATOR as USE_DATABASE_EMULATOR} from "@angular/fire/compat/database";
import {
  AngularFirestore,
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from "@angular/fire/compat/firestore";
import {REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from "@angular/fire/compat/functions";
import {USE_EMULATOR as USE_STORAGE_EMULATOR} from "@angular/fire/compat/storage";
import {ProfileModule} from "./components/pages/profile/profile.module";
import {AngularFireAnalyticsModule} from "@angular/fire/compat/analytics";
import {MatSidenavModule} from "@angular/material/sidenav";
import {provideState, provideStore, StoreModule} from "@ngrx/store";
import {provideEffects} from "@ngrx/effects";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import {chatFeature} from "./components/pages/chat/state/chat.state";
import {ChatEffects} from "./components/pages/chat/state/chat.effects";
import {provideRouterStore, routerReducer} from "@ngrx/router-store";
import {provideRouter, RouterModule} from "@angular/router";
import {authFeature} from "./components/auth/state/auth.state";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {AuthEffects} from "./components/auth/state/auth.effects";
import {UserCollectionProvider} from "../../.common/collections/user-collection-provider";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {legalFeature} from "./components/pages/system/legal/state/legal.state";
import {LegalEffects} from "./components/pages/system/legal/state/legal.effects";
import {NotificationEffects} from "./components/notification/state/notification.effect";
import {CreditsService} from "../../.common/credits/credits.service";
import {CreditsWebDb} from "./components/shared/credits/credits-web.db";
import {UserSubscriptionWebDb} from "./components/shared/user-subscriptions/user-subscription-web.db";

@NgModule({
  declarations: [AppComponent, LandingLayoutComponent, MainLayoutComponent],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({
      synchronizeTabs: true,
    }),
    AngularFireAnalyticsModule,
    HttpClientModule,

    PagesModule,
    ProfileModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    UiModule,
    MatSidenavModule,
    StoreModule.forRoot({
      router: routerReducer,
    }),
    RouterModule.forRoot(routes),
    MatProgressBarModule,
    MatDialogModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    CreditsWebDb,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: UserCollectionProvider,
      useFactory: (firestore: AngularFirestore) => new UserCollectionProvider(firestore.firestore),
      deps: [AngularFirestore],
    },
    {
      provide: UserSubscriptionWebDb,
      useFactory: () => new UserSubscriptionWebDb(),
    },
    {
      provide: CreditsService,
      useFactory: (creditsDb: CreditsWebDb, subDb: UserSubscriptionWebDb) =>
        new CreditsService(creditsDb, subDb),
      deps: [CreditsWebDb, UserSubscriptionWebDb],
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // },

    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators ? ["http://localhost:9099"] : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.useEmulators ? ["localhost", 9000] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulators ? ["localhost", 8080] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ["localhost", 5001] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.useEmulators ? ["localhost", 9199] : undefined,
    },
    {provide: REGION, useValue: environment.firebase.locationId},

    // NgRx
    provideRouter(routes),
    provideStore({
      router: routerReducer,
    }),
    provideState(authFeature),
    provideState(chatFeature),
    provideState(legalFeature),
    provideRouterStore(),
    provideEffects([AuthEffects, ChatEffects, LegalEffects, NotificationEffects]),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
