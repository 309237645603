import firebase from "firebase/compat";
import {firestore as firestoreAdmin} from "firebase-admin";

export function isFirestoreTimestamp(obj: any): obj is firebase.firestore.Timestamp {
  return obj && obj.toDate !== undefined && typeof obj.toDate === "function";
}

export function removeFieldValueType<T>(obj: T | firestoreAdmin.FieldValue): T {
  return obj as T;
}
