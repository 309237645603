import {Component, Input} from "@angular/core";
import {ApiType} from "../../../../../.common";

@Component({
  selector: "ui-ai-model",
  templateUrl: "./ai-model.component.html",
  styleUrls: ["./ai-model.component.scss"],
})
export class AiModelComponent {
  @Input() api!: ApiType;
  @Input()
  colorClass!: string | "";
  get modelIcon() {
    switch (this.api) {
      case "dall-e":
        return "image";
      case "cohere":
      case "gpt3":
        return "article";
      default:
        return "question_mark";
    }
  }
}
