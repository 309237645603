import {Component} from "@angular/core";
import {LinkedAccountsService} from "../../../../../../../services/linked-accounts.service";
import {AuthService} from "../../../../../../auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {firstValueFrom, Observable, of} from "rxjs";
import {AlertService} from "../../../../../../../services/alert.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {
  LinkedAccount,
  LinkedAccountStatus,
} from "../../../../../../../../../.common/linked-accounts/linked-accounts";
import {isErrorResponse} from "../../../../../../../../../.common/function-response";

@Component({
  selector: "app-linked-account",
  templateUrl: "./linked-account.component.html",
  styleUrls: ["./linked-account.component.scss"],
})
export class LinkedAccountComponent {
  stripeLinkedAccount$: Observable<LinkedAccount | null> = of(null);
  loaded = false;
  waitForRedirect = false;
  updatingAccountAfterReturn = false;

  constructor(
    private linkedAccountsService: LinkedAccountsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private functions: AngularFireFunctions,
    private alertService: AlertService,
    private router: Router,
  ) {
    this.checkBackRedirect();
    this.authService.user$.pipe(takeUntilDestroyed()).subscribe(async (user) => {
      if (user) {
        this.loaded = false;
        this.stripeLinkedAccount$ = this.linkedAccountsService.getLinkedAccountForType(
          user.id,
          "stripe",
        );
        this.stripeLinkedAccount$.subscribe(() => (this.loaded = true));
      }
    });
  }

  async connectStripe() {
    this.waitForRedirect = true;
    try {
      const user = await firstValueFrom(this.authService.user$);
      await this.linkedAccountsService.initNewAccountCreation(user!.id);
      const subscription = this.stripeLinkedAccount$?.subscribe((acc) => {
        if (acc && acc.status === "userInfoRequired") {
          subscription?.unsubscribe();
          this.redirectToStripe();
        }
      });
    } catch (e) {
      console.error(e);
      this.waitForRedirect = false;
    }
  }

  async redirectToStripe() {
    this.waitForRedirect = true;
    try {
      const url = `${document.location.origin}${document.location.pathname}`;
      const stripeLinkResponse = await this.linkedAccountsService.createStripeAccountLink({
        type: "account_onboarding",
        backUrl: `${url}#returnFrom=stripe`,
      });
      if (isErrorResponse(stripeLinkResponse)) {
        this.waitForRedirect = false;
        this.alertService.error(stripeLinkResponse.message, "Some error happens while redirect");
      } else {
        this.alertService.success("Redirecting to stripe...");
        window.open(stripeLinkResponse.data?.url, "_self");
        this.waitForRedirect = false;
      }
    } catch (e) {
      this.waitForRedirect = false;
    }
  }

  humanReadableStatus(status: LinkedAccountStatus | undefined) {
    switch (status) {
      case "connected":
        return "Connected";
      case "failed":
        return "Failed";
      case "init":
      case "userInfoRequired":
        return "Account initiation...";
      default:
        return "Not connected";
    }
  }

  private checkBackRedirect() {
    const splitFragment = this.activatedRoute.snapshot.fragment?.split("=");
    if (splitFragment && splitFragment?.length === 2 && splitFragment[0] === "returnFrom") {
      this.updatingAccountAfterReturn = true;
      switch (splitFragment[1]) {
        case "stripe":
          const fn = this.functions.httpsCallable("updateStripeLinkedAccountInfo_v_0_1_0");
          firstValueFrom(fn({}))
            .then(() => (this.updatingAccountAfterReturn = false))
            .catch((error) => {
              this.alertService.error(error);
            })
            .finally(() => {
              this.updatingAccountAfterReturn = false;
              this.router.navigate([]).catch(console.warn);
            });
          break;
        default:
          this.updatingAccountAfterReturn = false;
          this.router.navigate([]).catch(console.warn);
          console.warn("Unknown returnFrom", splitFragment[1]);
      }
    }
  }
}
