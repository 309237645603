import {Component, HostListener, OnInit} from "@angular/core";
import {AiPromptService, PromptsListMode} from "../../../../services/ai-prompt.service";
import {AuthService} from "../../../auth/auth.service";
import firebase from "firebase/compat/app";
import {AlertService} from "src/app/services/alert.service";
import {Title} from "@angular/platform-browser";
import {map, tap} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {AiPrompt} from "../../../../../../.common";
import Timestamp = firebase.firestore.Timestamp;

const titles = {
  my: "My Prompts",
  public: "Catalog",
};

@Component({
  selector: "app-prompts-list-page",
  templateUrl: "./list-prompts-page.component.html",
  styleUrls: ["./list-prompts-page.component.scss"],
})
export class ListPromptsPageComponent implements OnInit {
  prompts: AiPrompt[] = [];
  promptsLoaded = false;
  promptsListMode: PromptsListMode = "public";
  pageSize = 50;
  lastPageSize: number | undefined;

  constructor(
    public authService: AuthService,
    private aiPromptService: AiPromptService,
    private alertService: AlertService,
    private titleService: Title,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.prompts = [];
    this.route.snapshot.url[0]?.path === "my"
      ? (this.promptsListMode = "my")
      : (this.promptsListMode = "public");

    this.authService.user$
      .pipe(
        map((user) => (user ? user.id : undefined)),
        tap((userId) => this.loadNextPage(this.promptsListMode === "my" ? userId : undefined)),
      )
      .subscribe();
    this.titleService.setTitle(`${titles[this.promptsListMode]} - Symbiot AI`);
  }

  isPromptPresent() {
    return this.prompts.length > 0;
  }

  private async loadNextPage(userId: string | undefined) {
    try {
      this.promptsLoaded = false;
      let lastPromptCreated;
      if (this.prompts.length > 0) {
        lastPromptCreated = this.prompts[this.prompts.length - 1].created as Timestamp;
      }
      const promptsPage = await this.aiPromptService.listPrompts(
        this.pageSize,
        userId,
        lastPromptCreated,
      );
      this.lastPageSize = promptsPage.length;
      this.prompts.push(...promptsPage);
    } catch (error) {
      this.alertService.error("Error loading prompts");
    } finally {
      this.promptsLoaded = true;
    }
  }

  @HostListener("window:scroll", ["$event"])
  async onWindowScroll() {
    const pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight +
      64;
    const max = document.documentElement.scrollHeight;
    if (pos === max && this.lastPageSize === this.pageSize) {
      await this.loadNextPage(this.promptsListMode);
    }
  }
}
