import {Component, Input} from "@angular/core";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
  @Input() header = "Page not found";
  @Input() message = "The page you are looking for does not exist.";
}
