import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../auth/auth.service";
import {takeUntil} from "rxjs";
import {Destroyer} from "../../../_helpers/Destroyer";

@Component({
  selector: "app-auth-dialog",
  templateUrl: "auth-dialog-component.html",
  styleUrls: ["auth-dialog-component.scss"],
})
export class AuthDialogComponent extends Destroyer {
  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    private authService: AuthService,
  ) {
    super();
    authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.dialogRef.close(true);
      }
    });
  }
}
