import {firestore as firestoreAdmin} from "firebase-admin";
import {ChatParticipant, ChatParticipantAiApi, ChatParticipantHuman} from "./chat-participant";
import {AiContentType, isMultimodelAiContentType} from "../ai-prompt";
import {ModelParameterKey} from "../ai-models/model-parameters";

export interface ChatMessage {
  id: string;
  sender: ChatParticipant;
  /** @deprecated use content instead */
  text: string;
  content: ChatContent[];
  createdAt: firestoreAdmin.FieldValue | Date;
  updatedAt: firestoreAdmin.FieldValue | Date;
  mentions?: ChatParticipant[];
  typing?: boolean | firestoreAdmin.FieldValue;
  replyMessageId?: string;
  metadata?: {[key: string]: any} | ChatMessageAiApiMetadata;
}

export interface ChatMessageHuman extends ChatMessage {
  sender: ChatParticipantHuman;
  metadata?: ChatMessageHumanMetadata;
}

export type ChatMessageType = "text" | "image_b64" | "image_storage";

export interface ChatMessageAiApi extends ChatMessage {
  sender: ChatParticipantAiApi;
  metadata: ChatMessageAiApiMetadata;
}

type ChatContentBase = {
  id: string;
  type: ChatMessageType;
};

export type ChatContent = ChatContentBase &
  (ChatContentText | ChatContentStorageImage | ChatContentB64Image);

export type ChatContentText = ChatContentBase & {
  type: "text";
  text: string;
};

export type ChatContentB64Image = ChatContentBase & {
  type: "image_b64";
  altText?: string;
  width?: number;
  height?: number;
  b64?: string;
};

export type ChatContentStorageImage = ChatContentBase & {
  type: "image_storage";
  storagePath: string;
  contentUrl?: string;
  altText?: string;
  width?: number;
  height?: number;
};

export const isSupportedModelContentType = (
  aiContentType: AiContentType,
  type: ChatMessageType,
) => {
  const contentTypes = isMultimodelAiContentType(aiContentType)
    ? aiContentType.input
    : [aiContentType];

  switch (type) {
    case "text":
      return contentTypes.includes("text") || contentTypes.includes("chat");
    case "image_b64":
    case "image_storage":
      return contentTypes.includes("image");
    default:
      return false;
  }
};

export const isChatTextContent = (content: ChatContent): content is ChatContentText => {
  return content.type === "text";
};

export const isChatStorageImageContent = (
  content: ChatContent,
): content is ChatContentStorageImage => {
  return content.type === "image_storage";
};

export const isChatB64ImageContent = (content: ChatContent): content is ChatContentB64Image => {
  return content.type === "image_b64";
};

export type ChatMessageAiApiMetadata = {
  contentType: AiContentType;
  modelParameters?: Record<string, any>;
  outOfCredits?: boolean;
  usage?: number;
  finishReason?: "length" | string;

  storagePath?: string;
  contentUrl?: string;
  altText?: string;
  width: number;
  height: number;
  totalTime?: number;
};

export type ChatMessageHumanMetadata = {
  modelParameters?: Record<string, any> | Record<ModelParameterKey, any>;
  contextMessageIds?: string[];
  // contentType: AiPromptContentType; // can be used for chatLastMessage when we need to get contentType - text
};
