import {Component, inject} from "@angular/core";
import {filter, Observable, tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SidenavBarService} from "../../../services/sidenav-bar.service";
import {Store} from "@ngrx/store";
import {authFeature} from "../../auth/state/auth.state";
import {switchMap} from "rxjs/operators";
import {CreditsService} from "../../../../../.common/credits/credits.service";
import {Credits} from "../../../../../.common/credits/credits";

@Component({
  selector: "ui-credits-block",
  templateUrl: "./credits-block.component.html",
  styleUrls: ["./credits-block.component.scss"],
})
export class CreditsBlockComponent {
  #creditsService = inject(CreditsService);
  user$ = inject(Store).select(authFeature.selectUser);
  sidenavBarService = inject(SidenavBarService);

  percentageUsedCredits = 0;
  loggedIn = false;
  credits$: Observable<Credits>;
  constructor() {
    this.credits$ = this.user$.pipe(
      tap((user) => (this.loggedIn = !!user)),
      filter((user) => !!user),
      switchMap((user) => this.#creditsService.calculateCurrentCredits(user!.id)),
      tap((credits) => this.calculatePercentage(credits)),
    );
    this.credits$.pipe(takeUntilDestroyed()).subscribe();
  }

  private calculatePercentage(credits: Credits) {
    this.percentageUsedCredits =
      (credits.remainingCredits / credits.subscriptionPlan.maxCredits) * 100;
  }
}
