import {Component, OnInit, signal, WritableSignal} from "@angular/core";
import {AiPromptService} from "../../../../services/ai-prompt.service";
import {ActivatedRoute, Params} from "@angular/router";
import {AuthService} from "../../../auth/auth.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {CheckoutService} from "../../../../services/checkout.service";
import {ToastrService} from "ngx-toastr";
import {combineLatest, takeUntil} from "rxjs";
import {Destroyer} from "../../../_helpers/Destroyer";
import {AiApiService} from "../../../../services/ai-api.service";
import {AlertService} from "../../../../services/alert.service";
import {SubscriptionService} from "src/app/services/subscription.service";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {Title} from "@angular/platform-browser";
import {
  AiPrompt,
  AiPromptBodyParameter,
  isAiPrompt,
  PaymentDetails,
  SymbiotUser,
} from "../../../../../../.common";
import {isStateResolved, ObservableState} from "../../../../../../.common/observable-helpers";
import {AiApi} from "../../../../../../.common/ai-models";

@Component({
  selector: "app-prompts-single",
  templateUrl: "./single-prompt-page.component.html",
  styleUrls: ["./single-prompt-page.component.scss"],
})
export class SinglePromptPageComponent extends Destroyer implements OnInit {
  readonly promptId: string;
  executeInProgress = false;
  paramsForm = this.formBuilder.group({});
  isOwner = false;
  promptPayed = false;
  checkoutInProgress = false;
  paymentDetails: PaymentDetails | undefined;
  promptParameters: WritableSignal<AiPromptBodyParameter[]> = signal([]);
  possibleToExecute = false;

  constructor(
    public authService: AuthService,
    public aiApiService: AiApiService,
    private aiPromptService: AiPromptService,
    private formBuilder: FormBuilder,
    private checkoutsService: CheckoutService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private subscriptionService: SubscriptionService,
    private analytics: AngularFireAnalytics,
    private titleService: Title,
  ) {
    super();
    this.promptId = this.route.snapshot.params["promptId"];
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const newParameters: Params = {
        promptId: this.promptId, // Replace with the actual parameter key from the route
        // Add any other parameters as needed
      };
      this.aiApiService.parameters.next(newParameters);
    });

    // When user and prompt are loaded, set the prompt variables
    // and request if user has paid access to the prompt
    combineLatest([this.aiApiService.prompt$, this.authService.user$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([prompt, user]) => {
        if (isStateResolved<AiPrompt>(prompt)) {
          this.logAnalyticsEvent(prompt, user).then();
          if (user) {
            this.isOwner = user.id === prompt.ownerId;
            this.possibleToExecute = this.isOwner;
            if (prompt.publishSettings?.isPayed && user.id !== prompt.ownerId) {
              this.subscribeToPaymentDetails(user);
            }
            if (prompt.publishSettings?.subscriptionAvailable && user.id !== prompt.ownerId) {
              this.subscribeToUserSubscription(user);
            }
          }
          const savedState: any = this.getFormSavedState();
          const controls: {[key: string]: FormControl} = {};
          if (prompt.parameters && prompt.parameters.length > 0) {
            prompt.parameters.forEach((parameter: any) => {
              const value = savedState[parameter.selector] || parameter.defaultValue || "";
              controls[parameter.selector] = new FormControl(value, [Validators.required]);
            });
          }
          this.paramsForm = this.formBuilder.group(controls);

          this.setPromptVariables(prompt);
          this.titleService.setTitle(`${prompt.displayName} - Symbiot AI`);
        }
      });
  }

  private subscribeToUserSubscription(user: SymbiotUser) {
    this.subscriptionService.getSubscriptionsForUser(user.id).subscribe({
      next: (subscription) => {
        if (subscription && subscription.status == "active") {
          this.possibleToExecute = true;
        }
      },
      error: (e) => {
        this.alertService.error(e);
      },
    });
  }

  private subscribeToPaymentDetails(user: SymbiotUser) {
    this.checkoutsService
      .getPaymentDetails(user.id, this.promptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (paymentDetails) => {
          if (paymentDetails.exists) {
            this.promptPayed = true;
            this.possibleToExecute = true;
            this.paymentDetails = paymentDetails.data();
          }
        },
        error: (e) => console.error(e),
      });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.aiApiService.parameters.next({});
  }

  private setPromptVariables(prompt: AiPrompt) {
    if (prompt.parameters && prompt.parameters.length > 0) {
      this.promptParameters.set(prompt.parameters);
    }

    if (prompt.publishSettings && !prompt.publishSettings.isPayed) {
      this.possibleToExecute = true;
    }
  }

  execute() {
    this.executeInProgress = true;
    return this.aiPromptService
      .execute(this.promptId, this.paramsForm.value)
      .catch(this.alertService.error)
      .finally(() => (this.executeInProgress = false));
  }

  saveForm() {
    localStorage.setItem(this.getFormStateKey(), JSON.stringify(this.paramsForm.value));
  }

  private getFormStateKey() {
    return `single-formState-${this.promptId}`;
  }

  private getFormSavedState() {
    return JSON.parse(localStorage.getItem(this.getFormStateKey()) || "{}");
  }

  async checkout() {
    this.checkoutInProgress = true;
    const resp = await this.checkoutsService.checkoutPrompt(this.promptId);
    if (resp.success) {
      const href = resp.data?.url;
      if (href) {
        window.location.href = href;
      } else {
        this.toastrService.error("Link to checkout page is not provided. It should not happen.");
      }
    } else {
      this.checkoutInProgress = false;
      this.toastrService.error(resp.message);
    }
  }

  hasParams(prompt: AiPrompt) {
    return prompt.parameters.length > 0;
  }

  isAiPrompt(prompt: ObservableState<AiPrompt>): prompt is AiPrompt {
    return isAiPrompt(prompt);
  }

  isStateResolved(state: ObservableState<AiApi>) {
    return isStateResolved<AiApi>(state);
  }

  private async logAnalyticsEvent(prompt: AiPrompt, user?: SymbiotUser) {
    await this.analytics.logEvent("view_prompt", {
      promptId: prompt.id,
      userId: user?.id,
    });
  }
}
