<div class="side-nav" [class.open]="sidenavBarService.open" [class.chat]="chatExists" *ngIf="displaySideNav || menuExist || chatExists">
  <div class="menu-content">
    <ul class="menu" *ngIf="menuExist">
      <li *ngFor="let item of menuItems">
        <a
          mat-button
          [routerLink]="[item.link]"
          routerLinkActive="active-point"
          class="menu-item unfolded"
        >
          <mat-icon color="primary">{{ item.icon }}</mat-icon>
          <span class="titleMedium">{{ item.label }}</span>
        </a>
      </li>
    </ul>
    <router-outlet name="side-nav"></router-outlet>

    <div class="bottom-block">
      <ui-credits-block></ui-credits-block>
      <app-discord-widget></app-discord-widget>
      <div class="footer titleSmallNeutral50">
        <a routerLink="/terms" target="_blank">Terms</a>
        <span>.</span>
        <a routerLink="/privacy" target="_blank">Privacy policy</a>
      </div>
    </div>
  </div>
</div>
