import {BehaviorSubject} from "rxjs";

export type ObservableState<T> =
  | T
  | "loading"
  | "not-found"
  // null used when loading is complete and there is no data applicable
  | null;
export const newLoadingState = <T>(
  state: ObservableState<any> = "loading",
): BehaviorSubject<ObservableState<T>> => new BehaviorSubject<ObservableState<T>>(state);
export const isStateResolved = <T>(state: ObservableState<any>): state is T => {
  return state !== "loading" && state !== "not-found" && state !== null;
};
