import {Component, inject} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Store} from "@ngrx/store";
import {MarkdownModule} from "ngx-markdown";
import {legalFeature} from "../../../state/legal.state";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
  standalone: true,
  imports: [MarkdownModule],
})
export class PrivacyComponent {
  version = inject(Store).selectSignal(legalFeature.selectTermsVersion);
  constructor(private titleService: Title) {
    this.titleService.setTitle("Privacy - Symbiot AI");
  }
}
