import {createFeature, createReducer, on} from "@ngrx/store";
import {LegalActions} from "./legal.actions";

export interface CookiePreferences {
  version: string;
  analytics: boolean;
  sessionRecording: boolean;
  marketing: boolean;
}
export interface LegalState {
  privacyVersion: string;
  termsVersion: string;
  acceptableUsePolicyVersion: string;
  cookiesPolicyVersion: string;
  showAcceptCookiesDialog: boolean;
  cookiePreferences: CookiePreferences | undefined;
  showAcceptTermsDialog: boolean;
}

export const initialState = {
  privacyVersion: "1.0.0",
  termsVersion: "1.0.0",
  acceptableUsePolicyVersion: "1.0.0",
  cookiesPolicyVersion: "1.0.0",
  showAcceptCookiesDialog: false,
  cookiePreferences: undefined,
  showAcceptTermsDialog: false,
} as LegalState;

const reducer = createReducer(
  initialState,
  on(LegalActions.setCookiesPreferences, (state, {cookiePreferences}) => ({
    ...state,
    cookiePreferences,
  })),
  on(LegalActions.showAcceptTermsDialog, (state) => ({
    ...state,
    showAcceptTermsDialog: true,
  })),
  on(LegalActions.hideAcceptTermsDialog, (state) => ({
    ...state,
    showAcceptTermsDialog: false,
  })),
);

export const legalFeature = createFeature({
  name: "legalFeature",
  reducer,
});
