import {AiContentType, ApiType} from "../";

import {ModelParameterKey} from "./model-parameters";
import {ModelCategory} from "./model-category";

export interface AiApi {
  readonly id: ApiType;
  readonly name: string;
  readonly description?: string;
  readonly models: AiModel[];
  /**
   * @deprecated use models type instead
   */
  readonly contentType: AiContentType;
  readonly defaultModelId: string;

  getModel(model: string): AiModel;

  getDefaultModelParameters?(model: string): AiParameter[];
}

export interface AiParameterOption {
  readonly id: string;
  // TODO: remove question
  readonly displayName?: string;
  // TODO: remove question
  readonly description?: string;
}

export interface AiModel extends AiParameterOption {
  readonly prices: Record<string, number>;
  readonly contentType: AiContentType;
  readonly parameters: AiParameter[];
  readonly getMaxTokens?: (modelParams: Record<ModelParameterKey, any>) => number;
  readonly icon?: string;
  readonly outdated?: boolean;
  readonly categories: ModelCategory[];
}

export type AiParameter = {
  key: ModelParameterKey;
  displayName: string;
  description?: string;
  type: AiParameterType;
  /**
   * Map the parameter to an interface
   */
  mapTo?: "prompt" | "image" | "parameters" | "advanced";
  required?: boolean;
  disabled?: boolean;
  /**
   * If true, the parameter will not be shown in the UI
   */
  hidden?: boolean;
  /**
   * Options are required for type "select"
   */
  options?: AiParameterOption[];
  limits?: {
    min?: number;
    max?: number;
    step?: number;
  };
  displayIcon?: string;
  defaultValue?: any;
  skipForAi?: boolean;
};

export type AiParameterType = "text" | "number" | "boolean" | "range" | "select" | "image";
