<div class="header">
  <button class="left-close" mat-icon-button (click)="dialogRef.close()">
    <mat-icon class="colorNeutral60">arrow_back_ios_new</mat-icon>
  </button>
  <span class="headlineSmall colorNeutral80">Log in / Sign up</span>

  <button class="right-close" mat-icon-button (click)="dialogRef.close()">
    <mat-icon class="colorNeutral60">close</mat-icon>
  </button>
</div>

<ui-login></ui-login>
