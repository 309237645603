export interface SymbiotUser extends SymbiotUserLegalSettings {
  id: string;
  /** @deprecated */
  uid: string;
  email: string;
  /** @deprecated */
  displayName?: string;
  /** @deprecated */
  photoURL?: string;
  legal?: SymbiotUserLegalSettings;
}

export interface SymbiotUserLegalSettings {
  acceptedPrivacyVersion?: string;
  acceptedTermsVersion?: string;
  acceptedAcceptableUsePolicyVersion?: string;
  acceptableUsePolicyVersion?: string;
  ageConfirmed?: boolean;
}

export interface PublicUserInfo {
  photoURL?: string;
  displayName?: string;
}

export interface NotificationSettings {
  marketing: boolean;
  newsletter: boolean;
  featureUpdates: boolean;
  feedbackAndSurveys: boolean;
  educationalContent: boolean;
  eventInvitations: boolean;
  personalizedRecommendations: boolean;
  referralProgramInfo: boolean;
}
