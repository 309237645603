import {Component, inject, OnInit} from "@angular/core";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {Destroyer} from "../../_helpers/Destroyer";
import {ChatService} from "../../pages/chat/services/chat.service";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {
  AsyncPipe,
  DatePipe,
  JsonPipe,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from "@angular/common";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {createSelector, Store} from "@ngrx/store";
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {chatFeature} from "../../pages/chat/state/chat.state";
import {authFeature} from "../../auth/state/auth.state";
import {MatDividerModule} from "@angular/material/divider";
import {ChatActions} from "../../pages/chat/state/chat.actions";
import {FormsModule} from "@angular/forms";
import {ProfileIconComponent} from "../header/profile-icon/profile-icon.component";
import {Chat} from "../../../../../.common/chat/chat";

@Component({
  selector: "app-chat-navbar",
  templateUrl: "./chat-navbar.component.html",
  styleUrls: ["./chat-navbar.component.scss"],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    DatePipe,
    AsyncPipe,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    NgIf,
    NgForOf,
    RouterLink,
    RouterLinkActive,
    NgTemplateOutlet,
    JsonPipe,
    MatDividerModule,
    FormsModule,
    ProfileIconComponent,
  ],
})
export class ChatNavbarComponent extends Destroyer implements OnInit {
  store = inject(Store);
  // selectedChats: Set<string> = new Set();
  // singleMultipleToggle = true;

  // isChatlistSelectActive = false;

  vm$ = this.store.selectSignal(
    createSelector({
      chat: chatFeature.selectCurrentChat,
      chatList: chatFeature.selectChatList,
      user: authFeature.selectUser,
      isChatlistSelectActive: chatFeature.selectIsChatlistSelectActive,
      navbarSelectedChats: chatFeature.selectNavbarSelectedChats,
    }),
  );

  constructor(
    public authService: AuthService,
    public chatService: ChatService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(ChatActions.loadChatList());
  }

  toggleSelectChat(id: string) {
    this.store.dispatch(ChatActions.navbarChatsSelectionToggle(id));
  }
  chatIdentity(index: number, chat: Chat) {
    return JSON.stringify(chat);
  }
  hasChatParticipant(chat: Chat | undefined) {
    return chat && chat.modelParticipants && chat.modelParticipants.length > 0;
  }
}
