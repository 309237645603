import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, map, Observable} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {AuthService} from "../components/auth/auth.service";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {RecordNotFoundError} from "../../../.common/errors";
import {aiPrompts} from "../../../.common/firestore-collections";
import {AiPrompt, AiPromptBodyParameter, AiPromptExecutionRequest} from "../../../.common";
import {FnResponse} from "../../../.common/function-response";
import {AiPromptResponse} from "../../../.common/ai-prompt-response";
import FieldValue = firebase.firestore.FieldValue;

export class PromptNotFoundError extends RecordNotFoundError {
  constructor(id: string) {
    super("AiPrompt", id);
  }
}

@Injectable({
  providedIn: "root",
})
export class AiPromptService {
  private aiPromptCollection = this.afs.firestore.collection(aiPrompts);

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private authService: AuthService,
    private fns: AngularFireFunctions,
  ) {}

  async create(prompt: Partial<AiPrompt>) {
    const id = this.afs.createId();
    const promptRef = this.aiPromptCollection.doc(id);

    prompt.id = id;
    prompt.ownerId = this.authService.requireUserId();
    prompt.created = FieldValue.serverTimestamp();

    await promptRef.set(prompt);
    return promptRef.id;
  }

  getObservable(id: string): Observable<AiPrompt> {
    const collectionRef = this.afs.collection<AiPrompt>(aiPrompts);
    return collectionRef
      .doc(id)
      .snapshotChanges()
      .pipe(
        map((snapshot) => {
          if (!snapshot.payload.exists) {
            throw new PromptNotFoundError(id);
          }
          return snapshot.payload.data();
        }),
      );
  }

  async get(id: string): Promise<AiPrompt> {
    const snapshot = await this.aiPromptCollection.doc(id).get();
    if (!snapshot.exists) {
      throw new PromptNotFoundError(id);
    }
    return snapshot.data() as AiPrompt;
  }

  async update(id: string, prompt: Partial<AiPrompt>) {
    const promptRef = this.aiPromptCollection.doc(id);
    await promptRef.update(prompt);
  }

  async getTemplateVariables(tmpl: string): Promise<string[]> {
    return (await firstValueFrom(
      this.http.post(`${environment.apiUrl}GetTemplateVariables`, tmpl),
    )) as string[];
  }

  async saveTemplate(promptId: string, tmpl: string, params: AiPromptBodyParameter[]) {
    const userId = this.authService.requireUserId();
    await Promise.all([
      this.storage.upload(`templates/${userId}/${promptId}`, new Blob([tmpl]), {
        customMetadata: {},
      }),
      this.update(promptId, {parameters: params}),
    ]);
  }

  async getTemplate(promptId: string): Promise<string> {
    const userId = this.authService.requireUserId();
    const url = await firstValueFrom(
      this.storage.ref(`templates/${userId}/${promptId}`).getDownloadURL(),
    );
    return await firstValueFrom(this.http.get(url, {responseType: "text"}));
  }

  async parseTemplate(template: string, params: string[]) {
    const source = this.http.post<string>(
      `${environment.apiUrl}ParseTemplate`,
      {
        template,
        params,
      },
      {responseType: "text" as "json"},
    );
    return firstValueFrom(source);
  }

  async updatePublishSettings(promptId: string, publishSettings: any) {
    await this.aiPromptCollection.doc(promptId).update({publishSettings});
  }

  async listPrompts(
    pageSize: number,
    userId: string | undefined,
    startAfter?: firebase.firestore.Timestamp,
  ): Promise<AiPrompt[]> {
    let collection;
    if (userId) {
      collection = this.aiPromptCollection.where("ownerId", "==", userId);
    } else {
      collection = this.aiPromptCollection.where("publishSettings.visibility", "==", "public");
    }
    let query = collection.orderBy("created", "desc").limit(pageSize);
    if (startAfter) {
      query = query.startAfter(startAfter);
    }
    return query.get().then((snapshot) =>
      snapshot.docs.map((doc) => {
        const prompt = doc.data() as AiPrompt;
        prompt.id = doc.id;
        return prompt;
      }),
    );
  }

  async execute(promptId: string, parameters: Record<string, string>) {
    const fn = this.fns.httpsCallable<AiPromptExecutionRequest, FnResponse<AiPromptResponse<any>>>(
      "executeAiPromptHandler_v0_2_0",
    );
    const response = await firstValueFrom(fn({promptId, parameters}));
    if (!response.success) {
      throw new Error(response.message);
    }
    return response.data;
  }
}

export type PromptsListMode = "public" | "my";
