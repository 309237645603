import {Injectable} from "@angular/core";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {firstValueFrom} from "rxjs";
import Stripe from "stripe";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AuthService} from "../components/auth/auth.service";
import {AlertService} from "./alert.service";
import {FunctionResponse} from "../../../.common/function-response";
import {
  promptSuccessPayments,
  users,
  users_checkoutSessions,
} from "../../../.common/firestore-collections";
import {getPaymentDetailId, PaymentDetails} from "../../../.common";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  constructor(
    private fns: AngularFireFunctions,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private alertService: AlertService,
  ) {}

  public async checkoutPrompt(
    aiPromptId: string,
  ): Promise<FunctionResponse<Stripe.Checkout.Session>> {
    const fn = this.fns.httpsCallable<any, FunctionResponse<Stripe.Checkout.Session>>(
      "createCheckoutPromptRedirect",
    );
    return await firstValueFrom(
      fn({
        aiPromptId,
        backUrl: window.location.href,
      }),
    );
  }

  public async checkoutSubscription(priceId: string) {
    const docRef = await this.firestore
      .collection(users)
      .doc(this.authService.requireUserId())
      .collection(users_checkoutSessions)
      .add({
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
      });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const {error, url} = snap.data() as any;
      if (error) {
        this.alertService.error(error.message);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  public getPaymentDetails(userId: string, promptId: string) {
    const paymentDetails = getPaymentDetailId(userId, promptId);
    const paymentDetailsAngularFirestoreDocument = this.firestore
      .collection(promptSuccessPayments)
      .doc<PaymentDetails>(paymentDetails);
    return paymentDetailsAngularFirestoreDocument.get();
  }
}
