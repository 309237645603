@if (vm() && vm().loaded) {
  <div class="user-profile">
    @if (vm().user; as user) {
      <div class="userProfileIcon">
        <div>
          <a [matMenuTriggerFor]="userMenu">
            @if (vm().publicUserInfo) {
              <ui-profile-icon [publicUserData]="vm().publicUserInfo"></ui-profile-icon>
            } @else {
              <ui-profile-icon [publicUserData]="{displayName: user.email}"></ui-profile-icon>
            }
          </a>
        </div>
        <mat-menu #userMenu="matMenu">
          <a mat-menu-item routerLink="/p/{{ user.id }}">
            @if (vm().publicUserInfo; as p) {
              {{ p.displayName }}
            } @else {
              {{ user.email }}
            }
          </a>
          <!--        <a routerLink="/finances" mat-menu-item>Finances</a>-->
          <a routerLink="/profile/usage" mat-menu-item>Usage</a>
          <a routerLink="/subscription/select" mat-menu-item>Subscriptions</a>
          <a href="https://forms.gle/frKqLYq7wJwCgyxc6" target="_blank" mat-menu-item>Help desk</a>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="auth.signOut()">Logout</button>
        </mat-menu>
      </div>
    } @else {
      <ui-main-menu-item>
        <a (click)="openAuthWindow()">
          <div>
            <mat-icon class="user-icon">person</mat-icon>
            <span class="username">Sing in/up</span>
            <div class="active-line"></div>
          </div>
        </a>
      </ui-main-menu-item>
    }
  </div>
} @else {
  <mat-spinner diameter="30"></mat-spinner>
}
