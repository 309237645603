import {inject, Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {getEncoding} from "js-tiktoken";
import {firestore as firestoreAdmin} from "firebase-admin";
import * as firestore from "@firebase/firestore";
import {AlertService} from "../../../../services/alert.service";
import {Router} from "@angular/router";
import {
  docRefToObservable,
  queryToObservableArray,
} from "../../../../../../.common/firestore/observable";
import {ApiType, isTextAiContentTypeWithOutput} from "../../../../../../.common";
import {AiModel} from "../../../../../../.common/ai-models";
import {ChatCollectionProvider} from "../../../../../../.common/collections/chat-collection-provider";
import {
  ChatContentText,
  ChatMessage,
  ChatMessageHuman,
  isChatTextContent,
} from "../../../../../../.common/chat/chat-message";
import {Chat, ChatVisibility} from "../../../../../../.common/chat/chat";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialgueComponent} from "../../../ui/confirmation-dialgue/confirmation-dialgue.component";
import {Store} from "@ngrx/store";
import {chatFeature} from "../state/chat.state";
import {ChatActions} from "../state/chat.actions";
import {isFirestoreTimestamp} from "../../../../../../.common/firestore/types.converter";
import {ModelParameterKey} from "../../../../../../.common/ai-models/model-parameters";
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: "root",
})
export class ChatService {
  firestore = inject(AngularFirestore).firestore;
  chatCollectionProvider = new ChatCollectionProvider(this.firestore);
  alertService = inject(AlertService);
  chatCollection = this.chatCollectionProvider.getChatCollection();
  #tiktoken = getEncoding("gpt2");
  #router = inject(Router);
  #confirmationWindow = inject(MatDialog);
  #store = inject(Store);
  #chatList = this.#store.selectSignal(chatFeature.selectChatList);
  async sendMessage(chatId: string, message: ChatMessage) {
    message.createdAt = FieldValue.serverTimestamp();
    await this.chatCollectionProvider.getChatMessagesCollection(chatId).add(message);
  }

  getMessages(chatId: string) {
    const query = this.chatCollectionProvider
      .getChatMessagesCollection(chatId)
      .orderBy("createdAt", "asc");
    return queryToObservableArray<ChatMessage>(query);
  }

  async createNewChat(userId: string) {
    const chatRef = await this.chatCollection.add({
      id: this.chatCollection.id,
      ownerId: userId,
      createdAt: FieldValue.serverTimestamp(),
    } as Chat);

    const chat = (await chatRef.get()).data();
    await this.#router.navigate([`/chat/${chat!.id}`]);
  }

  getChat(chatId: string) {
    return docRefToObservable(this.chatCollection.doc(chatId));
  }

  async getLastChat(userId: string) {
    const chats = await this.chatCollection
      .where("ownerId", "==", userId)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
    if (chats.docs.length === 0) {
      return undefined;
    }
    return chats.docs[0].data();
  }

  fitMessagesToContext(
    messages: ChatMessage[],
    currentMessage: ChatMessageHuman,
    apiType: ApiType,
    modelParameters: Record<ModelParameterKey, any>,
    chatReplyMessage: ChatMessage | undefined,
    aiModel: AiModel,
  ) {
    let maxContextTokens: number;
    if (modelParameters.maxContextLength) {
      maxContextTokens = modelParameters.maxContextLength;
    } else {
      switch (apiType) {
        case "google-palm":
          maxContextTokens = modelParameters.maxOutputTokens;
          break;
        default:
          maxContextTokens = modelParameters.max_tokens / 2;
      }
    }
    if (maxContextTokens === undefined) {
      console.error("Can't determine maximum tokens");
      return {contextMessages: [], totalContextTokens: 0};
    }

    let totalContextTokens = this.calculateContentSize(currentMessage, apiType, aiModel);
    let contextMessages = [];

    messages = messages.slice().reverse();
    if (chatReplyMessage) {
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        if (message.id === chatReplyMessage.id) {
          messages = messages.slice(i);
          break;
        }
      }
    }

    for (const message of messages) {
      if (
        message.sender.type !== "human" &&
        !isTextAiContentTypeWithOutput(message.metadata?.contentType)
      ) {
        continue;
      }
      const messageTokens = this.calculateContentSize(message, apiType, aiModel);
      if (maxContextTokens < totalContextTokens + messageTokens) {
        break;
      }
      totalContextTokens += messageTokens;
      contextMessages.push(message);
    }
    contextMessages = contextMessages.reverse();
    return {contextMessages, totalContextTokens};
  }

  calculateContentSize(chatMessage: ChatMessage, apiType: ApiType, aiModel: AiModel) {
    let size = 0;
    if (chatMessage.text) {
      size += this.calculateTokensSize(chatMessage.text, apiType, aiModel);
    }
    if (chatMessage.content) {
      size += chatMessage.content
        .filter(isChatTextContent)
        .map((content) => content.text.trim())
        .map((text) => this.calculateTokensSize(text, apiType, aiModel))
        .reduce((acc, value) => acc + value, 0);
    }

    return size;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  calculateTokensSize(text: string, aiApi: ApiType, aiModel: AiModel) {
    if (!text) {
      return 0;
    }
    if (aiApi === "google-palm") {
      return text.length;
    }
    return this.#tiktoken.encode(text).length;
  }

  getDefaultModelParameters(aiModel: AiModel) {
    return aiModel.parameters
      .filter((value) => !value.mapTo || value.mapTo === "parameters")
      .reduce(
        (acc, parameter) => {
          parameter.skipForAi
            ? (acc.modelSkippedParameters[parameter.key] = parameter.defaultValue)
            : (acc.modelParameters[parameter.key] = parameter.defaultValue);
          return acc;
        },
        {
          modelParameters: {} as Record<string, any>,
          modelSkippedParameters: {} as Record<string, any>,
        },
      );
  }

  async addChatModelParticipant(chatId: string, apiId: ApiType) {
    await this.chatCollectionProvider
      .getChatCollection()
      .doc(chatId)
      .update({
        modelParticipants: FieldValue.arrayUnion(apiId),
      });
  }

  async removeChatModelParticipant(chatId: string, apiId: ApiType) {
    await this.chatCollectionProvider
      .getChatCollection()
      .doc(chatId)
      .update({
        modelParticipants: FieldValue.arrayRemove(apiId),
      });
  }

  async addChatHumanParticipant(chatId: string, userId: string) {
    return this.chatCollectionProvider
      .getChatCollection()
      .doc(chatId)
      .update({
        humanParticipants: FieldValue.arrayUnion(userId),
      });
  }

  async removeChatHumanParticipant(chatId: string, userId: string) {
    return this.chatCollectionProvider
      .getChatCollection()
      .doc(chatId)
      .update({
        humanParticipants: FieldValue.arrayRemove(userId),
      });
  }

  toDate(input?: firestoreAdmin.FieldValue | firestore.Timestamp | Date): Date {
    return isFirestoreTimestamp(input) ? input.toDate() : (input as Date);
  }

  getChatsForUser(userId: string) {
    return queryToObservableArray(
      this.chatCollection.where("ownerId", "==", userId).orderBy("createdAt", "desc"),
    );
  }

  setChatVisibility(chatId: string, visibility: ChatVisibility) {
    return this.chatCollectionProvider.getChatCollection().doc(chatId).update({
      visibility,
    });
  }

  async deleteChats(chatIds: string[]) {
    if (chatIds.length == 0) {
      throw new Error("No chats selected for deletion");
    }
    const firstChat = this.#chatList().find((chat) => chat.id === chatIds[0]);
    if (!firstChat) {
      throw new Error("Couldn't find chats while deleting. Really shouldn't happen");
    }

    const firstChatTitle = firstChat.displayName ? firstChat.displayName : "Unnamed chat";
    const confirmationMessage: string[] = [];
    confirmationMessage.push(firstChatTitle);
    if (chatIds.length == 2) {
      confirmationMessage.push(`and 1 other`);
    }
    if (chatIds.length > 2) {
      confirmationMessage.push(`and ${chatIds.length - 1} others`);
    }
    confirmationMessage.push("will be deleted. Are you sure?");

    this.confirmDeletion(
      confirmationMessage.join(" "),
      async () => {
        try {
          await Promise.allSettled(
            chatIds.map(async (chatId) => {
              return this.chatCollection.doc(chatId).delete();
            }),
          );

          // hack to fix bug after deleting chat
          window.location.href = "/chat";
        } catch (e) {
          console.error(e);
          this.alertService.error("Error deleting chats");
        } finally {
          this.#store.dispatch(ChatActions.navbarChatsSelectionClear());
        }
      },
      async () => {
        return;
      },
    );
  }

  async deleteChatMessages(chatMessages: ChatMessage[], chat: Chat) {
    const chatMessageProvider = this.chatCollectionProvider.getChatMessagesCollection(chat.id);

    try {
      await Promise.allSettled(
        chatMessages.map((chatMessage) => {
          chatMessageProvider.doc(chatMessage.id).delete();
        }),
      );
    } catch (e) {
      console.error(e);
      this.alertService.error("Error deleting chats");
    }
  }
  async changeChatDisplayName(chatId: string, displayName: string) {
    await this.chatCollection.doc(chatId).update({
      displayName: displayName,
    });
  }

  getRecommendedChats() {
    return queryToObservableArray(
      this.chatCollection
        .where("visibility", "==", "public")
        // .orderBy("impressions", "desc")
        .orderBy("createdAt", "desc")
        .limit(6),
    );
  }
  getPublicChats() {
    return queryToObservableArray(
      this.chatCollection
        .where("visibility", "==", "public")
        // .orderBy("impressions", "desc")
        .orderBy("createdAt", "desc"),
    );
  }

  getSharedChats(userId: string) {
    return queryToObservableArray(
      this.chatCollection
        .where("humanParticipants", "array-contains", userId)
        .orderBy("createdAt", "desc"),
    );
  }

  getLastMessageToDisplay(chat: Chat): string | undefined {
    if (!chat.lastMessage) {
      return "No messages";
    }
    if (chat.lastMessage.metadata?.contentType == "image") {
      return "Photo";
    }
    if (chat.lastMessage.content) {
      return (chat.lastMessage.content[0] as ChatContentText).text;
    }
    return "";
  }

  async confirmDeletion(
    message: string,
    onConfirm: () => Promise<void>,
    onCancel?: () => Promise<void>,
  ) {
    return this.#confirmationWindow.open(ConfirmationDialgueComponent, {
      data: {
        message,
        onConfirm,
        onCancel,
      },
    });
  }
}
