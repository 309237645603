import {Component, inject, Input} from "@angular/core";
import firebase from "firebase/compat";
import {AuthService} from "../../../auth/auth.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {tap} from "rxjs";
import {AiPrompt} from "../../../../../../.common";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: "ui-prompt-market-grid",
  templateUrl: "./prompt-market-grid.component.html",
  styleUrls: ["./prompt-market-grid.component.scss"],
})
export class PromptMarketGridComponent {
  @Input({required: true}) prompt!: AiPrompt;
  authService = inject(AuthService);
  userId?: string;

  constructor() {
    this.authService.user$
      .pipe(
        takeUntilDestroyed(),
        tap((user) => (this.userId = user?.id)),
      )
      .subscribe();
  }

  get promptCreated() {
    return (this.prompt.created as Timestamp).toDate();
  }

  get promptType() {
    if (this.prompt.api == "dall-e") {
      return "image";
    }
    return "text";
  }

  get hasImage() {
    return this.prompt.image != undefined && this.prompt.image != "";
  }

  get hasDescription() {
    return this.prompt.description != undefined && this.prompt.description != "";
  }

  openMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
}
