import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;

export interface AiPrompt {
  id: string;
  ownerId: string;
  ownerName: string;
  api: ApiType;
  model: string;
  modelConfig: any;
  displayName: string;
  description?: string;
  parameters: AiPromptBodyParameter[];
  created: Timestamp | typeof firebase.database.ServerValue.TIMESTAMP;
  publishSettings?: PublishSettings;
  tags?: string[];
  image?: string;
}

export interface PublishSettings {
  visibility: AiVisibility;
  bodyVisibility?: string;
  isPayed?: boolean;
  subscriptionAvailable?: boolean;
  fixedPriceAvailable?: boolean;
  fixedPrice?: number;
}

export interface AiPromptBodyParameter {
  selector: string;
  type: AiPromptBodyParameterType;
  defaultValue?: string;
}

export type ApiType =
  | "gpt3"
  | "cohere"
  | "dall-e"
  | "openai-chat"
  | "google-palm"
  | "anthropic"
  | "google-vertex-ai"
  | "stability-ai"
  | "mistral";
export type AiPromptBodyParameterType = "string" | "number" | "bool" | "oneOf";
export type AiVisibility = "public" | "private";
export type AiContentType =
  | "text"
  | "chat" //deprecated
  | "image"
  | "per-model"
  | "multimodal"
  | MultimodelAiContentType;

export type MultimodelAiContentType = {
  input: AiContentType[];
  output: AiContentType[];
};

export const isMultimodelAiContentType = (obj: any): obj is MultimodelAiContentType => {
  return obj && obj.input && obj.output;
};

export const isTextAiContentTypeWithInput = (contentType: AiContentType): boolean => {
  return (
    contentType === "chat" ||
    contentType === "text" ||
    (isMultimodelAiContentType(contentType) &&
      (contentType.input.includes("chat") || contentType.input.includes("text")))
  );
};

export const isTextAiContentTypeWithOutput = (contentType: AiContentType): boolean => {
  return (
    contentType === "chat" ||
    contentType === "text" ||
    (isMultimodelAiContentType(contentType) &&
      (contentType.output.includes("chat") || contentType.output.includes("text")))
  );
};

export const isAiPrompt = (obj: any): obj is AiPrompt => {
  return (
    obj &&
    obj.id &&
    obj.ownerId &&
    obj.api &&
    obj.model &&
    obj.displayName &&
    obj.parameters &&
    obj.created
  );
};

export type AiPromptExecutionRequest = {
  promptId: string;
  parameters: Record<string, string>;
};
