<div *ngIf="aiApiService.prompt$ | async as prompt">
  <app-not-found
    *ngIf="prompt === 'not-found'"
    message="The AI prompt '{{ promptId }}' you are looking for does not exist."
  >
  </app-not-found>
  <mat-spinner *ngIf="prompt === 'loading'"></mat-spinner>

  <div *ngIf="prompt !== 'loading' && prompt !== 'not-found'">
    <h1>{{ prompt.displayName }}</h1>
    <button mat-button *ngIf="isOwner" routerLink="edit">Edit</button>
    <p class="bodySmall">{{ prompt.description }}</p>
    <div *ngIf="promptParameters() as params">
      <ng-container *ngIf="params.length > 0">
        <span>Variables in the template</span>
        <form [formGroup]="paramsForm" (keyup)="saveForm()">
          <ul *ngFor="let param of params">
            <li>
              <mat-form-field>
                <mat-label>{{ param.selector }}</mat-label>
                <input
                  formControlName="{{ param.selector }}"
                  matInput
                  type="text"
                  class="testModelInputFields"
                  name="{{ param.selector }}"
                />
              </mat-form-field>
            </li>
          </ul>
        </form>
      </ng-container>
    </div>

    <ng-template #nonAuthPaymentDetails>
      <div *ngIf="!prompt.publishSettings?.isPayed">This is free prompt.</div>
      <div *ngIf="prompt.publishSettings?.isPayed">
        <div *ngIf="prompt.publishSettings?.fixedPrice">
          Fixed price: ${{ prompt.publishSettings?.fixedPrice }}
        </div>
      </div>
      <div *ngIf="prompt.publishSettings?.subscriptionAvailable">
        This prompt is available with subscription.
      </div>
      <div>Login to use this prompt.</div>
    </ng-template>
    <div *ngIf="authService.user$ | async; else nonAuthPaymentDetails">
      <div *ngIf="paymentDetails">
        <span>You bought this prompt for ${{ paymentDetails.price }}</span>
      </div>
      <button
        mat-raised-button
        (click)="execute()"
        [disabled]="!possibleToExecute || executeInProgress || !paramsForm.valid"
      >
        Execute prompt
        <mat-progress-bar *ngIf="executeInProgress" mode="indeterminate"></mat-progress-bar>
      </button>
      <p *ngIf="!paramsForm.valid">You should fill the variables before you can execute it.</p>

      <div *ngIf="prompt.publishSettings?.fixedPriceAvailable && !this.promptPayed">
        <p><b>For now, payments work in test mode.</b></p>
        <p>You can use card number: <i>4242 4242 4242 4242</i> with any other data.</p>
        <button
          *ngIf="prompt.publishSettings?.fixedPriceAvailable && !this.possibleToExecute"
          mat-raised-button
          (click)="checkout()"
          [disabled]="checkoutInProgress"
        >
          Checkout prompt for ${{ prompt.publishSettings?.fixedPrice }}
          <mat-progress-bar *ngIf="executeInProgress" mode="indeterminate"></mat-progress-bar>
        </button>
        <div *ngIf="prompt.publishSettings?.subscriptionAvailable && !this.possibleToExecute">
          <p>The prompt is accessible with subscription.</p>
          <button mat-raised-button [routerLink]="['../../subscription/select']">
            Unlock with subscription
            <mat-icon>help</mat-icon>
            <mat-progress-bar *ngIf="executeInProgress" mode="indeterminate"></mat-progress-bar>
          </button>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        (authService.user$ | async) && aiApiService.selectedAiApi$ | async as selectedAiApi;
        else loading
      "
    >
      <app-api-response-results
        *ngIf="isStateResolved(selectedAiApi)"
        responseType="user"
        [promptId]="prompt.id"
        [api]="$any(selectedAiApi)"
      >
      </app-api-response-results>
    </ng-container>

    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>
  </div>
</div>
