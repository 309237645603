import {Component, inject} from "@angular/core";
import {MarkdownModule} from "ngx-markdown";
import {Store} from "@ngrx/store";
import {Title} from "@angular/platform-browser";
import {legalFeature} from "../../../state/legal.state";

@Component({
  selector: "cookies-policy",
  standalone: true,
  imports: [MarkdownModule],
  templateUrl: "./cookies-policy.component.html",
  styleUrl: "./cookies-policy.component.scss",
})
export class CookiesPolicyComponent {
  version = inject(Store).selectSignal(legalFeature.selectTermsVersion);
  constructor(private titleService: Title) {
    this.titleService.setTitle("Cookies Policy - Symbiot AI");
  }
}
