import {createActionGroup, emptyProps} from "@ngrx/store";
import {ChatFilePreview} from "./chat.state";
import {Chat, ChatVisibility} from "../../../../../../.common/chat/chat";
import {
  ChatContent,
  ChatMessage,
  ChatMessageHuman,
} from "../../../../../../.common/chat/chat-message";
import {ApiType} from "../../../../../../.common";
import {AiModel} from "../../../../../../.common/ai-models";
import {ChatListMode} from "./chat-list-mode";

export const ChatActions = createActionGroup({
  source: "Chat",
  events: {
    loadChat: (chatId: string) => ({chatId}),
    loadChatList: emptyProps(),
    setChat: (chat: Chat) => ({chat}),
    setChatList: (chatList: Chat[]) => ({chatList}),
    chatNotFound: emptyProps(),
    destroyChat: emptyProps(),
    setChatVisibility: (chatId: string, visibility: ChatVisibility) => ({chatId, visibility}),
    changeChatDisplayName: (chatId: string, displayName: string) => ({chatId, displayName}),
    setChatlistSelectActive: (isActive: boolean) => ({isActive}),
    navbarChatsSelectionToggle: (chatId: string) => ({chatId}),
    navbarChatsSelectionClear: emptyProps(),
    navbarChatsDeleteSelected: emptyProps(),
    deleteChats: (chatIds: string[]) => ({chatIds}),
    setChatInputText: (text: string) => ({text}),
    changeChatListMode: (mode: ChatListMode) => ({mode}),
  },
});
export const ChatMessagesActions = createActionGroup({
  source: "Chat/Messages",
  events: {
    setChatMessages: (chatMessages: ChatMessage[]) => ({chatMessages}),
    appendTypingMessage: (messageId: string, text: string) => ({messageId, text}),
    removeTypingMessage: (messageId: string) => ({messageId}),
    sendMessage: emptyProps(),
    messageSent: emptyProps(),
    clearChatMessages: emptyProps(),
    setFilePreviews: (filePreview: ChatFilePreview) => ({filePreview}),
    deleteFilePreview: (filePreview: ChatFilePreview) => ({filePreview}),
  },
});
export const ChatContextMessagesActions = createActionGroup({
  source: "Chat/ContextMessages",
  events: {
    updateUseContext: (useContextMode: "auto" | "manual" | "off") => ({useContextMode}),
    updateContextMessages: (contextMessages: ChatMessage[]) => ({contextMessages}),
    setContextSize: (contextSize: number) => ({contextSize}),
    setChatReplyMessage: (message: ChatMessage | undefined) => ({message}),
  },
});
export const ChatCurrentMessageActions = createActionGroup({
  source: "Chat/CurrentMessage",
  events: {
    updateCurrentMessage: (currentMessage: ChatMessageHuman) => ({currentMessage}),
    clearCurrentMessage: emptyProps(),
    updateModelParameters: (modelParameters: Record<string, any>) => ({modelParameters}),
    updateModelSkippedParameters: (modelParameters: Record<string, any>) => ({modelParameters}),
    updateMessageModelParameter: (key: string, value: any) => ({key, value}),
    updateMessageModelSkippedParameter: (key: string, value: any) => ({key, value}),
    updateCurrentMessageText: (text: string) => ({text}),
    setCurrentMessageContent: (content: ChatContent) => ({content}),
  },
});
export const ChatAiApiActions = createActionGroup({
  source: "Chat/AiApi",
  events: {
    setSelectedAiApi: (apiId: ApiType) => ({apiId}),
    setSelectedAiModel: (model: AiModel) => ({model}),
    setUndefinedAiApi: emptyProps(),
    setUndefinedAiModel: emptyProps(),
  },
});

export const ChatPageActions = createActionGroup({
  source: "Chat/Page",
  events: {
    loading: (loading: boolean) => ({loading}),
    setChatAutoScroll: (autoScroll: boolean) => ({autoScroll}),
    setChatEmpty: emptyProps(),
    setPermissionDenied: (permissionDenied: boolean) => ({permissionDenied}),
    scrollToBottom: emptyProps(),
  },
});
