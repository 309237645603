import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AiPromptService} from "../../../../services/ai-prompt.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatStepper} from "@angular/material/stepper";
import {ToastrService} from "ngx-toastr";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {BehaviorSubject, takeUntil} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";

import {AiPromptHistoryService} from "../../../../services/ai-prompt-history.service";
import {Destroyer} from "../../../_helpers/Destroyer";
import {Title} from "@angular/platform-browser";
import {AiApi, Cohere, DallE, Gpt3} from "../../../../../../.common/ai-models";
import {AiPromptResponse} from "../../../../../../.common/ai-prompt-response";
import {AiPrompt, AiPromptBodyParameter, AiVisibility, ApiType} from "../../../../../../.common";

@Component({
  selector: "app-prompts-create-prompt-page",
  templateUrl: "./create-prompt-page.component.html",
  styleUrls: ["./create-prompt-page.component.scss"],
})
export class CreatePromptPageComponent extends Destroyer implements OnInit {
  availableAiApis: AiApi[] = [new Gpt3(), new Cohere(), new DallE()];

  // Step 1
  selectedApi?: AiApi;

  apiKey: string | null | undefined;
  readonly apiResponses$ = new BehaviorSubject<
    AiPromptResponse<any> | AiPromptResponse<any>[] | null
  >(null);
  chooseModelForm = this.formBuilder.group({
    api: new FormControl<ApiType | "">(
      {
        value: "",
        disabled: this.isEditPage(),
      },
      [Validators.required],
    ),
    model: new FormControl("", [Validators.required]),
    displayName: "",
    description: "",
  });
  // Step 2
  modelConfigurationForm = this.formBuilder.group({
    prompt: new FormControl("", [Validators.required]),
  });
  templateVariables: AiPromptBodyParameter[] = [];
  templateValidated = false;
  templateContentLoading = false;

  aiPrompt: Partial<AiPrompt> | undefined;
  stepperIndex = 0;

  isLoading = true;
  notFound = false;
  disableCreateButton = false;
  disableUpdateModelButton = false;

  testModelTextToSend = "";
  modelCompletionsLoading = false;

  // Step 4
  publishSettingsForm = this.formBuilder.group({
    visibility: new FormControl("private", [Validators.required]),
    isPayed: new FormControl(false, [Validators.required]),
    subscriptionAvailable: new FormControl(true, [Validators.required]),
    fixedPriceAvailable: new FormControl(false, [Validators.required]),
    fixedPrice: new FormControl(1, [Validators.required, Validators.min(1)]),
    bodyVisibility: new FormControl("hidden", [Validators.required]),
  });
  visibility: AiVisibility = "private";
  disablePublishButton = false;
  tags: Set<string> = new Set();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    private aiPromptService: AiPromptService,
    private aiPromptHistoryService: AiPromptHistoryService,
    private toastr: ToastrService,
    private titleService: Title,
  ) {
    super();
    this.titleService.setTitle("Create Prompt - Symbiot AI");
  }

  async ngOnInit() {
    const promptId = this.getPromptId();
    if (!promptId) {
      this.isLoading = false;
    } else {
      this.templateContentLoading = true;
      const template =
        (await this.aiPromptService.getTemplate(promptId).catch(() => {
          /**/
        })) || "";
      this.modelConfigurationForm.get("prompt")!.setValue(template);
      this.templateValidated = true;
      this.templateContentLoading = false;

      this.subscribeOnPrompt(promptId, template);
      this.apiResponses$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
        if (response != null && !Array.isArray(response)) {
          this.aiPromptHistoryService.createTest(response);
        }
      });
    }
    this.publishSettingsForm.controls.fixedPrice.disable(); //TODO: temporary disabled, until fix price is not available
  }

  private subscribeOnPrompt(promptId: string, template: string) {
    const next = (aiPrompt: AiPrompt) => {
      try {
        this.stepperIndex = parseInt(
          localStorage.getItem(this.getStepperIndexKey(promptId)) || "1",
        );
        this.aiPrompt = aiPrompt;
        this.selectedApi =
          this.availableAiApis.find((api) => api.id === aiPrompt.api) || this.availableAiApis[0];
        this.apiKey = localStorage.getItem(this.getModelKey());
        this.chooseModelForm.patchValue({
          api: aiPrompt.api,
          model: aiPrompt.model,
          displayName: aiPrompt.displayName,
          description: aiPrompt.description,
        });

        if (aiPrompt.parameters && aiPrompt.parameters.length > 0) {
          this.templateVariables = aiPrompt.parameters;
          this.testModelTextToSend = localStorage.getItem(this.getTestModelTextToSendKey()) || "";
        } else {
          this.testModelTextToSend = template;
        }
        this.visibility = aiPrompt.publishSettings?.visibility || "private";
        this.publicSettingInit(aiPrompt);
      } finally {
        this.isLoading = false;
      }
    };
    const error = (e: any) => {
      console.error(e);
      this.notFound = true;
    };
    this.aiPromptService
      .getObservable(promptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({next, error});
  }

  async testPrompt() {
    throw new Error("Not implemented");
    // TODO: we should not call it from the frontend
    // if (!this.keyExists()) {
    //   const dialogRef = this.dialog.open(PromptsLocalAddKeyDialogComponent, {
    //     data: {api: this.chooseModelForm.value.api, apiKey: this.apiKey},
    //   });
    //
    //   dialogRef
    //     .afterClosed()
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe((result) => {
    //       if (result) {
    //         localStorage.setItem(this.getModelKey(), result);
    //         this.apiKey = result;
    //       }
    //     });
    //   return;
    // }
    //
    // this.modelCompletionsLoading = true;
    // // eslint-disable-next-line @typescript-eslint/no-var-requires
    // const {Configuration, OpenAIApi} = require("openai");
    // const configuration = new Configuration({
    //   apiKey: this.apiKey,
    // });
    // const openai = new OpenAIApi(configuration);
    // try {
    //   switch (this.selectedApi?.id) {
    //     case "cohere":
    //       // eslint-disable-next-line no-case-declarations
    //       const response: any = await firstValueFrom(
    //         this.http.post(
    //           "https://api.cohere.ai/generate",
    //           {
    //             prompt: this.testModelTextToSend,
    //             model: "command-xlarge-20221108",
    //             max_tokens: 300,
    //             temperature: 0.9,
    //             k: 0,
    //             p: 0.75,
    //             frequency_penalty: 0,
    //             presence_penalty: 0,
    //             stop_sequences: [],
    //             return_likelihoods: "NONE",
    //           },
    //           {
    //             headers: {
    //               Authorization: `Bearer ${this.apiKey}`,
    //               "Cohere-Version": "2022-12-06",
    //               "Content-Type": "application/json",
    //             },
    //           },
    //         ),
    //       );
    //       response.generations.forEach((generation: any) => {
    //         this.apiResponses$.next({
    //           content: generation.text,
    //           promptId: this.getPromptId(),
    //           responseType: "test",
    //           contentType: "text",
    //         } as AiPromptResponseText);
    //       });
    //       break;
    //     case "gpt3":
    //       // eslint-disable-next-line no-case-declarations
    //       const completionRequest = {
    //         model: this.chooseModelForm.value.model,
    //         prompt: this.testModelTextToSend,
    //         temperature: 0.7,
    //         max_tokens: 256,
    //         top_p: 1,
    //         frequency_penalty: 0,
    //         presence_penalty: 0,
    //         best_of: 1,
    //       };
    //       (await openai.createCompletion(completionRequest)).data.choices.forEach((choice: any) => {
    //         this.apiResponses$.next({
    //           content: choice.text,
    //           promptId: this.getPromptId(),
    //           responseType: "test",
    //           contentType: "text",
    //         } as AiPromptResponseText);
    //       });
    //       break;
    //     case "dall-e":
    //       // eslint-disable-next-line no-case-declarations
    //       const responseDalle = await openai.createImage({
    //         prompt: this.testModelTextToSend,
    //         size: "256x256",
    //         n: 1,
    //       });
    //       responseDalle.data.data.forEach((data: any) => {
    //         const {url} = data;
    //         this.apiResponses$.next({
    //           content: {tmpUrl: url},
    //           promptId: this.getPromptId(),
    //           responseType: "test",
    //           contentType: "image",
    //         } as AiPromptResponseImage);
    //       });
    //       // promptReply = responseDalle.data.data[0].url;
    //       break;
    //     default:
    //       this.toastr.error("This model is not implemented yet.");
    //       return;
    //   }
    // } finally {
    //   this.modelCompletionsLoading = false;
    // }
  }

  public keyExists(): boolean {
    return !!this.apiKey;
  }

  private getModelKey() {
    if (!this.selectedApi) {
      throw new Error("API is not selected.");
    }
    return "key:" + this.selectedApi.id;
  }

  private getStepperIndexKey(promptId: any) {
    return `edit-model-current-step:${promptId}`;
  }

  async create() {
    try {
      this.disableCreateButton = true;
      const prompt = {
        ...this.chooseModelForm.value,
        publishSettings: {
          visibility: "private",
        },
        tags: Array.from(this.tags),
      } as AiPrompt;
      const id = await this.aiPromptService.create(prompt);
      await this.router.navigate([`/prompts/${id}/edit`]);
    } catch (e: any) {
      this.disableCreateButton = false;
      this.toastr.error(e.message);
      console.error(e);
    }
  }

  getPromptId() {
    return this.route.snapshot.params["promptId"];
  }

  remove(tag: string) {
    this.tags.delete(tag);
  }

  edit(tag: string, event: MatChipEditedEvent) {
    this.tags.delete(tag);
    this.tags.add(event.value);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      this.tags.add(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  async validateTemplate() {
    const tmpl = this.modelConfigurationForm.value.prompt!;
    const variables = await this.aiPromptService.getTemplateVariables(tmpl);
    this.templateVariables = variables.map((variable) => {
      return {
        selector: variable,
        type: "string",
      };
    });
    if (variables.length < 1) {
      this.testModelTextToSend = tmpl;
    } else {
      this.testModelTextToSend = "";
    }
    this.templateValidated = true;
  }

  isEditPage() {
    return !!this.getPromptId();
  }

  async updateSelectedModel(stepper: MatStepper) {
    this.disableUpdateModelButton = true;
    try {
      const updatedValues = this.getUpdatedValues(this.chooseModelForm);
      if (updatedValues) {
        await this.aiPromptService.update(this.getPromptId(), updatedValues);
      }
      this.chooseModelForm.markAsPristine();
      stepper.next();
    } finally {
      this.disableUpdateModelButton = false;
    }
  }

  getUpdatedValues(form: FormGroup) {
    const dirtyValues: any = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  async saveTemplate() {
    await this.aiPromptService.saveTemplate(
      this.getPromptId(),
      this.modelConfigurationForm.value.prompt!,
      this.templateVariables,
    );
    this.modelConfigurationForm.markAsPristine();
  }

  onStepChange($event: StepperSelectionEvent) {
    localStorage.setItem(
      this.getStepperIndexKey(this.getPromptId()),
      $event.selectedIndex.toString(),
    );
  }

  async parseTemplate() {
    const paramsValues: any = {};
    document
      .querySelectorAll(".testModelInputFields")
      .forEach((element: any) => (paramsValues[element.name] = element.value));
    this.testModelTextToSend = await this.aiPromptService.parseTemplate(
      this.modelConfigurationForm.value.prompt!,
      paramsValues,
    );
    localStorage.setItem(this.getTestModelTextToSendKey(), this.testModelTextToSend);
  }

  private getTestModelTextToSendKey() {
    return this.getPromptId() + "testModelTextToSend";
  }

  publishPrompt() {
    this.disablePublishButton = true;
    this.aiPromptService
      .updatePublishSettings(this.getPromptId(), this.publishSettingsForm.value)
      .then(() => {
        this.toastr.success("Prompt published");
        localStorage.removeItem(this.getPublishSettingsKey());
        this.router.navigate([`/prompts/${this.getPromptId()}`]);
      })
      .finally(() => (this.disablePublishButton = false));
  }

  removeApiKey() {
    localStorage.removeItem(this.getModelKey());
    this.apiKey = "";
  }

  isFree() {
    return !this.publishSettingsForm.controls.isPayed.value;
  }

  private getPublishSettingsKey() {
    return this.getPromptId() + "publishSettings";
  }

  private publicSettingInit(aiPrompt: AiPrompt) {
    const publicSettings = localStorage.getItem(this.getPublishSettingsKey());
    if (publicSettings) {
      try {
        publicSettings
          ? this.publishSettingsForm.patchValue(JSON.parse(publicSettings))
          : // @ts-ignore
            aiPrompt.publishSettings && this.publishSettingsForm.setValue(aiPrompt.publishSettings);
      } catch (e) {
        console.warn(
          "Probably, broken state publishSettingsForm in localStorage. Clear it.",
          publicSettings,
          e,
        );
        localStorage.removeItem(this.getPublishSettingsKey());
      }
    }
    this.publishSettingsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      localStorage.setItem(
        this.getPublishSettingsKey(),
        JSON.stringify(this.publishSettingsForm.value),
      );
    });
  }

  fixPriceIsNotAvailable() {
    return !this.publishSettingsForm.controls.fixedPriceAvailable.value;
  }

  onAiModelChange(modelId: any) {
    this.selectedApi = this.findModelById(modelId);
    this.chooseModelForm.patchValue({
      api: this.selectedApi.id,
    });
  }

  findModelById(id: string) {
    const model = this.availableAiApis.find((model) => model.id === id);
    if (model) {
      return model;
    }
    throw new Error(`Model with id ${id} not found`);
  }

  onSelectedModelChange(model: any) {
    this.chooseModelForm.controls.model.patchValue(model.id);
    this.chooseModelForm.controls.model.markAsDirty();
  }

  onPromptTextChange($event: any) {
    this.templateValidated = false;
    if (this.templateVariables.length > 0) {
      this.testModelTextToSend = $event;
    }
  }
}

@Component({
  selector: "app-prompts-create-key-dialog",
  templateUrl: "./create-key-dialog.html",
  styles: [
    `
      .dialog {
        padding: 20px;
      }
    `,
  ],
})
export class PromptsLocalAddKeyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PromptsLocalAddKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddKeyDialogData,
  ) {}
}

interface AddKeyDialogData {
  apiId: string;
  apiKey: string;
}
