<div class="text-center">
  <h1>{{ header }}</h1>
  <p>{{ message }}</p>

  <p>
    <b>
      <a href="/">back to home</a>
    </b>
  </p>
</div>
