import {Injectable} from "@angular/core";
import {distinctUntilChanged, Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

export type ScrollPosition = "top" | "bottom" | "middle";

@Injectable({
  providedIn: "root",
})
export class MainLayoutService {
  #doScroll$ = new Subject<"top" | "bottom">();
  doScroll$ = this.#doScroll$.asObservable().pipe(debounceTime(100));
  #scrollPositionChanged$ = new Subject<ScrollPosition>();
  scrollPositionChanged$ = this.#scrollPositionChanged$.asObservable().pipe(distinctUntilChanged());

  scrollToBottom() {
    this.#doScroll$.next("bottom");
  }

  scrollToTop() {
    this.#doScroll$.next("top");
  }

  updatePosition(position: "top" | "bottom" | "middle") {
    this.#scrollPositionChanged$.next(position);
  }
}
