export class RecordNotFoundError extends Error {
  constructor(recordName: string, recordId: string) {
    super(`${recordName} with id ${recordId} not found`);
  }
}
export class ValidationError extends Error {
  readonly errorType = "ValidationError";
  constructor(message: string) {
    super(message);
  }
}
