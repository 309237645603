<div [ngClass]="{'layout': !!displaySideNav, 'layout-without-side-nav' : !displaySideNav, 'chat-list-page': isChatListPage}"
     *ngIf="userLoaded(); else loadingTemplate">
  <header>
    <ui-header></ui-header>
    <router-outlet name="header"></router-outlet>
    <router-outlet class="top-menu-mobile" name="side-nav-header-mobile"></router-outlet>
  </header>

  <div class="side-nav" *ngIf="displaySideNav">
    <router-outlet class="top-menu-desktop" name="side-nav-header-desktop"></router-outlet>
    <ui-side-navbar></ui-side-navbar>
  </div>
  <div class="content-header">
    <ui-mobile-menu></ui-mobile-menu>
    <router-outlet name="content-header"></router-outlet>
  </div>
  <div class="content" #content>
    <router-outlet></router-outlet>
    <router-outlet class="content-mobile" name="content-mobile"></router-outlet>
    <router-outlet class="content-desktop" name="content-desktop"></router-outlet>
  </div>
  <div class="content-footer">
    <router-outlet name="content-footer"></router-outlet>
  </div>
  <footer>
    <router-outlet name="footer"></router-outlet>
    <!--    temporary disabled-->
    <!--    <ui-main-menu *ngIf="displayFooterMenu" class="bgPrimaryWhite100"></ui-main-menu>-->
  </footer>
</div>
<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
