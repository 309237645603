@if (customizeEnabled) {
  <accept-cookies-customize (back)="customizeEnabled = false"></accept-cookies-customize>
} @else {
  <div class="accept-cookies colorNeutral70" data-cy="accept-all-cookies-window">
    <p class="titleSmall">We Value Your Privacy</p>
    <div class="bodySmall colorNeutral60">
      <p>
        We use cookies and similar tracking technologies to enhance your browsing experience, provide personalized
        content
        and
        ads, analyze our website traffic, and understand where our visitors are coming from.
      </p>
      <p>By clicking "Accept All," you
        consent to our use of these technologies.</p>

      <ul>
        <li>Analytics: We use tools like Google Analytics to understand user behavior.</li>
        <li>Session Recording: Services like smartlook.com are used for recording user sessions to improve user
          experience.
        </li>
        <li>Marketing: Technologies like Meta Pixel help us with marketing efforts.</li>
      </ul>
      <p>
        Your privacy is important to us. You can choose which technologies you allow by clicking "Customize." You can
        change
        your preferences at any time.
      </p>
    </div>
    <div class="text-center">
      <button mat-flat-button color="primary" (click)="acceptAll()" data-cy="accept-all-cookies-button">Accept All</button>
      <button mat-button class="colorNeutral50" (click)="customizeEnabled = true">Customize</button>
    </div>
  </div>
}
