import {ApiType} from "../ai-prompt";

export interface ChatParticipant {
  id: string | ApiType;
  type: UserType;
}

export interface ChatParticipantAiApi extends ChatParticipant {
  id: ApiType;
  type: "model";
  modelId: string;
}

export interface ChatParticipantHuman extends ChatParticipant {
  id: string;
  type: "human";
}

export function isChatParticipantAiApi(
  chatParticipant: ChatParticipant,
): chatParticipant is ChatParticipantAiApi {
  return chatParticipant && chatParticipant.type === "model";
}

export function isChatParticipantHuman(
  chatParticipant: ChatParticipant,
): chatParticipant is ChatParticipantHuman {
  return chatParticipant && chatParticipant.type === "human";
}

export type UserType = "human" | "model";
