<div *ngIf="userLoaded(); else loadingTemplate">
  <header>
    <ui-header></ui-header>
  </header>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
