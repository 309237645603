import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UiModule} from "../ui/ui.module";
import {ListPromptsPageComponent} from "./prompts/list-prompts-page/list-prompts-page.component";
import {PromptsLocalAddKeyDialogComponent} from "./prompts/create-prompt-page/create-prompt-page.component";
import {RouterLink} from "@angular/router";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {SinglePromptPageComponent} from "./prompts/single-prompt-page/single-prompt-page.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {AuthDialogComponent} from "../ui/login/dialog/auth-dialog-component";
import {MatCardModule} from "@angular/material/card";
import {PromptModule} from "./prompts/prompt.module";
import {PromptCommonModule} from "./prompts/common/prompt-common.module";
import {LinkedAccountComponent} from "./finances/earnings/payouts/stripe/linked-account/linked-account.component";
import {SubscriptionSelectPageComponent} from "./subscription/subscription-select-page/subscription-select-page.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {EarningsComponent} from "./finances/earnings/earnings.component";
import {PayoutHistoryComponent} from "./finances/earnings/payouts/history/payout-history.component";
import {MatDividerModule} from "@angular/material/divider";
import {SharedModule} from "../../shared/shared.module";
import {MatBadgeModule} from "@angular/material/badge";
import {PricingComponent} from "./subscription/pricing-component/pricing.component";
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
  declarations: [
    ListPromptsPageComponent,
    PromptsLocalAddKeyDialogComponent,
    AuthDialogComponent,
    SinglePromptPageComponent,
    SubscriptionSelectPageComponent,
    LinkedAccountComponent,
    EarningsComponent,
    PayoutHistoryComponent,
    PricingComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    PromptModule,
    RouterLink,
    MatSlideToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatCardModule,
    PromptCommonModule,
    MatGridListModule,
    MatDividerModule,
    SharedModule,
    MatBadgeModule,
    MatMenuModule,
  ],
  exports: [ListPromptsPageComponent, SubscriptionSelectPageComponent, PricingComponent],
})
export class PagesModule {}
