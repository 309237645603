import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: "ui-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  userLoaded = false;

  registrationForm!: FormGroup;
  loginForm!: FormGroup;

  selectedTab = "tab-1";
  emailSent = false;

  constructor(
    public auth: AuthService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) {
    this.auth.user$.pipe(takeUntilDestroyed()).subscribe(() => (this.userLoaded = true));
  }

  private readonly _minLength = 6;

  ngOnInit() {
    this.registrationForm = this.formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(this._minLength)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(this._minLength)]],
      },
      {validator: this.checkPasswords},
    );
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      // termsAccepted: [false, [Validators.requiredTrue]],
      // password: ['', [Validators.required, Validators.minLength(this._minLength)]],
    });
  }

  checkPasswords(group: FormGroup) {
    const password = group.get("password")?.value;
    const confirmPassword = group.get("confirmPassword")?.value;
    return password === confirmPassword ? null : {notSame: true};
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  onSubmit() {
    if (this.registrationForm.valid) {
      console.info("Submitting registration form");
    }
  }

  async sendEmail() {
    await this.auth.emailAuth(this.loginForm.get("email")?.value);
    this.toastr.success("Email sent");
    this.emailSent = true;
  }
}
