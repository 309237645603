import {Component} from "@angular/core";
import {SecondLevelMenuBase} from "../side-navbar/side-navbar.component";

@Component({
  selector: "ui-mobile-menu",
  templateUrl: "./mobile-menu.component.html",
  styleUrls: ["./mobile-menu.component.scss"],
})
export class MobileMenuComponent extends SecondLevelMenuBase {
  constructor() {
    super();
  }
}
