import {Component} from "@angular/core";
import {PagesModule} from "../../pages.module";

@Component({
  selector: "pricing-page",
  templateUrl: "./pricing-page.component.html",
  styleUrls: ["./pricing-page.component.scss"],
  imports: [PagesModule],
  standalone: true,
})
export class PricingPageComponent {}
