import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MainMenuComponent} from "./main-menu/main-menu.component";
import {HeaderComponent} from "./header/header.component";
import {LogoComponent} from "./logo/logo.component";
import {LoginComponent} from "./login/login.component";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DiscordWidgetComponent} from "./discord-widget/discord-widget.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatToolbarModule} from "@angular/material/toolbar";
import {PromptMarketGridComponent} from "./prompt/prompt-market-grid/prompt-market-grid.component";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AiModelComponent} from "./ai-model/ai-model.component";
import {PromptFooterComponent} from "./prompt/prompt-components/prompt-footer/prompt-footer.component";
import {MatListModule} from "@angular/material/list";
import {MainMenuItemComponent} from "./main-menu/main-menu-item/main-menu-item.component";
import {ProfileIconComponent} from "./header/profile-icon/profile-icon.component";
import {ProfileHeaderMenuIconComponent} from "./header/profile-menu-icon/profile-header-menu-icon.component";
import {SliderComponent} from "./form-fields/slider/slider.component";
import {SideNavbarComponent} from "./side-navbar/side-navbar.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatTabsModule} from "@angular/material/tabs";
import {CreditsBlockComponent} from "./credits-block/credits-block.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {SharedModule} from "../../shared/shared.module";
import {MatSelectModule} from "@angular/material/select";
import {MatSliderModule} from "@angular/material/slider";
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from "@angular/cdk/scrolling";
import {MobileMenuComponent} from "./mobile-menu/mobile-menu.component";
import {TabComponent} from "./tab/tab.component";
import {ChatNavbarComponent} from "./chat-navbar/chat-navbar.component";

@NgModule({
  declarations: [
    MainMenuComponent,
    HeaderComponent,
    LogoComponent,
    LoginComponent,
    DiscordWidgetComponent,
    NotFoundComponent,
    PromptMarketGridComponent,
    AiModelComponent,
    PromptFooterComponent,
    MainMenuItemComponent,
    SliderComponent,
    ProfileHeaderMenuIconComponent,
    SideNavbarComponent,
    MobileMenuComponent,
    CreditsBlockComponent,
    TabComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatCheckboxModule,
    MatDividerModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    NgOptimizedImage,
    MatListModule,
    RouterLinkActive,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    SharedModule,
    MatSelectModule,
    MatSliderModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    ChatNavbarComponent,
    RouterOutlet,
    ProfileIconComponent,
  ],
  exports: [
    MainMenuComponent,
    HeaderComponent,
    LogoComponent,
    LoginComponent,
    DiscordWidgetComponent,
    NotFoundComponent,
    PromptMarketGridComponent,
    SideNavbarComponent,
    MobileMenuComponent,
  ],
})
export class UiModule {}
