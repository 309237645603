import firebase from "firebase/compat/app";
import {apiUsage} from "../firestore-collections";
import {WithId} from "../firestore/dataconverters";
import {ApiUsageEntity} from "../usage";

export class ApiUsageCollectionProvider {
  private readonly firestore: firebase.firestore.Firestore;

  constructor(firestore: firebase.firestore.Firestore) {
    this.firestore = firestore;
  }
  getApiUsageCollection() {
    return this.firestore.collection(apiUsage).withConverter(new WithId<ApiUsageEntity>());
  }
}
