import {inject, Injectable} from "@angular/core";
import {LegalActions} from "./legal.actions";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {filter, of, switchMap, tap} from "rxjs";
import {legalFeature} from "./legal.state";
import {AppActions} from "../../../../../state/app.actions";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AcceptAllLegalDialogComponent} from "../components/confirmation/accept-all-legal-dialog/accept-all-legal-dialog.component";
import {catchError, withLatestFrom} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {selectRouteDataTermsOff} from "../../../../../routing/state/selectors";
import {UserService} from "../../../../../services/user.service";
import {AuthActions} from "../../../../auth/state/auth.actions";
import {AcceptCookiesDialogComponent} from "../components/confirmation/accept-cookies-dialog/accept-cookies-dialog.component";
import {TrackingService} from "../service/tracking.service";

@Injectable()
export class LegalEffects {
  #actions = inject(Actions);
  readonly #cookiePreferencesKey = "cookiePreferencesKey";
  #dialog = inject(MatDialog);
  #store = inject(Store);
  #acceptCookiesDialog?: MatDialogRef<AcceptCookiesDialogComponent>;
  #acceptAllLegalDialog?: MatDialogRef<AcceptAllLegalDialogComponent>;
  #userService = inject(UserService);
  #trackingService = inject(TrackingService);

  loadCookiesPreferences$ = createEffect(() =>
    this.#actions.pipe(
      ofType(AppActions.loadApp),
      switchMap(() => {
        const p = localStorage.getItem(this.#cookiePreferencesKey);
        const actions = [];
        if (p) {
          actions.push(LegalActions.setCookiesPreferences(JSON.parse(p)));
          actions.push(LegalActions.hideAcceptCookiesDialog());
        } else {
          actions.push(LegalActions.setCookiesPreferences(undefined));
          actions.push(LegalActions.showAcceptCookiesDialog());
        }
        return of(...actions);
      }),
    ),
  );

  loadLegalSettings$ = createEffect(() =>
    this.#actions.pipe(
      ofType(AuthActions.setUser),
      filter((user) => !!user),
      switchMap((user) =>
        this.#userService
          .getLegalSettings(user!.id)
          .pipe(switchMap((legalSettings) => of(LegalActions.setLegalSettings(legalSettings)))),
      ),
      catchError((error) => {
        console.error("Failed to load legal settings", error);
        return of(LegalActions.setLegalSettings(undefined));
      }),
    ),
  );

  checkTermsAccepted$ = createEffect(() =>
    this.#actions.pipe(
      ofType(LegalActions.setLegalSettings),
      withLatestFrom(
        this.#store.select(selectRouteDataTermsOff),
        this.#store.select(legalFeature.selectShowAcceptTermsDialog),
        this.#store.select(legalFeature.selectPrivacyVersion),
        this.#store.select(legalFeature.selectTermsVersion),
        this.#store.select(legalFeature.selectAcceptableUsePolicyVersion),
      ),
      filter(([, termOff]) => !termOff),
      switchMap(
        ([
          {legalSettings},
          ,
          showAcceptTermsDialog,
          privacyVersion,
          termsVersion,
          acceptableUsePolicyVersion,
        ]) => {
          if (
            !showAcceptTermsDialog &&
            (legalSettings?.acceptedTermsVersion !== termsVersion ||
              legalSettings?.acceptedPrivacyVersion !== privacyVersion ||
              legalSettings?.acceptedAcceptableUsePolicyVersion !== acceptableUsePolicyVersion ||
              !legalSettings?.ageConfirmed)
          ) {
            return of(LegalActions.showAcceptTermsDialog());
          }
          return of(LegalActions.hideAcceptTermsDialog());
        },
      ),
    ),
  );

  setCookiesPreferences$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(LegalActions.setCookiesPreferences),
        tap(({cookiePreferences}) => {
          if (cookiePreferences) {
            localStorage.setItem(this.#cookiePreferencesKey, JSON.stringify(cookiePreferences));
          } else {
            localStorage.removeItem(this.#cookiePreferencesKey);
          }
        }),
      ),
    {dispatch: false},
  );

  saveCookiePreferences$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(LegalActions.saveCookiePreferences),
        tap((cookiePreferences) =>
          localStorage.setItem(this.#cookiePreferencesKey, JSON.stringify(cookiePreferences)),
        ),
      ),
    {dispatch: false},
  );

  openAcceptLegalAllDialog$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(LegalActions.showAcceptTermsDialog),
        tap(() => {
          this.#acceptAllLegalDialog = this.#dialog.open(AcceptAllLegalDialogComponent, {
            disableClose: true,
          });
        }),
      ),
    {dispatch: false},
  );

  closeAcceptLegalAllDialog$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(LegalActions.hideAcceptTermsDialog),
        filter(() => !!this.#acceptAllLegalDialog),
        tap(() => {
          this.#acceptAllLegalDialog!.close();
        }),
      ),
    {dispatch: false},
  );

  openAcceptCookiesDialog$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(LegalActions.showAcceptCookiesDialog),
        tap(() => {
          this.#acceptCookiesDialog = this.#dialog.open(AcceptCookiesDialogComponent, {
            disableClose: true,
            autoFocus: false,
            hasBackdrop: false,
            position: {
              bottom: "20px",
            },
          });
        }),
      ),
    {dispatch: false},
  );

  closeAcceptCookiesDialog$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(LegalActions.hideAcceptCookiesDialog),
        filter(() => !!this.#acceptCookiesDialog),
        tap(() => {
          this.#acceptCookiesDialog!.close();
        }),
      ),
    {dispatch: false},
  );
}
