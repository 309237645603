import {combineLatest, firstValueFrom, map, Observable} from "rxjs";
import {CreditsDatabase} from "./credits.database";
import {Credits, CreditsSubscriptionPlan, freePlanCredits} from "./credits";
import {StripeUserSubscription} from "../subscriptions/stripe-user-subscription";
import {UserSubscriptionsDatabase} from "../subscriptions/user-subscriptions.database";

export class CreditsService {
  private creditsDatabase: CreditsDatabase;
  private userSubscriptionsDatabase: UserSubscriptionsDatabase;

  constructor(creditsDatabase: CreditsDatabase, subscriptionsDatabase: UserSubscriptionsDatabase) {
    this.creditsDatabase = creditsDatabase;
    this.userSubscriptionsDatabase = subscriptionsDatabase;
  }

  public calculateCurrentCredits(userId: string) {
    return this.getCredits(userId).pipe(
      map((credits: Credits) => {
        const now = new Date();
        const tickIntervalMillis = credits.subscriptionPlan.tickIntervalInMinutes * 60 * 1000;
        const timeSinceLastTick = now.getTime() - credits.lastUpdatedTick.getTime();
        const ticksElapsed = Math.floor(timeSinceLastTick / tickIntervalMillis);
        const currentCredits =
          credits.remainingCredits + credits.subscriptionPlan.creditsPerTick * ticksElapsed;

        credits.remainingCredits = Math.min(currentCredits, credits.subscriptionPlan.maxCredits);
        if (ticksElapsed > 0) {
          credits.lastUpdatedTick = new Date(
            credits.lastUpdatedTick.getTime() + ticksElapsed * tickIntervalMillis,
          );
        }
        return credits;
      }),
    );
  }

  public async updateCreditsUsage(userId: string, usage: number): Promise<Credits> {
    const creditsObservable = this.calculateCurrentCredits(userId);
    const credits = await firstValueFrom(creditsObservable);

    credits.remainingCredits = Math.max(0, credits.remainingCredits - usage);
    // credits.lastUpdatedTick = new Date();
    await this.creditsDatabase.updateCredits(userId, credits);
    return credits;
  }

  private getCredits(userId: string): Observable<Credits> {
    const plan = this.getCreditsSubscriptionPlan(userId);
    const credits = this.creditsDatabase.getCredits(userId);
    return combineLatest([plan, credits]).pipe(
      map(([p, c]) =>
        !!c
          ? {...c, subscriptionPlan: p}
          : ({
              lastUpdatedTick: new Date(),
              remainingCredits: p.maxCredits,
              subscriptionPlan: p,
            } as Credits),
      ),
    );
  }

  private getCreditsSubscriptionPlan(userId: string): Observable<CreditsSubscriptionPlan> {
    return this.userSubscriptionsDatabase
      .getActiveSubscription(userId)
      .pipe(map(this.extractCreditsSubscriptionPlan));
  }

  private extractCreditsSubscriptionPlan(
    subscription: StripeUserSubscription | undefined,
  ): CreditsSubscriptionPlan {
    if (subscription && subscription?.items[0]?.price?.product?.metadata) {
      if (subscription.items.length !== 1) {
        throw new Error("Invalid subscription");
      }
      const metadata = subscription.items[0].price.product.metadata;
      return {
        creditsPerTick: metadata["creditsPerTick"],
        maxCredits: metadata["maxCredits"],
        tickIntervalInMinutes: metadata["tickIntervalInMinutes"],
      };
    }
    return freePlanCredits;
  }
}
