import {Component, OnInit} from "@angular/core";
import {ApiUsageService} from "../../../../services/usage/api-usage.service";
import {AuthService} from "../../../auth/auth.service";
import {Destroyer} from "../../../_helpers/Destroyer";
import {Subject, takeUntil} from "rxjs";
import {Title} from "@angular/platform-browser";
import {ApiUsageEntity} from "../../../../../../.common/usage";
import {isPermissionDeniedError} from "../../../../../../.common/firestore/errors";

@Component({
  selector: "app-usage",
  templateUrl: "./usage.component.html",
  styleUrls: ["./usage.component.scss"],
})
export class UsageComponent extends Destroyer implements OnInit {
  usage$ = new Subject<ApiUsageEntity>();

  constructor(
    private usageService: ApiUsageService,
    private authService: AuthService,
    private titleService: Title,
  ) {
    super();
    this.titleService.setTitle("Usage - Symbiot AI");
  }

  async ngOnInit() {
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.usageService
          .getUserUsages(user.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (usage) => this.usage$.next(usage),
            error: (error) => {
              if (isPermissionDeniedError(error)) {
                this.usage$.next({
                  currentUsage: 0,
                } as ApiUsageEntity);
              } else {
                throw error;
              }
            },
          });
      }
    });
  }
}
