import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CreatePromptPageComponent} from "./create-prompt-page.component";
import {MatStepperModule} from "@angular/material/stepper";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {UiModule} from "../../../ui/ui.module";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ModelsComponent} from "./controls/models/models.component";
import {MatDialogModule} from "@angular/material/dialog";
import {PromptCommonModule} from "../common/prompt-common.module";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [CreatePromptPageComponent, ModelsComponent],
  exports: [CreatePromptPageComponent],
  imports: [
    CommonModule,
    PromptCommonModule,
    UiModule,
    MatStepperModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    RouterLink,
    MatInputModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
  ],
})
export class CreatePromptPageModule {}
