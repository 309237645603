import firebase from "firebase/compat";
import FieldValue = firebase.firestore.FieldValue;

export interface ApiUsageEntity {
  id?: string;
  currentLimit: number;
  currentUsage: number | FieldValue;
  lastUpdated: Date | FieldValue;
}

export const creditCoefficient = 90;
export const freeTierCredits = 15;
