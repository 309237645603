import {Component, effect, inject, Injectable} from "@angular/core";
import {SidenavBarService} from "../../../services/sidenav-bar.service";
import {createSelector, Store} from "@ngrx/store";
import {getRouterSelectors} from "@ngrx/router-store";
import {authFeature} from "../../auth/state/auth.state";

@Injectable()
export class SecondLevelMenuBase {
  vm = inject(Store).selectSignal(
    createSelector({
      user: authFeature.selectUser,
      routerData: getRouterSelectors().selectRouteData,
      routerUrl: getRouterSelectors().selectUrl,
    }),
  );
  replacements = {
    "{myProfileUrl}": () => `/p/${this.vm().user?.id}`,
    "{self}": () => this.vm().routerUrl,
  } as {[key: string]: () => string};
  menuItems: {label: string; link: string; icon: string}[] = [];
  menuExist = false;
  chatExists = false;
  displaySideNav?: boolean;

  constructor() {
    effect(() => {
      const vm = this.vm();
      this.chatExists = !!vm.routerData["chatEnabled"];
      this.menuItems = vm.routerData["menuPoints"];
      this.displaySideNav = vm.routerData["displaySideNav"];
      this.menuExist = !!this.menuItems && this.menuItems.length > 0;

      if (this.menuExist) {
        for (let i = 0; i < this.menuItems.length; i++) {
          const item = this.menuItems[i];
          if (this.replacements[item.link]) {
            this.menuItems[i].link = this.replacements[item.link]();
          }
        }
      }
    });
  }
}

@Component({
  selector: "ui-side-navbar",
  templateUrl: "./side-navbar.component.html",
  styleUrls: ["./side-navbar.component.scss"],
})
export class SideNavbarComponent extends SecondLevelMenuBase {
  constructor(public sidenavBarService: SidenavBarService) {
    super();
  }
}
