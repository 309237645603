import {AiApi, AiModel, AiParameter} from "./types";
import {AiContentType, ApiType} from "../";
import {ModelCategoryTextGeneration} from "./model-category";

const prompt: AiParameter = {
  key: "prompt",
  displayName: "Prompt",
  type: "text",
  mapTo: "prompt",
  required: true,
};

/** deprecated. should use openai chat instead */
export class Gpt3 implements AiApi {
  id: ApiType = "gpt3";
  contentType: AiContentType = "text";
  name = "GPT-3";
  description =
    "GPT-3 is a natural language generation model from OpenAI. It is the most powerful language model ever created. It can generate text in response to a prompt. It can also be used to perform many other tasks, such as summarizing text, translating text, and more.";
  models: AiModel[] = [
    // {id: "gpt-3.5-turbo", prices: {"1K": 0.002}},
    // {id: "gpt-3.5-turbo-16k", prices: {"1K": 0.004}},
    {
      id: "text-davinci-003",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K": 0.02},
      parameters: [prompt],
      contentType: "text",
    },
    {
      id: "text-curie-001",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K": 0.002},
      parameters: [prompt],
      contentType: "text",
    },
    {
      id: "text-babbage-001",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K": 0.0005},
      parameters: [prompt],
      contentType: "text",
    },
    {
      id: "text-ada-001",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K": 0.0004},
      parameters: [prompt],
      contentType: "text",
    },
    {
      id: "code-davinci-002",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K": 0.02},
      parameters: [prompt],
      contentType: "text",
    },
    {
      id: "code-cushman-001",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K": 0.02},
      parameters: [prompt],
      contentType: "text",
    },
  ];
  defaultModelId: string = "text-davinci-003";

  getModel(modelId: string): AiModel {
    return this.models.find((model) => model.id === modelId) as AiModel;
  }
}
