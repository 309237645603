import {OpenAIChatModelParameterKey} from "./openai-chat";
import {OpenAIDalleModelParameterKey} from "./dall-e";
import {GooglePalmParameterKeys, GoogleVertexImageCaptioningKeys} from "./google-palm";
import {AnthropicParameterKey} from "./anthropic";
import {AiParameter} from "./types";
import {StabilityAIParameterKeys} from "./stability-ai";
import {MistralParameterKeys} from "./mistral";

type CommonModelParameterKeys = "maxContextLength";
export type ModelParameterKey =
  | CommonModelParameterKeys
  | OpenAIChatModelParameterKey
  | OpenAIDalleModelParameterKey
  | GooglePalmParameterKeys
  | GoogleVertexImageCaptioningKeys
  | AnthropicParameterKey
  | StabilityAIParameterKeys
  | MistralParameterKeys;
export const maxContextLength: AiParameter = {
  key: "maxContextLength",
  displayName: "Maximum context length",
  description: "The maximum number of tokens for the model context.",
  type: "range",
  skipForAi: true,
};
