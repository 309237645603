import * as CryptoJS from "crypto-js";

export function stringToHash(str: string) {
  return CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex);
}

export function stringToNumberHash(str: string) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}
