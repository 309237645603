import {createSelector} from "@ngrx/store";
import * as fromRouter from "@ngrx/router-store";

const {selectRouteData, selectQueryParam} = fromRouter.getRouterSelectors();

export const selectRouteDataTermsOff = createSelector(
  selectRouteData,
  (data) => !!data?.["termsOff"],
);

export const selectRouteUtmParam = selectQueryParam("utm_source");
