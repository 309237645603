import firebase from "firebase/compat/app";
import {chats, chats_messages} from "../firestore-collections";
import {WithId} from "../firestore/dataconverters";
import {Chat} from "../chat/chat";
import {ChatMessage} from "../chat/chat-message";

export class ChatCollectionProvider {
  private readonly firestore: firebase.firestore.Firestore;

  constructor(firestore: firebase.firestore.Firestore) {
    this.firestore = firestore;
  }
  getChatCollection() {
    return this.firestore.collection(chats).withConverter(new WithId<Chat>());
  }

  getChatMessagesCollection(chatId: string) {
    return this.firestore
      .collection(`${chats}/${chatId}/${chats_messages}`)
      .withConverter(new WithId<ChatMessage>());
  }
}
