import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import {UserService} from "../../../../services/user.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {PublicUserInfo} from "../../../../../../.common";
import {
  ChatParticipant,
  ChatParticipantAiApi,
  ChatParticipantHuman,
  isChatParticipantAiApi,
  isChatParticipantHuman,
} from "../../../../../../.common/chat/chat-participant";
import {getApiById} from "../../../../../../.common/ai-models/all-api";
import {AiApi, AiModel} from "../../../../../../.common/ai-models";

@Component({
  selector: "ui-profile-icon",
  templateUrl: "./profile-icon.component.html",
  styleUrls: ["./profile-icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, NgOptimizedImage],
})
export class ProfileIconComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  changeDetectorRef = inject(ChangeDetectorRef);
  @Input({required: false})
  publicUserData?: PublicUserInfo;
  @Input()
  showName = true;
  @Input()
  showImage = true;
  @Input()
  chatParticipant?: ChatParticipant | ChatParticipantAiApi | ChatParticipantHuman;
  @Input()
  profileIconSize = 24;
  @Input()
  displayNameClasses: string | string[] = "username";
  @Input()
  iconClasses: string | string[] = "profile-icon";
  @Input()
  withLink: boolean = false;
  userService = inject(UserService);

  ngOnInit(): void {
    if (this.chatParticipant && this.publicUserData) {
      throw new Error("chatParticipant and publicUserData are mutually exclusive");
    }
    if (this.chatParticipant) {
      if (isChatParticipantHuman(this.chatParticipant)) {
        this.userService
          .getPublicUserData(this.chatParticipant.id)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((publicUserInfo) => {
            this.publicUserData = publicUserInfo;
            this.changeDetectorRef.detectChanges();
          });
      } else {
        this.updatePublicData();
      }
    }
  }

  private updatePublicData() {
    if (this.chatParticipant && isChatParticipantAiApi(this.chatParticipant)) {
      const aiApi = getApiById(this.chatParticipant.id);
      const model = aiApi.getModel(this.chatParticipant.modelId);
      const displayName = this.chatParticipant.modelId ? model?.displayName : aiApi.name;
      const photoURL = this.#getPhotoUrl(model, aiApi);
      this.publicUserData = {
        displayName,
        photoURL,
      };
    }
  }

  #getPhotoUrl(model: AiModel, aiApi: AiApi) {
    const icon = model?.icon ? `models/${model.icon}` : aiApi.id + ".png";
    return "/assets/img/ai-api/" + icon;
  }
}
