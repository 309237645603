import {inject, Injectable} from "@angular/core";
import {ChatService} from "../services/chat.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  ChatActions,
  ChatAiApiActions,
  ChatContextMessagesActions,
  ChatCurrentMessageActions,
  ChatMessagesActions,
  ChatPageActions,
} from "./chat.actions";
import {
  catchError,
  debounceTime,
  exhaustMap,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import {distinctUntilChanged, EMPTY, filter, map, of, takeUntil, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {chatFeature} from "./chat.state";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {AuthService} from "../../../auth/auth.service";
import {routerNavigatedAction} from "@ngrx/router-store";
import {MainLayoutService} from "../../../layouts/main-layout/main-layout.service";
import {authFeature} from "../../../auth/state/auth.state";
import {Router} from "@angular/router";
import {isPermissionDeniedError} from "../../../../../../.common/firestore/errors";
import {ChatMessage} from "../../../../../../.common/chat/chat-message";
import {ChatParticipantAiApi} from "../../../../../../.common/chat/chat-participant";
import {stringToHash} from "../../../../../../.common/text/stringToHash";
import {setChatListMode} from "./chat-list-mode";
import {ModelParameterKey} from "../../../../../../.common/ai-models/model-parameters";
import * as CryptoJS from "crypto-js";

let streamrSubscription: WebSocket | undefined;
@Injectable()
export class ChatEffects {
  chatService = inject(ChatService);
  actions$ = inject(Actions);
  store = inject(Store);
  authService = inject(AuthService);
  layoutService = inject(MainLayoutService);
  router = inject(Router);

  onChatIdParam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      map(({payload}) => payload.routerState.root.firstChild?.firstChild?.params["chatId"]),
      exhaustMap((chatId) =>
        chatId
          ? [ChatActions.loadChat(chatId), ChatPageActions.loading(true)]
          : [ChatPageActions.setChatEmpty(), ChatPageActions.loading(false)],
      ),
    ),
  );

  loadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadChat),
      filter(({chatId}) => !!chatId),
      exhaustMap(({chatId}) => {
        return this.chatService.getMessages(chatId).pipe(
          takeUntil(this.actions$.pipe(ofType(routerNavigatedAction))),
          mergeMap((messages) => [ChatMessagesActions.setChatMessages(messages)]),
          catchError((err) =>
            isPermissionDeniedError(err) ? of(ChatPageActions.setPermissionDenied(true)) : EMPTY,
          ),
        );
      }),
      catchError((err) =>
        isPermissionDeniedError(err) ? of(ChatPageActions.setPermissionDenied(true)) : EMPTY,
      ),
    ),
  );
  loadChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadChat),
      filter(({chatId}) => !!chatId),
      switchMap(({chatId}) => this.chatService.getChat(chatId)),
      exhaustMap((chat) => [ChatActions.setChat(chat)]),
      catchError((err) =>
        isPermissionDeniedError(err) ? of(ChatPageActions.setPermissionDenied(true)) : EMPTY,
      ),
    ),
  );
  streamSubscribe$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.loadChat),
        filter(({chatId}) => !!chatId),
        distinctUntilChanged(),
        switchMap(({chatId}) => this.chatService.getChat(chatId)),
        distinctUntilChanged((previous, current) => previous.id === current.id),
        tap((chat) => {
          console.log("chat", chat);
          if (streamrSubscription && streamrSubscription.readyState === WebSocket.OPEN) {
            streamrSubscription.close();
            console.log("streamr closed");
          }
          if (!chat) {
            return;
          }
          const stream = encodeURIComponent("funshot.eth/symbiotai-chats-3");
          streamrSubscription = new WebSocket(
            `wss://streaming.symbiotai.com/streams/${stream}/subscribe?partitions=0&apiKey=`,
            // `ws://streaming.symbiotai.com/streams/${stream}/subscribe?partitions=0&apiKey=`,
            // `ws://98.67.160.6:7170/streams/${stream}/subscribe?apiKey=MmMyOWNjMGY0YmM3NGYxMmExZTBkYmMzNzBkMTQxYjE`,
          );
          let n = -1;
          streamrSubscription.onmessage = (streamrMsg: any) => {
            try {
              const data = JSON.parse(streamrMsg.data);
              CryptoJS.AES.decrypt(data.data, "123");
              if (data.n !== n + 1) {
                console.error("missed message", n, data.n);
              }
              n = data.n;
              // this.streamSubject$.next(data);
              // console.log("data", data);

              this.store.dispatch(ChatMessagesActions.appendTypingMessage(data.id, data.data));
            } catch (e) {
              console.error("error parsing streamr message", e);
            }

            // if (this.contentFromStream.has(data.id)) {
            //   this.contentFromStream.set(data.id, this.contentFromStream.get(data.id) + data.data);
            // } else {
            //   this.contentFromStream.set(data.id, data.data);
            // }
            // this.#changeDetectorRef.detectChanges();
          };

          // const streamrClient = new StreamrClient();
          // const stream = "funshot.eth/symbiotai-chats-2";
          // streamrClient
          //   .subscribe(
          //     {
          //       partition: 0,
          //       stream: stream,
          //     },
          //     (message: any) => {
          //       console.log("message", message);
          //     },
          //   )
          //   .then(() => console.log("subscribed"))
          //   .catch(console.error);
          // streamrClient
          //   .setProxies(
          //     {stream},
          //     [
          //       {
          //         nodeId: "5cb86b27c66dc53140450d35a2b6f3e87adf071c",
          //         websocket: {host: "98.67.160.6", port: 7170, tls: false},
          //       },
          //     ],
          //     ProxyDirection.SUBSCRIBE,
          //   )
          //   .then(() => {
          //     streamrClient
          //       .subscribe(
          //         {
          //           partition: 0,
          //           stream: stream,
          //         },
          //         (message: any) => {
          //           console.log("message", message);
          //         },
          //       )
          //       .then(() => console.log("subscribed"))
          //       .catch(console.error);
          //   });
        }),
      ),
    {dispatch: false},
  );

  onFirstSetChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessagesActions.setChatMessages),
      withLatestFrom(
        this.store.select(chatFeature.selectChatPageLoading),
        this.store.select(chatFeature.selectPermissionDenied),
      ),
      filter(([, loading, permissionDeny]) => !!loading || !!permissionDeny),
      exhaustMap(([{chatMessages}]) => [
        ChatPageActions.loading(false),
        ChatPageActions.setPermissionDenied(false),
        ChatPageActions.setChatAutoScroll(true),
        ...(chatMessages.length === 0 ? [ChatPageActions.setChatEmpty()] : []),
      ]),
    ),
  );

  updateDefaultApiParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatAiApiActions.setSelectedAiModel),
      map(({model}) => this.chatService.getDefaultModelParameters(model)),
      exhaustMap((parameters) => [
        ChatCurrentMessageActions.updateModelParameters(parameters.modelParameters),
        ChatCurrentMessageActions.updateModelSkippedParameters(parameters.modelSkippedParameters),
      ]),
    ),
  );

  updateContextMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChatCurrentMessageActions.updateCurrentMessage,
        ChatCurrentMessageActions.updateCurrentMessageText,
        ChatCurrentMessageActions.updateModelParameters,
        ChatCurrentMessageActions.updateMessageModelParameter,
        ChatCurrentMessageActions.updateMessageModelSkippedParameter,
        ChatContextMessagesActions.updateUseContext,
        ChatMessagesActions.setChatMessages,
        ChatContextMessagesActions.setChatReplyMessage,
      ),
      debounceTime(100),
      // switchMap(() => this.store.select(chatFeature.selectCurrentChatId)),
      // filter((chatId) => !!chatId),
      switchMap(() => this.store.select(chatFeature.selectCurrentMessage)),
      withLatestFrom(
        this.store.select(chatFeature.selectChatMessages),
        this.store.select(chatFeature.selectUseContextMode),
        this.store.select(chatFeature.selectSelectedAiApi),
        this.store.select(chatFeature.selectModelParameters),
        this.store.select(chatFeature.selectModelSkippedParameters),
        this.store.select(chatFeature.selectChatReplyMessage),
        this.store.select(chatFeature.selectSelectedAiModel),
      ),
      filter(
        ([, , , apiType, modelParams, modelSkippedParams, , aiModel]) =>
          !!apiType &&
          !!aiModel &&
          (!!modelParams["max_tokens"] ||
            !!modelParams["maxOutputTokens"] ||
            !!modelParams["max_tokens_to_sample"] ||
            !!modelSkippedParams["maxContextLength"]),
        // &&
        // chatMessages.length > 0,
      ),
      exhaustMap(
        ([
          currentMessage,
          chatMessages,
          contextMode,
          apiType,
          modelParams,
          modelSkippedParams,
          chatReplyMessage,
          aiModel,
        ]) => {
          if (contextMode === "manual") {
            return [];
          }
          if (contextMode === "off") {
            return [
              ChatContextMessagesActions.updateContextMessages([]),
              ChatContextMessagesActions.setContextSize(
                this.chatService.calculateContentSize(currentMessage, apiType!, aiModel!),
              ),
            ];
          }
          const {contextMessages, totalContextTokens} = this.chatService.fitMessagesToContext(
            chatMessages,
            currentMessage,
            apiType!,
            {...modelParams, ...modelSkippedParams},
            chatReplyMessage,
            aiModel!,
          );
          return [
            ChatContextMessagesActions.updateContextMessages(contextMessages),
            ChatContextMessagesActions.setContextSize(totalContextTokens),
          ];
        },
      ),
    ),
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessagesActions.sendMessage),
      withLatestFrom(
        this.store.select(chatFeature.selectCurrentChatId),
        this.store.select(chatFeature.selectCurrentMessage),
        this.store.select(chatFeature.selectContextMessages),
        this.store.select(chatFeature.selectSelectedAiApi),
        this.store.select(authFeature.selectUser),
        this.store.select(chatFeature.selectModelParameters),
        this.store.select(chatFeature.selectSelectedAiModel),
        this.store.select(chatFeature.selectChatReplyMessage),
        this.store.select(chatFeature.selectMessageContent),
      ),
      //If reply message has an image, put this image into message content

      map(
        ([
          ,
          chatId,
          currentMessage,
          contextMessages,
          aiApiId,
          user,
          modelParameters,
          model,
          replyMessage,
          messageContent,
        ]) => {
          if (!chatId) {
            throw new Error("chatId is undefined");
          }
          if (!user) {
            throw new Error("user is undefined");
          }
          //When replying to a message, if there are no images attached, add the image from the reply
          const currentImage = Object.values(messageContent).find(
            (content) => content.type == "image_storage",
          );

          if (!currentImage && replyMessage && replyMessage.metadata?.contentType == "image") {
            const id = stringToHash(replyMessage.metadata.storagePath);
            messageContent[id] = {
              id: id,
              type: "image_storage",
              storagePath: replyMessage.metadata.storagePath,
            };
          }
          const replyImage = replyMessage?.content?.find(
            (content) => content.type == "image_storage",
          );
          if (!currentImage && replyMessage && replyImage) {
            messageContent[replyImage.id] = {
              ...replyImage,
            };
          }

          const params = {} as Record<ModelParameterKey, any>;
          Object.keys(modelParameters).forEach((key) => {
            const k = key as ModelParameterKey;
            if (modelParameters[k]) {
              params[k] = modelParameters[k];
            }
          });

          const message: ChatMessage = {
            ...currentMessage,
            ...{
              sender: {type: "human", id: user.id},
              content: Object.values(messageContent),
              metadata: {
                ...currentMessage.metadata,
                modelParameters: params,
                contextMessageIds: contextMessages.map((it) => it.id),
              },

              mentions: [
                {
                  type: "model",
                  id: aiApiId,
                  modelId: model?.id,
                } as ChatParticipantAiApi,
              ],
            },
          };
          if (replyMessage) {
            message.replyMessageId = replyMessage.id;
          }
          return fromPromise(this.chatService.sendMessage(chatId, message));
        },
      ),
      exhaustMap(() => [ChatMessagesActions.messageSent(), ChatPageActions.scrollToBottom()]),
    ),
  );

  onMessageSent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessagesActions.messageSent),
      map(() => {
        return ChatCurrentMessageActions.clearCurrentMessage();
      }),
    ),
  );

  addModelToParticipants$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatAiApiActions.setSelectedAiApi),
        withLatestFrom(this.store.select(chatFeature.selectCurrentChat)),
        tap(async ([{apiId}, chat]) => {
          if (!chat) {
            throw new Error("Chat is not selected. Can't add model to participants.");
          }
          if (chat && !chat.modelParticipants?.includes(apiId)) {
            await this.chatService.addChatModelParticipant(chat.id, apiId);
          }
        }),
      ),
    {dispatch: false},
  );

  autoScrollChatToBottom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatMessagesActions.sendMessage, ChatMessagesActions.setChatMessages),
        withLatestFrom(this.store.select(chatFeature.selectChatAutoScroll)),
        filter(([, autoScroll]) => autoScroll),
        tap(() => this.layoutService.scrollToBottom()),
      ),
    {dispatch: false},
  );
  scrollContentToBottom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatPageActions.scrollToBottom),
        tap(() => this.layoutService.scrollToBottom()),
      ),
    {dispatch: false},
  );

  chanceChatVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.setChatVisibility),
      withLatestFrom(this.store.select(chatFeature.selectCurrentChat)),
      filter(([{visibility}, chat]) => !!chat && chat.visibility !== visibility),
      tap(([{chatId, visibility}]) =>
        fromPromise(this.chatService.setChatVisibility(chatId, visibility)),
      ),
      exhaustMap(([{visibility}, chat]) => [
        ChatActions.setChat({
          ...chat!,
          visibility,
        }),
      ]),
    ),
  );

  changeChatDisplayName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.changeChatDisplayName),
      withLatestFrom(this.store.select(chatFeature.selectCurrentChat)),
      filter(([chat]) => !!chat),
      tap(async ([{chatId, displayName}]) => {
        await this.chatService.changeChatDisplayName(chatId, displayName);
      }),
      exhaustMap(([{displayName}, chat]) => [
        ChatActions.setChat({
          ...chat!,
          displayName,
        }),
      ]),
    ),
  );

  navBarDeleteSelectedChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.navbarChatsDeleteSelected),
      withLatestFrom(this.store.select(chatFeature.selectNavbarSelectedChats)),
      filter(([, selectedChats]) => Object.keys(selectedChats).length > 0),
      map(([, selectedChats]) => Object.keys(selectedChats)),
      exhaustMap((chatIds) => [ChatActions.deleteChats(chatIds)]),
    ),
  );
  deleteChats$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.deleteChats),
        tap(async ({chatIds}) => {
          await this.chatService.deleteChats(chatIds);
        }),
      ),
    {dispatch: false},
  );
  setChatListMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.changeChatListMode),
        tap(({mode}) => setChatListMode(mode)),
      ),
    {dispatch: false},
  );

  loadChatList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadChatList),
      switchMap(() => this.store.select(chatFeature.selectChatListMode)),
      mergeMap((mode) => {
        switch (mode + "") {
          case "private":
            return this.chatService
              .getChatsForUser(this.authService.requireUserId())
              .pipe(map((chatList) => ChatActions.setChatList(chatList)));
          case "public":
            return this.chatService
              .getPublicChats()
              .pipe(map((chatList) => ChatActions.setChatList(chatList)));
          case "shared":
            return this.chatService
              .getSharedChats(this.authService.requireUserId())
              .pipe(map((chatList) => ChatActions.setChatList(chatList)));
          default:
            throw new Error("Unknown chat list mode");
        }
      }),
    ),
  );
}
