<div class="dialog">
  <p>
    Welcome to SymbiotAI! We're excited to have you on board. Please take a moment to review and accept our terms and policies to continue.
  </p>
  <p>To use SymbiotAI, please accept our mandatory policies. These are essential for us to provide you with our services. You can also choose to receive marketing emails by selecting the optional checkbox.</p>
  <form [formGroup]="form" (ngSubmit)="acceptSelected()">
    <div class="checkboxes">
      <div class="checkbox">
        <mat-checkbox [class.error]="hasError(form.controls.terms)" formControlName="terms">
          I have read and agree to the <a routerLink="/terms" target="_blank">Terms of Service</a>.
        </mat-checkbox>
      </div>
      <div class="checkbox">
        <mat-checkbox [class.error]="hasError(form.controls.privacy)" formControlName="privacy">
          I have read and agree to the <a routerLink="/privacy" target="_blank">Privacy Policy</a>.
        </mat-checkbox>
      </div>
      <div class="checkbox">
        <mat-checkbox [class.error]="hasError(form.controls.acceptableUse)" formControlName="acceptableUse">
          I have read and agree to the <a routerLink="/acceptable-use-policy" target="_blank">Acceptable Use Policy</a>.
        </mat-checkbox>
      </div>
      <div class="checkbox">
        <mat-checkbox [class.error]="hasError(form.controls.ageConfirmed)" formControlName="ageConfirmed">
          I confirm that I am 18 years of age or older.
        </mat-checkbox>
      </div>
      <div class="checkbox">
        <mat-checkbox [class.error]="hasError(form.controls.marketing)" formControlName="marketing">
          I would like to receive updates, news, and special offers from SymbiotAI?
        </mat-checkbox>
      </div>
    </div>
    <div class="text-center">
      <button mat-flat-button color="primary" type="button" (click)="acceptAll()" cdkfocusinitial="true">Accept all & agree</button>
      <button mat-button type="submit">Agree with selected</button>
      <button mat-button (click)="logout()" type="button">Logout</button>
    </div>
  </form>
</div>
