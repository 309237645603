import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SidenavBarService {
  private key = "SidenavBarService-open";
  public open = true;
  public isMenuExist = true;

  constructor() {
    // this.open = !!localStorage.getItem(this.key);
  }

  public isClassActive = false;
  public isChatEnabled = false;

  toggle() {
    // this.open = !this.open;
    // if (this.open) {
    //   localStorage.setItem(this.key, "true");
    // } else {
    //   localStorage.removeItem(this.key);
    // }
  }

  close() {
    this.open = false;
    localStorage.removeItem(this.key);
  }

  setMenuExist(isMenuExist: boolean) {
    this.isMenuExist = isMenuExist;
  }

  setChatEnabled(isChatEnabled: boolean) {
    this.isChatEnabled = isChatEnabled;
  }

  menuExist() {
    return this.isMenuExist;
  }
}
