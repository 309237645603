<mat-card class="under-development">
  <button mat-button color="warn" disabled="disabled">
    This feature is not available yet.
    <p>
      <i>Be patient: our AI is currently sipping coffee and coding new features just for you!</i>
      🤖☕️
    </p>
  </button>
</mat-card>
<mat-card>
  <mat-card-content>
    <app-linked-account></app-linked-account>
    <mat-divider></mat-divider>
    <app-payout-history></app-payout-history>
  </mat-card-content>
</mat-card>
