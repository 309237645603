<ng-container *ngIf="loaded && !updatingAccountAfterReturn; else loadingTemplate">
  <ng-container
    *ngIf="
      stripeLinkedAccount$ && (stripeLinkedAccount$ | async) as acc;
      else accountNotConnectedTemplate
    "
  >
    <ng-container>
      <div class="status">
        <ng-container
          [ngTemplateOutlet]="accountStatus"
          [ngTemplateOutletContext]="{status: acc.status, error: acc.connectionError}"
        ></ng-container>
        <div>
          <button mat-button [disabled]="acc.status !== 'connected'" (click)="redirectToStripe()">
            Settings
          </button>
        </div>
      </div>
      <ng-container
        *ngIf="!acc.status || acc.status === 'userInfoRequired' || acc.status === 'failed'"
      >
        <p>
          It seems you didn't finish configure your Stripe account. Please click the button below to
          finish the configuration.
        </p>
        <ng-template [ngTemplateOutlet]="retryButtonTemplate"></ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #accountStatus let-status="status" let-error="error">
  <p>
    Account status:
    <b *ngIf="!error">{{ humanReadableStatus(status) }}</b>
    <b *ngIf="error" class="error">Error: {{ error }}</b>
  </p>
</ng-template>
<ng-template #accountNotConnectedTemplate>
  <ng-template
    [ngTemplateOutlet]="accountStatus"
    [ngTemplateOutletContext]="{status: 'Not connected'}"
  ></ng-template>
  <p>To set up and configure your payout account please click the button below.</p>
  <button
    mat-raised-button
    color="primary"
    [disabled]="waitForRedirect"
    (click)="connectStripe()"
    type="button"
  >
    Set up account
  </button>
</ng-template>

<ng-template #retryButtonTemplate>
  <button
    mat-raised-button
    color="primary"
    [disabled]="waitForRedirect"
    (click)="redirectToStripe()"
    type="button"
  >
    Retry
    <mat-progress-bar *ngIf="waitForRedirect" mode="indeterminate"></mat-progress-bar>
  </button>
</ng-template>
<ng-template #loadingTemplate>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>
