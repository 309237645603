import {Observable} from "rxjs";
import firebase from "firebase/compat/app";

export function docRefToObservable<T>(
  docRef: firebase.firestore.DocumentReference<T>,
): Observable<T> {
  return new Observable((observer) => {
    const unsubscribe = docRef.onSnapshot(
      (snapshot) => {
        observer.next(snapshot.data());
      },
      (error) => {
        observer.error(error);
      },
      () => {
        observer.complete();
      },
    );

    return () => {
      unsubscribe();
    };
  });
}

export function queryToObservableArray<T>(query: firebase.firestore.Query<T>): Observable<T[]> {
  return new Observable((observer) => {
    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        observer.next(snapshot.docs.map((doc) => doc.data()));
      },
      (error) => {
        observer.error(error);
      },
      () => {
        observer.complete();
      },
    );

    return () => {
      unsubscribe();
    };
  });
}
export function queryToObservable<T>(query: firebase.firestore.Query<T>): Observable<T> {
  return new Observable((observer) => {
    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        snapshot.docs.forEach((doc) => {
          observer.next(doc.data());
        });
      },
      (error) => {
        observer.error(error);
      },
      () => {
        observer.complete();
      },
    );

    return () => {
      unsubscribe();
    };
  });
}
