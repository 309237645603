<a routerLink="/prompts" mat-stroked-button>< back to prompts</a>

<div *ngIf="!isLoading && !notFound">
  <mat-stepper
    linear
    orientation="vertical"
    #stepper
    [selectedIndex]="stepperIndex"
    (selectionChange)="onStepChange($event)"
  >
    <mat-step [formGroup]="chooseModelForm" [completed]="isEditPage()">
      <form [formGroup]="chooseModelForm">
        <ng-template *ngIf="stepper.selectedIndex === 0" matStepLabel
        >Select the AI model
        </ng-template
        >
        <ng-template *ngIf="stepper.selectedIndex !== 0" matStepLabel>{{
          aiPrompt?.displayName || aiPrompt?.id || "My prompt"
          }}</ng-template>
        <mat-form-field>
          <mat-label>AI</mat-label>
          <mat-select formControlName="api" (valueChange)="onAiModelChange($event)">
            <mat-option *ngFor="let model of availableAiApis" [value]="model.id">
              {{ model.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="selectedApi">
          <app-models
            [api]="selectedApi"
            [selectedValue]="aiPrompt?.model"
            (selectedModelChange)="onSelectedModelChange($event)"
          ></app-models>
          <div>
            <mat-form-field>
              <mat-label>Display name</mat-label>
              <input formControlName="displayName" matInput type="text"/>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Description</mat-label>
              <textarea formControlName="description" matInput type="area" rows="5"></textarea>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field *ngIf="false" class="example-chip-list" appearance="fill">
              <mat-label>Enter Tags...</mat-label>
              <mat-chip-grid #chipGrid aria-label="Enter fruits">
                <mat-chip-row
                  *ngFor="let tag of tags"
                  (removed)="remove(tag)"
                  (edited)="edit(tag, $event)"
                  [editable]="true"
                >
                  {{ tag }}
                  <button matChipRemove [attr.aria-label]="'remove ' + tag">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input
                  placeholder="New fruit..."
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="add($event)"
                />
              </mat-chip-grid>
            </mat-form-field>
            <button
              mat-raised-button
              color="primary"
              type="button"
              *ngIf="isEditPage()"
              [disabled]="disableUpdateModelButton || !chooseModelForm.dirty"
              (click)="updateSelectedModel(stepper)"
            >
              Update
            </button>
            <button
              *ngIf="!isEditPage()"
              [disabled]="disableCreateButton || !chooseModelForm.valid"
              mat-raised-button
              color="primary"
              type="button"
              (click)="create()"
            >
              Create new AI prompt
              <mat-progress-bar *ngIf="disableCreateButton" mode="indeterminate"></mat-progress-bar>
            </button>
          </div>
          <div *ngIf="isEditPage()">
            <button
              type="button"
              mat-raised-button
              [disabled]="!modelConfigurationForm.valid"
              (click)="stepper.next()"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [completed]="modelConfigurationForm.valid && templateValidated">
      <form [formGroup]="modelConfigurationForm">
        <ng-template matStepLabel>Model configuration</ng-template>
        <div>Write your promt to AI model</div>
        <mat-form-field>
          <mat-label>Enter your prompt</mat-label>
          <textarea
            formControlName="prompt"
            matInput
            type="area"
            (ngModelChange)="onPromptTextChange($event)"
            rows="5"
            placeholder="Insert a text you want to send to the AI"
          ></textarea>
        </mat-form-field>
        <div *ngIf="templateVariables.length > 0">
          <span>Variables in the template</span>
          <ul *ngFor="let t of templateVariables">
            <li>{{ t.selector }}</li>
          </ul>
        </div>
        <div>
          <button
            type="button"
            mat-raised-button
            [disabled]="templateValidated || !modelConfigurationForm.get('prompt')?.value"
            (click)="validateTemplate()"
          >
            Validate template
          </button>
          <button
            type="button"
            mat-raised-button
            [disabled]="!templateValidated || !modelConfigurationForm.dirty"
            (click)="saveTemplate()"
          >
            Save template
          </button>
        </div>
        <div>
          <button
            type="button"
            [disabled]="!modelConfigurationForm.valid"
            mat-raised-button
            (click)="stepper.previous()"
          >
            Previous
          </button>
          <button
            type="button"
            [disabled]="!modelConfigurationForm.valid"
            mat-raised-button
            (click)="stepper.next()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Test the model</ng-template>
      <form *ngIf="isEditPage()">
        <div *ngIf="templateVariables.length > 0">
          <span>Variables in the template</span>
          <ul *ngFor="let t of templateVariables">
            <li>
              <mat-form-field>
                <mat-label>{{ t.selector }}</mat-label>
                <input matInput type="text" class="testModelInputFields" name="{{ t.selector }}"/>
              </mat-form-field>
            </li>
          </ul>
          <button type="button" mat-raised-button (click)="parseTemplate()">
            Inject variables in the template
          </button>
        </div>
        <div>
          {{ testModelTextToSend }}
        </div>

        <button type="button" mat-raised-button *ngIf="keyExists()" (click)="removeApiKey()">
          Remove API key
        </button>
        <button
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="!testModelTextToSend || modelCompletionsLoading"
          (click)="testPrompt()"
        >
          Test with AI model
          <mat-progress-bar *ngIf="modelCompletionsLoading" mode="indeterminate"></mat-progress-bar>
        </button>
        <app-api-response-results
          responseType="test"
          [promptId]="getPromptId()"
          [api]="selectedApi"
        ></app-api-response-results>
        <!--        <div>-->
        <!--          <div *ngIf="selectedApi?.contentType == 'text'">-->
        <!--            <pre *ngFor="let response of apiResponses$.slice().reverse()">{{response.content}}</pre>-->
        <!--          </div>-->
        <!--          <div *ngIf="selectedApi?.contentType == 'image'">-->
        <!--            <pre *ngFor="let response of apiResponses$.slice().reverse()"><img [src]="response.content.url"/></pre>-->
        <!--          </div>-->
        <!--        </div>-->
        <div>
          <button
            type="button"
            mat-raised-button
            [disabled]="!modelConfigurationForm.valid"
            (click)="stepper.previous()"
          >
            Previous
          </button>
          <button
            type="button"
            mat-raised-button
            [disabled]="!modelConfigurationForm.valid"
            (click)="stepper.next()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <form [formGroup]="publishSettingsForm">
        <ng-template matStepLabel>Publish</ng-template>
        <h3>Visibility setting</h3>
        <mat-button-toggle-group formControlName="visibility">
          <mat-button-toggle
            matTooltip="Everyone see and able to use the prompt, except prompt body."
            matTooltipPosition="above"
            value="public"
          >Public
          </mat-button-toggle>
          <!--        <mat-button-toggle matTooltip="Everyone see and able to use the prompt, INCLUDING prompt body."-->
          <!--                           matTooltipPosition="above" value="public-body">Public body-->
          <!--        </mat-button-toggle>-->
          <mat-button-toggle
            matTooltip="Only you see the prompt."
            matTooltipPosition="above"
            value="private"
          >Private
          </mat-button-toggle>
        </mat-button-toggle-group>
        <h3>Prompt body</h3>
        <mat-button-toggle-group formControlName="bodyVisibility">
          <mat-button-toggle
            matTooltip="The prompt body is visible to everyone."
            matTooltipPosition="above"
            value="visible"
          >Visible
          </mat-button-toggle>
          <mat-button-toggle
            matTooltip="The prompt body is hidden from everyone."
            matTooltipPosition="above"
            value="hidden"
          >Hidden
          </mat-button-toggle>
        </mat-button-toggle-group>

        <h3>Payments</h3>
        <p>Do you want to earn on this prompt?</p>

        <mat-checkbox formControlName="isPayed">Paid</mat-checkbox>

        <div [hidden]="isFree()">
          <div>
            <mat-checkbox
              formControlName="subscriptionAvailable"
              matTooltip="The rewards from the subscription will be distributed according to the prompt usage"
            >
              Available for users with subscription (recommended)
            </mat-checkbox>
          </div>
          <div>

            <div class="mat-badge-small">
              <h3>
                <mat-icon>help</mat-icon>
                Fix price
              </h3>
              <span>
              Fix price is disabled for this moment.
              <a target="_blank" href="https://forms.gle/frKqLYq7wJwCgyxc6">Please let us know if you're interested in this feature.
              </a>
            </span></div>
            <!--            <mat-checkbox-->
            <!--              [disabled]="true"-->
            <!--              formControlName="fixedPriceAvailable"-->
            <!--              matTooltip="The user will pay a fixed price to get access to this prompt."-->
            <!--            >-->
            <!--              Fixed price-->
            <!--            </mat-checkbox>-->
          </div>

          <div [hidden]="fixPriceIsNotAvailable()">
            <mat-form-field>
              <mat-label>Enter a price for the prompt (in $)</mat-label>
              <input matInput type="number" formControlName="fixedPrice"/>
            </mat-form-field>
          </div>
        </div>

        <div>
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="disablePublishButton || !this.publishSettingsForm.valid"
            (click)="publishPrompt()"
          >
            Publish
          </button>
        </div>
      </form>
      <div>
        <button
          type="button"
          mat-raised-button
          [disabled]="!modelConfigurationForm.valid"
          (click)="stepper.previous()"
        >
          Previous
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<app-not-found
  *ngIf="notFound"
  message="The AI prompt '{{ getPromptId() }}' you are looking for does not exist."
>
</app-not-found>
<mat-spinner *ngIf="isLoading"></mat-spinner>
