export const aiPrompts = "ai-prompts";
export const aiPromptResponses = "ai-prompt-responses";
export const apiUsage = "api-usage";
export const activePayments = "active-payments";
export const chats = "chats";
export const chats_messages = "messages";
export const promptSuccessPayments = "prompt-success-payments";
export const ongoingPayouts = "ongoing-payouts";
export const users = "users";
export const users_notifications = "notifications";
export const users_legal = "legal";
export const users_legal_versionsDoc = "accepted-versions";
export const users_credits = "credits";
export const users_credits_usageDoc = "usage";
export const stripeProducts = "stripe-products";
export const users_checkoutSessions = "checkout_sessions";
export const users_subscriptions = "subscriptions";
export const users_linkedAccounts = "linked-accounts";
export const users_publicData = "public";
export const users_publicData_infoDoc = "info";
