import {Component, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {UiModule} from "../../ui/ui.module";
import {PagesModule} from "../pages.module";
import {AsyncPipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {AiPromptService} from "../../../services/ai-prompt.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {AlertService} from "../../../services/alert.service";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {AiPrompt} from "../../../../../.common";

@Component({
  selector: "app-main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
  standalone: true,
  imports: [
    UiModule,
    PagesModule,
    NgForOf,
    NgIf,
    MatProgressSpinnerModule,
    AsyncPipe,
    MatGridListModule,
    NgOptimizedImage,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class MainPageComponent implements OnInit {
  promptsLoaded = false;
  promptsPage: Promise<AiPrompt[]>;
  pageSize = 4;
  email: FormControl = new FormControl("", [Validators.required, Validators.email]);

  constructor(
    private aiPromptService: AiPromptService,
    private titleService: Title,
    private afs: AngularFirestore,
    private alertService: AlertService,
    private analytics: AngularFireAnalytics,
  ) {
    this.promptsPage = this.aiPromptService.listPrompts(this.pageSize, undefined);
    this.titleService.setTitle("Symbiot AI");
  }

  async ngOnInit() {
    this.promptsLoaded = true;
  }

  subscribe() {
    if (!this.email.valid) {
      return;
    }
    this.afs.collection("email-subscriptions").add({
      email: this.email.value,
    });
    this.email.reset();
    this.alertService.success("Thanks for subscribing!", "We'll be in touch soon.");
    return this.analytics.logEvent("email-subscribe");
  }
}
