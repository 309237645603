import {Component, OnInit, signal} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../../auth/auth.service";
import {combineLatest, takeUntil} from "rxjs";
import {Destroyer} from "../../../_helpers/Destroyer";
import {UserService} from "../../../../services/user.service";
import {Title} from "@angular/platform-browser";
import {PublicUserInfo, SymbiotUser} from "../../../../../../.common";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent extends Destroyer implements OnInit {
  readonly userData = signal(undefined as SymbiotUser | undefined);
  readonly publicUserData = signal(undefined as PublicUserInfo | undefined);
  userId?: string;
  isMyPage = false;
  notFound = false;
  loading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private titleService: Title,
  ) {
    super();
    this.titleService.setTitle("Account - Symbiot AI");
  }

  ngOnInit() {
    combineLatest([this.activatedRoute.params, this.authService.user$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([params, user]) => {
        if (!params) return;
        this.loading = true;
        this.userId = params["accountId"];
        if (!this.userId) throw new Error("No accountId in address");
        this.isMyPage = !!user && params["accountId"] === user.id;
        if (this.isMyPage) {
          this.userData.set(user);
          this.notFound = false;
        }

        this.userService
          .getPublicUserData(this.userId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((publicUserInfo) => {
            this.publicUserData.set(publicUserInfo);
            this.notFound = !publicUserInfo;
            this.loading = false;
            this.titleService.setTitle(`${publicUserInfo?.displayName} - Symbiot AI`);
          });
      });
  }
}
