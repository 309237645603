<a
  *ngIf="link"
  routerLink="{{ link }}"
  routerLinkActive="is-active"
  [routerLinkActiveOptions]="{exact: link === '/'}"
  class="menu-item"
  aria-label="icon-button with menu icon"
>
  <div>
    <mat-icon *ngIf="icon" class="material-symbols-outlined">{{ icon }}</mat-icon>
    <span class="icon-label" *ngIf="label">{{ label }}</span>
    <div class="active-line"></div>
  </div>
</a>
<ng-content></ng-content>
