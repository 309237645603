import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UsageComponent} from "./usage/usage.component";
import {AccountComponent} from "./account/account.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {UiModule} from "../../ui/ui.module";
import {SharedModule} from "../../../shared/shared.module";
import {ProfileIconComponent} from "../../ui/header/profile-icon/profile-icon.component";

@NgModule({
  declarations: [UsageComponent, AccountComponent],
  imports: [CommonModule, MatProgressSpinnerModule, UiModule, SharedModule, ProfileIconComponent],
})
export class ProfileModule {}
