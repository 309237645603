<p *ngIf="promptsListMode === 'public' && promptsLoaded && !isPromptPresent()">
  You can find here templates, created by people and get answer from artificial intelligence.
</p>
<p *ngIf="promptsListMode === 'my' && promptsLoaded && !isPromptPresent()">
  You don't have any prompts yet. You can create new one by clicking on "Create new prompt" button.
</p>

<div *ngIf="promptsLoaded || isPromptPresent()" class="prompts-list">
  <ui-prompt-market-grid *ngFor="let prompt of prompts" [prompt]="prompt"></ui-prompt-market-grid>
</div>
<div class="loader">
  <mat-progress-bar *ngIf="!promptsLoaded" mode="indeterminate"></mat-progress-bar>
</div>
