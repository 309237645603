<div class="intro">
  <div class="hero-image">
    <img src="/assets/img/landing/logo1.png" alt=""/>
  </div>
  <div class="hero-text">
    <div class="headlineLargeNeutral80 head">Symbiot AI</div>
    <p class="bodyLargeNeutral70 text">
      Welcome to SymbiotAI, a revolutionary platform designed for teams to blend the power of artificial intelligence
      with collaborative creativity. Develop and explore endless AI possibilities in an environment suitable tech pros
      and amateurs alike.
    </p>
    <div class="buttons">
      <a routerLink="/chat" color="primary" mat-flat-button data-cy="get-started-button">
        <span class="buttonMediumPrimary10">
          Get started
        </span>
      </a>
    </div>
  </div>

</div>

<div class="supported-models-wrapper container">
  <div class="supported-models colorNeutral70">
    <div class="all-models">
      <div class="model">
        <img src="/assets/img/ai-api/openai.svg" alt="GPT3"/>
        <span class="headlineSmall">GPT3</span>
      </div>
      <div class="model">
        <img src="/assets/img/ai-api/google-palm.png" alt="Google PaLM"/>
        <span class="headlineSmall">Google PaLM</span>
      </div>
      <div class="model">
        <img src="/assets/img/ai-api/openai.svg" alt="Dall-E"/>
        <span class="headlineSmall">Dall-E</span>
      </div>
      <div class="model">
        <img src="/assets/img/ai-api/cohere.png" alt="Co:here"/>
        <span class="headlineSmall">Co:here</span>
      </div>
      <div class="model">
        <img src="/assets/img/ai-api/openai.svg" alt="GPT4"/>
        <span class="headlineSmall">GPT4</span>
      </div>
      <div class="model opacity30">
        <img src="/assets/img/ai-api/midjourney.svg" alt="midjourney"/>
        <span class="headlineSmall">Midjourney</span>
      </div>
      <div class="model opacity30">
        <img src="/assets/img/ai-api/leonardo.png" alt="Leonardo"/>
        <span class="headlineSmall">Leonardo</span>
      </div>
    </div>
  </div>
  <div class="many-more bodyLarge colorNeutral60">and many more upcoming soon</div>
</div>

<div class="community container">
  <div class="text-info">
    <div class="headlineMediumNeutral80">AI models in one simple interface</div>
    <div class="titleLarge colorNeutral70">
      SymbiotAI offers a user-friendly chat providing unlimited communication with various AI models. Engage with
      diverse advanced AI in real-time, all facilitated by an easy-to-navigate system.
    </div>
    <a
      routerLink="/chat"
      class="go-to-chat"
      mat-flat-button
      color="primary">
      <mat-icon class="material-symbols-outlined">forum</mat-icon>
      Start generating
    </a>
  </div>
  <div class="chat-interface">
    <img src="/assets/img/landing/chat-interface.png" alt="chat interface"/>
  </div>
</div>


<mat-card class="chat-preview container">
  <div class="text-info">
    <div class="headlineMediumNeutral80">Features expanding AI functionality</div>
    <div class="items">
      <div class="item">
        <img class="image" src="assets/img/landing/icons/chat-preview/1.svg" alt=""/>
        <div class="text">
          <div class="titleMedium colorNeutral70">Numerous AI models in one simple interface</div>
          <p class="bodyMedium colorNeutral60">
            Say goodbye to the hassle of searching for AI models on market and
            registering on multiple
            websites.
          </p>
        </div>
      </div>
      <div class="item">
        <img class="image" src="assets/img/landing/icons/chat-preview/2.svg" alt=""/>
        <div class="text">
          <div class="titleMedium colorNeutral70">Stop learning — start working</div>
          <p class="bodyMedium colorNeutral60">
            Stop reading manuals to understand how to interact with different models. SymbiotAI allows you to start
            working immediately by making AI instruments easy to use.
          </p>
        </div>
      </div>
      <div class="item">
        <img class="image" src="assets/img/landing/icons/chat-preview/3.svg" alt=""/>
        <div class="text">
          <div class="titleMedium colorNeutral70">Switch between different AI models in one click</div>
          <p class="bodyMedium colorNeutral60">
            Say goodbye to the hassle of searching for AI models on market and
            registering on multiple
            websites.
          </p>
        </div>
      </div>
      <div class="item">
        <img class="image" src="assets/img/landing/icons/chat-preview/4.svg" alt=""/>
        <div class="text">
          <div class="titleMedium colorNeutral70">Set up the AI context for your needs</div>
          <p class="bodyMedium colorNeutral60">
            SymbiotAI eliminates the need for copying extensive texts to ensure that AI models understand your desired
            context.
          </p>
        </div>
      </div>
    </div>
    <a
      routerLink="/chat"
      class="go-to-chat"
      mat-flat-button
      color="primary">
      <mat-icon class="material-symbols-outlined">forum</mat-icon>
      Try now
    </a>
  </div>
  <div class="chat-interface">
    <img src="/assets/img/landing/chat-preview.png" alt="chat interface"/>
  </div>
</mat-card>

<!--<div *ngIf="promptsPage | async as promptsPage; else loading " class="prompts-list">-->
<!--  <ui-prompt-market-grid *ngFor="let prompt of promptsPage" [prompt]="prompt"></ui-prompt-market-grid>-->
<!--</div>-->


<div class="discord-info container">
  <img src="assets/img/landing/icons/discord.svg" width="72" height="72" alt=""/>
  <div class="discord-info-title">Symbiot AI</div>
  <p class="bodyLargeNeutral70 description">
    Join our Discord server to stay in touch with the best engineers and digital artists. Improve your skills and start
    making money from your creativity. Collaborate as a team working on prompts or form a company using prompts to
    increase your profits.
  </p>
  <a class="bodyMediumNeutral70" href="https://discord.gg/asBXSWywyf" mat-stroked-button>Join</a>
</div>

<div class="pricing-block">
  <div class="subscriptions-title">Ready to get started?</div>
  <div class="subscriptions-description">Find the best plan according to your needs with no transaction fees and hidden costs</div>
  <pricing-component class="subscriptions-list"></pricing-component>
</div>

<div class="keep-in-touch container">
  <mat-card class="shadow-elevation-light-upside-down">
    <div class="info">
      <div class="headlineLarge colorNeutral80">Let's keep in touch!</div>
      <div class="bodyLarge colorNeutral70">
        At SymbiotAI, we are continuously enhancing our services. Stay updated with the latest advancements by
        subscribing to our updates. Don't worry - we are committed to a spam-free communication. Your inbox will only
        receive meaningful updates pertaining to our platform.
      </div>
    </div>
    <div class="email">
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-label>Enter your email</mat-label>
        <input matInput type="email" [formControl]="email" required>
        <mat-error>Please enter a valid email</mat-error>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="subscribe()">Subscribe</button>
    </div>
  </mat-card>
</div>
<footer class="bgPrimary80">
  <div class="headlineLarge text-center">Coming soon</div>
  <div class="features-list container">
    <div class="feature-list-item">
      <img src="assets/img/landing/icons/chat-icon-white.svg" width="72" height="64" alt=""/>
      <h2 class="headlineMedium">Unified Chat Interface</h2>
      <p class="bodyLarge colorPrimary20">
        Interact seamlessly with multiple AI models, prompts, and people all in one place. A revolutionary chat
        interface
        that bridges the gap between humans and artificial intelligence.
      </p>
      <a class="titleMedium colorTransparentWhite100" routerLink="/chat">Open AI chat</a>
    </div>
    <div class="feature-list-item">
      <img src="assets/img/landing/icons/monetization-icon-white.svg" width="72" height="63" alt=""/>
      <h2 class="headlineMedium">Skills Monetization</h2>
      <p class="bodyLarge colorPrimary20">
        Are you a prompt engineer or a digital artist? Get rewarded for your creativity! We are working on a
        profit-sharing program that will compensate you for the executions of your prompts. Stay tuned!
      </p>
      <a class="titleMedium colorTransparentWhite100" href="https://forms.gle/ixtgRzWYvT77qMPYA" target="_blank">Subscribe
        for
        updates</a>
    </div>
    <div class="feature-list-item">
      <img src="assets/img/landing/icons/collaborative-icon-white.svg" width="74" height="63" alt=""/>
      <h2 class="headlineMedium">Collaborative Tools</h2>
      <p class="bodyLarge colorPrimary20">
        Enhance productivity with our upcoming feature that allows teams and companies to collaborate on prompts and
        chats
        in a restricted access environment. Let us know if you're interested!
      </p>
      <a class="titleMedium colorTransparentWhite100" href="https://forms.gle/ixtgRzWYvT77qMPYA" target="_blank">Express
        your
        interest</a>
    </div>
  </div>
  <a mat-flat-button href="https://forms.gle/ixtgRzWYvT77qMPYA">Ask for a new feature</a>
</footer>
