import {AiApi, AiModel, AiParameter} from "./types";
import {AiContentType, ApiType} from "../";
import {ModelCategoryTextGeneration} from "./model-category";

const paramTemperature: AiParameter = {
  key: "temperature",
  displayName: "Temperature",
  description:
    "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperature results in more random completions.",
  type: "range",
  limits: {
    min: 0,
    max: 2,
    step: 0.1,
  },
  defaultValue: 0.9,
  displayIcon: "device_thermostat",
};

const paramMaxTokens: AiParameter = {
  key: "max_tokens",
  displayName: "Maximum tokens",
  description:
    "The maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by model. (One token is roughly 4 characters for standard English text)",
  type: "range",
  limits: {
    min: 1,
    max: 4097,
    step: 1,
  },
  defaultValue: 1500,
  displayIcon: "short_text",
};

export class Cohere implements AiApi {
  contentType: AiContentType = "text";
  id: ApiType = "cohere";
  name = "Co:here";
  description =
    "Cohere is the leading AI platform for enterprise. It is a world-class AI that uniquely suited to the needs of business.";
  models: AiModel[] = [
    {
      id: "generate",
      displayName: "Cohere-Generate",
      description:
        "Generate produces unique content for emails, landing pages, product descriptions, and more.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 0.001, "1K-output": 0.002},
      parameters: [paramTemperature, paramMaxTokens],
      contentType: "text",
    },
    // {id: "embed"},
    // {id: "classify"},
  ];
  readonly defaultModelId: string = "generate";

  getModel(modelId: string): AiModel {
    return this.models.find((model) => model.id === modelId) as AiModel;
  }
}
