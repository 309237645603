import {ErrorHandler, Inject, Injectable, Injector, NgZone} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject, distinct, filter, tap} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  errors$ = new BehaviorSubject<Error | undefined>(undefined);
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private zone: NgZone,
  ) {
    this.errors$
      .pipe(
        filter((error) => !!error),
        distinct((error) => error?.message),
        debounceTime(1000),
        tap((error) => {
          console.error("Error from global error handler", error);
          this.toastrService.error(error?.message || "Undefined client error", "Error", {
            disableTimeOut: true,
          });
        }),
      )
      .subscribe();
  }

  handleError(error: any) {
    this.errors$.next(error);
  }

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }
}
