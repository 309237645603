import {createActionGroup, emptyProps} from "@ngrx/store";
import {CookiePreferences} from "./legal.state";
import {SymbiotUserLegalSettings} from "../../../../../../../.common";

export const LegalActions = createActionGroup({
  source: "Legal",
  events: {
    loadCookiesPreferences: emptyProps(),
    setCookiesPreferences: (cookiePreferences: CookiePreferences | undefined) => ({
      cookiePreferences,
    }),
    setLegalSettings: (legalSettings: SymbiotUserLegalSettings | undefined) => ({legalSettings}),
    saveCookiePreferences: (cookiePreferences: CookiePreferences) => ({cookiePreferences}),
    showAcceptCookiesDialog: emptyProps(),
    hideAcceptCookiesDialog: emptyProps(),
    showAcceptTermsDialog: emptyProps(),
    hideAcceptTermsDialog: emptyProps(),
  },
});
