import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ApiResponseResultsComponent} from "./api-response-results/api-response-results.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MarkdownModule} from "ngx-markdown";
import {MatIconModule} from "@angular/material/icon";
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
  declarations: [ApiResponseResultsComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MarkdownModule.forRoot(),
    MatIconModule,
    MatExpansionModule,
  ],
  exports: [ApiResponseResultsComponent],
})
export class PromptCommonModule {}
