<div class="bottom-icons bgPrimary80">
  <div class="icon textColor like" matTooltip="Like this prompt. Not implemented yet.">
    <mat-icon> favorite_outline </mat-icon>
    <span class="count">∞</span>
  </div>
  <div class="icon textColor views" matTooltip="Views count. Not implemented yet.">
    <mat-icon class="material-icons-outlined"> visibility_outline </mat-icon>
    <span class="count">∞</span>
  </div>
  <div class="icon textColor plays" matTooltip="Execute count. Not implemented yet.">
    <mat-icon fontSet="material-icons-outlined"> play_arrow_outline </mat-icon>
    <span class="count">∞</span>
  </div>
</div>
