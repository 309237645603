export type PaymentProvider = "stripe" | "paypal" | "apple-pay" | "google-pay";

export interface PaymentDetails {
  userId: string;
  promptId: string;
  price: number;
  currency: string;
  createdAt: Date;
  paymentProvider: PaymentProvider;
  status: "pending" | "success" | "failed";
}

export function getPaymentDetailId(userId: string, promptId: string) {
  return `${userId}:${promptId}`;
}
