import {Component, inject, OnInit} from "@angular/core";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {AuthService} from "../auth.service";
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-finish-email-auth",
  templateUrl: "./finish-email-auth.component.html",
  styleUrls: ["./finish-email-auth.component.scss"],
  imports: [MatProgressSpinnerModule],
  standalone: true,
})
export class FinishEmailAuthComponent implements OnInit {
  #auth = inject(AuthService);
  #userService = inject(UserService);
  #router = inject(Router);

  async ngOnInit() {
    const redirectPath = await this.#auth.emailAuthFinish();
    const timeout = setTimeout(() => {
      this.#router.navigate([redirectPath]);
    }, 5000);
    this.#userService.getPublicUserData(this.#auth.requireUserId()).subscribe((user) => {
      if (user?.displayName) {
        this.#router.navigate([redirectPath]);
        clearTimeout(timeout);
      }
    });
  }
}
