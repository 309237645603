import {AiApi, AiModel} from "./types";
import {AiContentType, ApiType} from "../";

import {maxContextLength} from "./model-parameters";
import {ModelCategoryImageCaptioning, ModelCategoryTextGeneration} from "./model-category";

export class GooglePalm implements AiApi {
  id: ApiType = "google-palm";
  contentType: AiContentType = "per-model";
  name = "Google Palm";

  description =
    "Google Palm is a chatbot that can talk about anything you want. It is available through the Chat Completions API.";
  models: AiModel[] = [
    {
      id: "gemini-pro",
      displayName: "Gemini Pro",
      description:
        "The Gemini Pro model is a Google's LLM that excels at language understanding, language generation, and conversations. This model is fine-tuned to conduct natural multi-turn conversations, and is ideal for text tasks about code that require back-and-forth interactions.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 0.00025, image: 0.0025, "1K-output": 0.0005},
      contentType: {
        input: ["text"],
        output: ["text"],
      },
      icon: "google-gemini.png",
      parameters: [
        {
          key: "prompt",
          displayName: "Prompt",
          type: "text",
          mapTo: "prompt",
          required: true,
        },
        {
          key: "temperature",
          displayName: "Temperature",
          description:
            "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperature results in more random completions.",
          type: "range",
          limits: {
            min: 0,
            max: 1,
            step: 0.1,
          },
          defaultValue: 0,
          required: false,
          displayIcon: "device_thermostat",
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 30720,
          },
          defaultValue: 30720,
        },
        {
          key: "maxOutputTokens",
          displayName: "Maximum output tokens",
          description:
            "Maximum number of tokens that can be generated in the response. A token is approximately four characters. 100 tokens correspond to roughly 60-80 words.\n" +
            "Specify a lower value for shorter responses and a higher value for longer responses.",
          type: "range",
          limits: {
            min: 1,
            max: 2048,
            step: 1,
          },
          required: false,
          defaultValue: 2048,
          displayIcon: "short_text",
        },
      ],
    },
    {
      id: "chat-bison",
      displayName: "Chat Bison",
      description:
        "The chat-bison foundation model is a large language model (LLM) that excels at language understanding, language generation, and conversations. This chat model is fine-tuned to conduct natural multi-turn conversations, and is ideal for text tasks about code that require back-and-forth interactions.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K": 0.0005},
      contentType: "chat",
      parameters: [
        {
          key: "temperature",
          displayName: "Temperature",
          description:
            "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperature results in more random completions.",
          type: "range",
          limits: {
            min: 0,
            max: 1,
            step: 0.1,
          },
          defaultValue: 0,
          required: false,
          displayIcon: "device_thermostat",
        },
        {
          ...maxContextLength,
          limits: {
            step: 10,
            min: 1,
            max: 8192,
          },
          defaultValue: 8192,
        },
        {
          key: "maxOutputTokens",
          displayName: "Maximum output tokens",
          description:
            "Maximum number of tokens that can be generated in the response. A token is approximately four characters. 100 tokens correspond to roughly 60-80 words.\n" +
            "Specify a lower value for shorter responses and a higher value for longer responses.",
          type: "range",
          limits: {
            min: 1,
            max: 1024,
            step: 1,
          },
          required: false,
          defaultValue: 1500,
          displayIcon: "short_text",
        },
      ],
    },
    {
      id: "imagetext",
      displayName: "Image captioning",
      description: "The model that supports image captioning as well as question and answering.",
      categories: [ModelCategoryImageCaptioning],
      prices: {image: 0.0015},
      contentType: {
        input: ["image", "text"],
        output: ["text"],
      },
      parameters: [
        {
          key: "prompt",
          displayName: "Prompt",
          description: "The question you want to get answered about your image.",
          type: "text",
          mapTo: "prompt",
          limits: {
            min: 0,
            max: 80,
          },
          defaultValue: "",
        },
        {
          key: "image",
          displayName: "Image",
          description: "The image you want to get captions for.",
          type: "image",
          mapTo: "image",
          limits: {
            min: 1,
            max: 1,
          },
          required: true,
        },
      ],
    },
  ];
  readonly defaultModelId: string = this.models[0].id;

  getModel(modelId: string): AiModel {
    return this.models.find((model) => model.id === modelId) as AiModel;
  }

  readonly parameters = this.models[0].parameters!;
}

export type GooglePalmParameterKeys = "temperature" | "maxOutputTokens";
export type GoogleVertexImageCaptioningKeys = "prompt" | "image" | "storagePath" | "sampleCount";
