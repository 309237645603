import {inject, Injectable} from "@angular/core";
import {firstValueFrom, Observable} from "rxjs";
import {UserCollectionProvider} from "../../../.common/collections/user-collection-provider";
import {PublicUserInfo, SymbiotUser, SymbiotUserLegalSettings} from "../../../.common";
import {docRefToObservable} from "../../../.common/firestore/observable";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userCollectionProvider = inject(UserCollectionProvider);

  getPublicUserData(userId: string): Observable<PublicUserInfo | undefined> {
    return docRefToObservable(this.userCollectionProvider.getUserPublicDataDocRef(userId));
  }

  async isUserExists(userId: string): Promise<boolean> {
    return !!(await firstValueFrom(this.getPublicUserData(userId)));
  }

  updateLegalSettings(user: SymbiotUser, legalSettings: SymbiotUserLegalSettings) {
    return this.userCollectionProvider.getUserLegalSettingsDoc(user.id).set(legalSettings);
  }

  getLegalSettings(userId: string): Observable<SymbiotUserLegalSettings | undefined> {
    return docRefToObservable(this.userCollectionProvider.getUserLegalSettingsDoc(userId));
  }
}
