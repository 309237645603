import {Routes} from "@angular/router";
import {MainPageComponent} from "../components/pages/main-page/main-page.component";
import {MainLayoutComponent} from "../components/layouts/main-layout/main-layout.component";
import {ListPromptsPageComponent} from "../components/pages/prompts/list-prompts-page/list-prompts-page.component";
import {CreatePromptPageComponent} from "../components/pages/prompts/create-prompt-page/create-prompt-page.component";
import {SinglePromptPageComponent} from "../components/pages/prompts/single-prompt-page/single-prompt-page.component";
import {AngularFireAuthGuard} from "@angular/fire/compat/auth-guard";
import {FinishEmailAuthComponent} from "../components/auth/finish-email-auth/finish-email-auth.component";
import {TermsComponent} from "../components/pages/system/legal/components/docs/terms/terms.component";
import {NotFoundComponent} from "../components/ui/not-found/not-found.component";
import {UsageComponent} from "../components/pages/profile/usage/usage.component";
import {AccountComponent} from "../components/pages/profile/account/account.component";
import {LinkedAccountComponent} from "../components/pages/finances/earnings/payouts/stripe/linked-account/linked-account.component";
import {SubscriptionSelectPageComponent} from "../components/pages/subscription/subscription-select-page/subscription-select-page.component";
import {EarningsComponent} from "../components/pages/finances/earnings/earnings.component";
import {LandingLayoutComponent} from "../components/layouts/landing-layout/landing-layout.component";
import {PricingPageComponent} from "../components/pages/subscription/pricing-page/pricing-page.component";
import {SideNavbarHeaderComponent} from "../components/ui/side-navbar-header/side-navbar-header.component";
import {PrivacyComponent} from "../components/pages/system/legal/components/docs/privacy/privacy.component";
import {AcceptableUsePolicyComponent} from "../components/pages/system/legal/components/docs/acceptable-use-policy/acceptable-use-policy.component";
import {CookiesPolicyComponent} from "../components/pages/system/legal/components/docs/cookies-policy/cookies-policy.component";

export const routes: Routes = [
  {
    path: "",
    component: LandingLayoutComponent,
    children: [{path: "", component: MainPageComponent}],
  },
  {
    path: "prompts",
    component: MainLayoutComponent,
    children: [
      {path: "", redirectTo: "catalog", pathMatch: "full"},
      {
        path: "catalog",
        component: ListPromptsPageComponent,
        data: {
          menuPoints: [{label: "All", link: "/prompts/catalog", icon: "filter_alt_off"}],
          displaySideNav: true,
        },
      },
      {
        path: "create",
        component: CreatePromptPageComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
          menuPoints: [{label: "Create", link: "/prompts/create", icon: "create_new_folder"}],
          displaySideNav: true,
        },
      },
      {
        path: "my",
        component: ListPromptsPageComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
          menuPoints: [{label: "Created by me", link: "/prompts/my", icon: "handyman"}],
          displaySideNav: true,
        },
      },
      {
        path: ":promptId",
        component: SinglePromptPageComponent,
        data: {
          menuPoints: [{label: "Prompt", link: "{self}", icon: "label"}],
          displaySideNav: true,
        },
      },
      {
        path: ":promptId/edit",
        component: CreatePromptPageComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
          menuPoints: [{label: "Edit", link: "{self}", icon: "label"}],
          displaySideNav: true,
        },
      },
    ],
  },
  {
    path: "p",
    component: MainLayoutComponent,
    children: [
      {
        path: ":accountId",
        component: AccountComponent,
        data: {
          menuPoints: [
            {label: "Profile", link: "{myProfileUrl}", icon: "person"},
            {label: "Subscriptions", link: "/subscription/select", icon: "currency_exchange"},
          ],
          displaySideNav: true,
        },
      },
    ],
  },
  {
    path: "profile",
    component: MainLayoutComponent,
    children: [
      // @deprecated
      {
        path: "a/:accountId",
        component: AccountComponent,
        data: {
          menuPoints: [
            {label: "Profile", link: "{myProfileUrl}", icon: "person"},
            {label: "Subscriptions", link: "/subscription/select", icon: "currency_exchange"},
          ],
          displaySideNav: true,
        },
      },
      {
        path: "usage",
        component: UsageComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
          menuPoints: [{label: "Usage", link: "/profile/usage", icon: "data_usage"}],
          displaySideNav: true,
        },
      },
      {
        path: "linked-accounts",
        component: LinkedAccountComponent,
        canActivate: [AngularFireAuthGuard],
      },
    ],
  },
  {
    path: "subscription",
    component: MainLayoutComponent,
    children: [
      {
        path: "select",
        component: SubscriptionSelectPageComponent,
        canActivate: [AngularFireAuthGuard],
        data: {
          menuPoints: [
            {label: "Profile", link: `{myProfileUrl}`, icon: "person"},
            {label: "Subscriptions", link: "/subscription/select", icon: "currency_exchange"},
          ],
          displaySideNav: true,
        },
      },
    ],
  },

  {
    path: "pricing",
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        component: PricingPageComponent,
        data: {
          displaySideNav: false,
        },
      },
    ],
  },
  {
    path: "chat/:chatId",
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import(
            "../components/pages/chat/components/chat-content-header/chat-content-header.component"
          ).then((m) => m.ChatContentHeaderComponent),
        outlet: "content-header",
      },
      {
        path: "",
        loadComponent: () =>
          import("../components/pages/chat/components/chat-controls/chat-controls.component").then(
            (m) => m.ChatControlsComponent,
          ),
        outlet: "content-footer",
      },

      {
        path: "",
        loadComponent: () =>
          import("../components/pages/chat/components/chat-page/chat-page.component").then(
            (m) => m.ChatPageComponent,
          ),
        data: {
          displaySideNav: true,
          displayFooterMenu: false,
          chatEnabled: true,
        },
      },
      {
        path: "",
        outlet: "side-nav",
        loadComponent: () =>
          import("../components/ui/chat-navbar/chat-navbar.component").then(
            (m) => m.ChatNavbarComponent,
          ),
      },
      {
        path: "",
        outlet: "side-nav-header-mobile",
        component: SideNavbarHeaderComponent,
        data: {
          displaySideNav: true,
          chatEnabled: true,
        },
      },
      {
        path: "",
        outlet: "side-nav-header-desktop",
        component: SideNavbarHeaderComponent,
        data: {
          displaySideNav: true,
          chatEnabled: true,
        },
      },
    ],
  },
  {
    path: "chat",
    component: MainLayoutComponent,
    children: [
      // {
      //   path: "",
      //   outlet: "content-mobile",
      //   loadComponent: () =>
      //     import("../components/ui/chat-navbar/chat-navbar.component").then(
      //       (m) => m.ChatNavbarComponent,
      //     ),
      //   data: {
      //     displaySideNav: true,
      //     chatEnabled: true,
      //   },
      // },
      {
        path: "",
        outlet: "content-mobile",
        loadComponent: () =>
          import(
            "../components/pages/chat/components/chat-mobile-content/chat-mobile-content.component"
          ).then((m) => m.ChatMobileContentComponent),
        data: {
          displaySideNav: true,
          chatEnabled: true,
        },
      },
      {
        path: "",
        outlet: "side-nav",
        loadComponent: () =>
          import("../components/ui/chat-navbar/chat-navbar.component").then(
            (m) => m.ChatNavbarComponent,
          ),
        data: {
          displaySideNav: true,
        },
      },
      {
        path: "",
        outlet: "content-desktop",
        loadComponent: () =>
          import(
            "../components/pages/chat/components/chat-page-default/chat-page-default.component"
          ).then((m) => m.ChatPageDefaultComponent),
        data: {
          displaySideNav: true,
          chatEnabled: true,
        },
      },
      {
        path: "",
        outlet: "side-nav-header-desktop",
        component: SideNavbarHeaderComponent,
        data: {
          displaySideNav: true,
          chatEnabled: true,
        },
      },
      {
        path: "",
        outlet: "side-nav-header-mobile",
        component: SideNavbarHeaderComponent,
        data: {
          displaySideNav: true,
          chatEnabled: true,
        },
      },
    ],
  },
  {
    path: "finances",
    component: MainLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    children: [
      {
        path: "",
        redirectTo: "earnings",
        pathMatch: "full",
      },
      {
        path: "earnings",
        component: EarningsComponent,
      },
    ],
  },
  {path: "finishEmailSignIn", component: FinishEmailAuthComponent},
  {path: "terms", component: TermsComponent, data: {termsOff: true}},
  {path: "privacy", component: PrivacyComponent, data: {termsOff: true}},
  {path: "acceptable-use-policy", component: AcceptableUsePolicyComponent, data: {termsOff: true}},
  {path: "cookies-policy", component: CookiesPolicyComponent, data: {termsOff: true}},
  {
    path: "**",
    component: MainLayoutComponent,
    children: [{path: "", component: NotFoundComponent}],
  },
];
