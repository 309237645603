import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private toastrService: ToastrService) {}

  public success(message: string, title?: string) {
    this.toastrService.success(message, title, {});
  }

  public warning(message: string, title?: string) {
    this.toastrService.warning(message, title, {
      disableTimeOut: true,
    });
  }

  public error(message: string | Error, title?: string) {
    if (message instanceof Error) {
      console.error(message);
      message = message.message;
    }
    this.toastrService.error(message, title, {
      disableTimeOut: true,
    });
  }
}
