import {inject, Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {Credits} from "../../../../../.common/credits/credits";
import {CreditsDatabase} from "../../../../../.common/credits/credits.database";
import {UserCollectionProvider} from "../../../../../.common/collections/user-collection-provider";
import {docRefToObservable} from "../../../../../.common/firestore/observable";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CreditsWebDb implements CreditsDatabase {
  #userCollectionProvider = inject(UserCollectionProvider);

  getCredits(userId: string): Observable<Credits | undefined> {
    return docRefToObservable(this.#userCollectionProvider.getUserCreditsUsageDoc(userId)).pipe(
      catchError(() => of(undefined)),
    );
  }

  async updateCredits(userId: string, credits: Credits) {
    await this.#userCollectionProvider.getUserCreditsUsageDoc(userId).set(credits);
  }
}
