<div *ngIf="loggedIn">
  <div *ngIf="credits$ | async as credits; else loading">
    <div class="credits-block" *ngIf="sidenavBarService.open">
      <div class="titleSmall">Credits</div>
      <div class="credits">
        <span class="titleMediumPrimary80">{{ credits.remainingCredits | roundDigits }}</span>
        <span class="titleSmallNeutral50">/{{ credits.subscriptionPlan.maxCredits }}</span>
      </div>
      <div class="used-credits">
        <mat-progress-bar mode="determinate" value="{{ percentageUsedCredits }}"></mat-progress-bar>
      </div>
    </div>
<!--    TODO: we need think, how to display the credits on mobile-->
<!--    <div class="mini-credit-block" *ngIf="!sidenavBarService.open">-->
<!--      <mat-progress-spinner-->
<!--        matTooltip="Credits {{ credits.remainingCredits | roundDigits }}/{{ credits.subscriptionPlan.maxCredits }}"-->
<!--        matTooltipPosition="right"-->
<!--        mode="determinate"-->
<!--        value="{{ percentageUsedCredits }}"-->
<!--      >-->
<!--      </mat-progress-spinner>-->
<!--    </div>-->
  </div>
</div>
<ng-template #loading>
  <mat-spinner diameter="30"></mat-spinner>
</ng-template>
