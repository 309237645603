import {Component, DestroyRef, inject, OnInit, signal, WritableSignal} from "@angular/core";
import {SubscriptionService} from "../../../../services/subscription.service";
import {filter, firstValueFrom, Observable, switchMap, tap} from "rxjs";
import {CheckoutService} from "../../../../services/checkout.service";
import {AuthService} from "../../../auth/auth.service";
import {Destroyer} from "../../../_helpers/Destroyer";
import {Title} from "@angular/platform-browser";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ApiUsageService} from "../../../../services/usage/api-usage.service";
import {
  StripePriceInterval,
  StripeProduct,
  StripeProductRole,
} from "../../../../../../.common/subscriptions/stripe-product";
import {ApiUsageEntity} from "../../../../../../.common/usage";
import {StripeUserSubscription} from "../../../../../../.common/subscriptions/stripe-user-subscription";

@Component({
  selector: "app-subscription-select-page",
  templateUrl: "./subscription-select-page.component.html",
  styleUrls: ["./subscription-select-page.component.scss"],
})
export class SubscriptionSelectPageComponent extends Destroyer implements OnInit {
  destroyRef = inject(DestroyRef);
  products$: Promise<StripeProduct[]> | undefined;
  interval: StripePriceInterval = "year";
  userSubscription: WritableSignal<StripeUserSubscription | null> = signal(null);
  subscribedProduct: WritableSignal<StripeProduct | null> = signal(null);
  private usageService = inject(ApiUsageService);
  usage$: Observable<ApiUsageEntity>;
  percentageUsedCredits = 0;
  totalCredits = 0;
  mostPopular = "Standard";
  featuresMap = {
    standard: ["100 credits per month"],
    business: [
      "1350 credits per month",
      "Full access to all prompt",
      "Private execution results",
      "Support priority",
    ],
    "business-pro": [
      "4500 credits per month",
      "Full access to all prompt",
      "Private execution results",
      "Personal support manager",
      "Personal consulting",
    ],
  };
  loading = false;
  loggedIn = false;
  disablePlanManagement = false;
  constructor(
    private subscriptionService: SubscriptionService,
    private checkoutService: CheckoutService,
    private authService: AuthService,
    private titleService: Title,
  ) {
    super();
    this.titleService.setTitle("Subscriptions - Symbiot AI");
    this.usage$ = this.authService.user$.pipe(
      tap((user) => (this.loggedIn = !!user)),
      filter((user) => !!user),
      switchMap((user) => this.usageService.getUserUsages(user!.id)),
      tap((usage) => this.calculatePercentage(usage)),
    );
    this.usage$.pipe(takeUntilDestroyed()).subscribe();
  }

  async ngOnInit() {
    this.products$ = firstValueFrom(this.subscriptionService.getProductsWithPrices());
    this.authService.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((user) => !!user),
        switchMap((user) => this.subscriptionService.getSubscriptionsForUser(user!.id)),
        filter((subscription) => !!subscription),
        tap((subscription) => this.userSubscription.set(subscription!)),
        switchMap((subscription) =>
          this.subscriptionService.getProductById(subscription!.items[0].plan.product.id),
        ),
        filter((product) => !!product),
        tap((product) => this.subscribedProduct.set(product)),
      )
      .subscribe();
  }

  async subscribe(priceId: string) {
    if (this.loading) return;
    this.loading = true;
    await this.checkoutService.checkoutSubscription(priceId);
    this.loading = false;
  }

  getProductFeatures(role: StripeProductRole) {
    const features = this.featuresMap[role];
    if (!features) {
      console.error(`Couldn't find feature list for role: ${role}`);
      return [];
    }
    return features;
  }

  isMostPopular(product: StripeProduct) {
    return this.mostPopular === product.name;
  }

  private calculatePercentage(usage: ApiUsageEntity) {
    if (+usage.currentUsage > 0) {
      this.totalCredits = usage.currentLimit - Number(usage.currentUsage);
      this.percentageUsedCredits = (this.totalCredits / usage.currentLimit) * 100;
    }
  }
  async redirectToCustomerPortal() {
    this.disablePlanManagement = true;
    await this.subscriptionService.toCustomerPortal();
    this.disablePlanManagement = false;
  }
}
