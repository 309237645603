<!--<p>You don't have a keys for this model. If you want to test your prompt, you can do it only with your personal keys or just test your prompt manually.</p>-->
<!--<p>If you want to create GPT-3 api keys, please create it <a href="https://beta.openai.com/account/api-keys" target="_blank">here</a>(you need openai account).</p>-->

<div class="dialog">
  <p>
    You don't have a keys for this model. If you want to test your prompt, you can do it only with
    your personal keys or just test your prompt manually.
  </p>
  <p>
    If you want to create co:here api keys, please create it
    <a href="https://dashboard.cohere.ai/api-keys" target="_blank">here</a>.
  </p>
  <p>For tests it will be enough to create Trial key.</p>

  <mat-form-field appearance="fill">
    <mat-label>Api Key</mat-label>
    <input matInput [(ngModel)]="data.apiKey" />
  </mat-form-field>
  <div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button mat-button [mat-dialog-close]="data.apiKey" cdkFocusInitial>Add key</button>
  </div>
</div>
