import {Component, inject} from "@angular/core";
import {SidenavBarService} from "../../../services/sidenav-bar.service";

@Component({
  selector: "app-discord-widget",
  templateUrl: "./discord-widget.component.html",
  styleUrls: ["./discord-widget.component.scss"],
})
export class DiscordWidgetComponent {
  sidenavBarService = inject(SidenavBarService);
}
