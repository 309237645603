import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthActions} from "./auth.actions";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {switchMap, withLatestFrom} from "rxjs/operators";
import firebase from "firebase/compat";
import {SymbiotUser} from "../../../../../.common";
import {AuthService} from "../auth.service";
import {Store} from "@ngrx/store";
import {of} from "rxjs";
import {authFeature} from "./auth.state";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {UserService} from "../../../services/user.service";

@Injectable()
export class AuthEffects {
  #store = inject(Store);
  #afAuth = inject(AngularFireAuth);
  #actions$ = inject(Actions);
  #authService = inject(AuthService);
  #userService = inject(UserService);

  loadUser$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.loadUser),
      switchMap(() => this.#afAuth.authState),
      switchMap((firebaseUser) =>
        firebaseUser ? of(this.toSymbiotUser(firebaseUser)) : of(undefined),
      ),
      switchMap((user) => {
        if (!user) {
          return [AuthActions.notAuthenticated()];
        }
        return this.#userService
          .getPublicUserData(user.id)
          .pipe(
            switchMap((publicUserInfo) => [
              AuthActions.setUser(user),
              AuthActions.setPublicUserInfo(publicUserInfo),
            ]),
          );
      }),
    ),
  );

  updateLegalSettings$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.updateLegalSettings),
      withLatestFrom(this.#store.select(authFeature.selectUser)),
      switchMap(([legalSettings, user]) => {
        if (!user) {
          throw new Error("You're not logged in");
        }
        return fromPromise(this.#userService.updateLegalSettings(user, legalSettings)).pipe(
          switchMap(() => of(AuthActions.setUser({...user, ...legalSettings} as SymbiotUser))),
        );
      }),
    ),
  );

  logout$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => {
        return fromPromise(this.#authService.signOut()).pipe(
          switchMap(() => of(AuthActions.notAuthenticated())),
        );
      }),
    ),
  );

  private toSymbiotUser(firebaseUser: firebase.User): SymbiotUser {
    return {
      id: firebaseUser.uid,
      email: firebaseUser.email,
    } as SymbiotUser;
  }
}
