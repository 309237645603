import {Component, ElementRef, HostListener, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {SideNavbarComponent} from "../side-navbar/side-navbar.component";
import {SidenavBarService} from "../../../services/sidenav-bar.service";

@Component({
  selector: "ui-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  name?: string;
  @ViewChild(SideNavbarComponent, {static: false}) childComponent: SideNavbarComponent | undefined;

  constructor(
    private elementRef: ElementRef,
    public dialog: MatDialog,
    public sidenavBarService: SidenavBarService,
  ) {}

  @HostListener("window:scroll")
  onWindowScroll() {
    const header = this.elementRef.nativeElement.querySelector("mat-toolbar");
    if (!header) {
      return;
    }
    if (window.pageYOffset > 0) {
      header.classList.add("page-scrolled");
    } else {
      header.classList.remove("page-scrolled");
    }
  }
}
