import {inject, Injectable} from "@angular/core";
import {firstValueFrom, Observable} from "rxjs";
import firebase from "firebase/compat";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {StripeCreateAccountLinkParams} from "../../../functions/src/_services/stripe-service";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {CreateStripeAccountLinkResponseType} from "../../../functions/src/linked-accounts/create-stripe-account-link";
import {users, users_linkedAccounts} from "../../../.common/firestore-collections";
import {WithId} from "../../../.common/firestore/dataconverters";
import {SymbiotUser} from "../../../.common";
import {
  LinkedAccount,
  LinkedAccountStatus,
  LinkedAccountsType,
  StripeLinkedAccount,
} from "../../../.common/linked-accounts/linked-accounts";

@Injectable({
  providedIn: "root",
})
export class LinkedAccountsService {
  private usersCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  firebaseFunctions = inject(AngularFireFunctions);

  constructor(private firestore: AngularFirestore) {
    this.usersCollection = this.firestore.firestore
      .collection(users)
      .withConverter(new WithId<SymbiotUser>());
  }

  async initNewAccountCreation(userId: string) {
    await this.requestStripeOnboardingLink(userId, "init");
  }

  getLinkedAccountForType(
    userId: string,
    accountType: LinkedAccountsType,
  ): Observable<LinkedAccount | null> {
    this.validateUserId(userId);
    return new Observable<LinkedAccount | null>((subscriber) => {
      this.getLinkedAccountCollection(userId)
        .doc(accountType)
        .onSnapshot((accountDoc) => {
          const data = accountDoc.data();
          if (!accountDoc.exists || data === undefined) {
            return subscriber.next(null);
          }
          subscriber.next(data);
        });
    });
  }

  async requestStripeOnboardingLink(userId: string, status: LinkedAccountStatus) {
    this.validateUserId(userId);
    await this.getLinkedAccountCollection(userId)
      .doc("stripe")
      .set(
        {
          status: status,
        } as StripeLinkedAccount,
        {merge: true},
      );
  }

  async createStripeAccountLink(params: StripeCreateAccountLinkParams) {
    const fn = this.firebaseFunctions.httpsCallable<
      StripeCreateAccountLinkParams,
      CreateStripeAccountLinkResponseType
    >("createStripeAccountLink_v_0_1_0");
    return await firstValueFrom(fn(params));
  }

  private validateUserId(userId: string) {
    if (!userId) {
      throw new Error("User id is required");
    }
  }

  private getLinkedAccountCollection(userId: string) {
    return this.usersCollection
      .doc(userId)
      .collection(users_linkedAccounts)
      .withConverter(new WithId<LinkedAccount>());
  }
}
