import {AiApi, AiModel} from "./types";
import {AiContentType, ApiType} from "../";

import {maxContextLength} from "./model-parameters";
import {ModelCategoryTextGeneration} from "./model-category";

export class MistralApi implements AiApi {
  id: ApiType = "mistral";
  contentType: AiContentType = "per-model";
  name = "Mistral";
  description = "French company develop opensource AI models";
  models: AiModel[] = [
    {
      id: "mixtral-8x7b-32768",
      displayName: "Mixtral-8x7B",
      description:
        "The Mixtral-8x7B-instruct-v0.1 Large Language Model (LLM) is a pretrained generative Sparse Mixture of Experts tuned to be a helpful assistant.",
      categories: [ModelCategoryTextGeneration],
      prices: {"1K-input": 0.27 / 1000, "1K-output": 0.27 / 1000},
      contentType: "chat",
      parameters: [
        {
          key: "temperature",
          displayName: "Temperature",
          description:
            "Amount of randomness injected into the response.\n" +
            "\n" +
            "Defaults to 1. Ranges from 0 to 1. Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.",
          type: "range",
          limits: {
            min: 0,
            max: 1,
            step: 0.1,
          },
          defaultValue: 0.6,
          required: false,
        },
        {
          ...maxContextLength,
          limits: {
            step: 50,
            min: 1,
            max: 32768,
          },
          defaultValue: 32768,
        },
        {
          key: "max_tokens",
          displayName: "Maximum completion tokens",
          description:
            "The maximum number of tokens to generate before stopping. Note that our models may stop before reaching this maximum. This parameter only specifies the absolute maximum number of tokens to generate.",
          type: "range",
          limits: {
            min: 1,
            max: 32768,
            step: 10,
          },
          required: false,
          defaultValue: 32768,
        },
      ],
    },
  ];
  readonly defaultModelId: string = this.models[0].id;

  getModel(modelId: string): AiModel {
    return this.models.find((model) => model.id === modelId) as AiModel;
  }

  readonly parameters = this.models[0].parameters!;
}
export type MistralParameterKeys = "max_tokens";
