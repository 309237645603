import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {tap} from "rxjs";
import {NotificationActions} from "./notification.actions";
import {NotificationService} from "../notification.service";

@Injectable()
export class NotificationEffects {
  #actions$ = inject(Actions);
  #notificationService = inject(NotificationService);

  acceptAllEmails$ = createEffect(
    () =>
      this.#actions$.pipe(
        ofType(NotificationActions.acceptAllEmails),
        tap(async () => {
          await this.#notificationService.setEmailNotificationPreferences({
            feedbackAndSurveys: true,
            marketing: true,
            newsletter: true,
            personalizedRecommendations: true,
            educationalContent: true,
            eventInvitations: true,
            featureUpdates: true,
            referralProgramInfo: true,
          });
        }),
      ),
    {dispatch: false},
  );
}
