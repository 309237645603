import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RoundDigitsPipe} from "./pipes/round-hundred.pipe";
import {SortSubscriptionsPipe} from "./pipes/sort-subscriptions.pipe";

@NgModule({
  declarations: [RoundDigitsPipe, SortSubscriptionsPipe],
  exports: [RoundDigitsPipe, SortSubscriptionsPipe],
  imports: [CommonModule],
})
export class SharedModule {}
