import {Component, inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {authFeature} from "../../auth/state/auth.state";

@Component({
  selector: "app-landing-layout",
  templateUrl: "./landing-layout.component.html",
  styleUrls: ["./landing-layout.component.scss"],
})
export class LandingLayoutComponent {
  #store = inject(Store);
  userLoaded = this.#store.selectSignal(authFeature.selectLoaded);
}
