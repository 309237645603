import {Component, DestroyRef, inject, OnInit, signal, WritableSignal} from "@angular/core";
import {filter, firstValueFrom, switchMap, tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SubscriptionService} from "../../../../services/subscription.service";
import {CheckoutService} from "../../../../services/checkout.service";
import {AuthService} from "../../../auth/auth.service";
import {Title} from "@angular/platform-browser";
import {Destroyer} from "../../../_helpers/Destroyer";
import {MatDialog} from "@angular/material/dialog";
import {createSelector, Store} from "@ngrx/store";
import {authFeature} from "../../../auth/state/auth.state";
import {
  StripePriceInterval,
  StripeProduct,
  StripeProductRole,
} from "../../../../../../.common/subscriptions/stripe-product";
import {freePlanCredits} from "../../../../../../.common/credits/credits";
import {StripeUserSubscription} from "../../../../../../.common/subscriptions/stripe-user-subscription";

@Component({
  selector: "pricing-component",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent extends Destroyer implements OnInit {
  destroyRef = inject(DestroyRef);
  products$: Promise<StripeProduct[]> | undefined;
  interval: StripePriceInterval = "year";
  dialog = inject(MatDialog);
  currentSubscription: WritableSignal<StripeUserSubscription | null> = signal(null);
  mostPopular = "Standard";
  featuresMap = {
    standard: [
      "More that 6 times faster credits recharge",
      "More that 3 times more credits per session (when full credits)",
    ],
    business: [
      "1350 credits per month",
      "Full access to all prompt",
      "Private execution results",
      "Support priority",
    ],
    "business-pro": [
      "4500 credits per month",
      "Full access to all prompt",
      "Private execution results",
      "Personal support manager",
      "Personal consulting",
    ],
  };
  loading = false;

  store = inject(Store);
  vm$ = this.store.selectSignal(
    createSelector({
      user: authFeature.selectUser,
    }),
  );
  constructor(
    private subscriptionService: SubscriptionService,
    private checkoutService: CheckoutService,
    private authService: AuthService,
    private titleService: Title,
  ) {
    super();
  }

  async ngOnInit() {
    this.products$ = firstValueFrom(this.subscriptionService.getProductsWithPrices());
    this.authService.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((user) => !!user),
        switchMap((user) => this.subscriptionService.getSubscriptionsForUser(user!.id)),
        filter((subscription) => !!subscription),
        tap((subscription) => this.currentSubscription.set(subscription!)),
      )
      .subscribe();
  }

  async subscribe(priceId: string) {
    if (!this.vm$().user) {
      this.openAuthWindow();
      return;
    }
    if (this.currentSubscription() != null) return;
    if (this.loading) return;
    this.loading = true;
    await this.checkoutService.checkoutSubscription(priceId);
    this.loading = false;
  }

  getProductFeatures(role: StripeProductRole) {
    const features = this.featuresMap[role];
    if (!features) {
      console.error(`Couldn't find feature list for role: ${role}`);
      return [];
    }
    return features;
  }

  isMostPopular(product: StripeProduct) {
    return this.mostPopular === product.name;
  }

  openAuthWindow() {
    this.authService.openAuthWindow();
  }

  protected readonly window = window;

  isCurrentSubscription(product: StripeProduct) {
    const subscription = this.currentSubscription();
    return subscription != null && subscription.role === product.role;
  }

  protected readonly freePlanCredits = freePlanCredits;
}
