<ng-container *ngIf="vm$() as vm">
  <div class="subscription-container">
    <div class="interval-selector">
      <mat-button-toggle-group name="interval" aria-label="Select an interval" [(ngModel)]="interval">
        <mat-button-toggle value="month">Monthly</mat-button-toggle>
        <mat-button-toggle value="year">Annual</mat-button-toggle>
      </mat-button-toggle-group>
      <div class="discount-message titleLarge colorAccent100">Save up to 18%</div>
    </div>

    <div class="products" *ngIf="products$ | async | sortSubscriptions as products; else loadingTemplate">
      <div class="products-wrapper">
        <div class="product">
          <div class="titleLarge colorNeutral80">Free</div>
          <mat-divider></mat-divider>
          <div class="price-container">
            <div class="price bodyMedium colorPrimary80">
              <span>$</span>
              <span class="displaySmall">0</span>
              <span>/{{interval}}</span>
            </div>
            <mat-divider></mat-divider>
            <ul class="features bodySmall">
              <li>Get {{freePlanCredits.creditsPerTick}} credits every {{freePlanCredits.tickIntervalInMinutes}} minutes</li>
              <li>Up to {{freePlanCredits.maxCredits}} credits maximum</li>
              <li>OpenAI GPT3.5, GPT4</li>
              <li>Claude instant, Claude 2.1</li>
              <li>Co:here</li>
              <li>Google VertexAI Bison, Gemini</li>
              <li>OpenAI Dall-E 2, Dall-E 3</li>
              <li>All in one chat functionality</li>
              <li>Chat with multiple text models</li>
              <li>Create different style of texts</li>
              <li>Image creation</li>
              <li>Sent chat via link</li>
            </ul>
            <div class="subscribe-button">
              <a *ngIf="!vm.user" (click)="openAuthWindow()" mat-flat-button color="primary">
                Select
              </a>
              <a *ngIf="!!vm.user && !currentSubscription()" [disabled]="!! (vm.user)" mat-flat-button color="primary">
                Current plan
              </a>
            </div>
          </div>
        </div>
        <div
          matBadge="Most Popular"
          [matBadgeHidden]=!isMostPopular(product)
          matBadgePosition="above after"
          matBadgeOverlap="false"
          *ngFor="let product of products">
          <ng-container *ngIf="product.prices | async as pr; else loadingTemplate">
            <ng-container *ngFor="let price of pr">
              <div class="product" (click)="subscribe(price.id)" *ngIf="price.interval === interval">
                <div class="titleLarge colorNeutral80">{{ product.name }}</div>
                <mat-divider></mat-divider>
                <div class="price-container">
                  <div class="price bodyMedium colorPrimary80 text-center">
                    <span class="bodyMedium">$</span>
                    <ng-container [ngSwitch]="interval">
                      <ng-container *ngSwitchCase="'month'">
                          <span class="displaySmall"
                                *ngSwitchCase="'month'">{{ price.unit_amount / 100 }}</span>
                        <span class="bodyMedium colorPrimary80">/month</span>
                      </ng-container>
                      <ng-container *ngSwitchCase="'year'">
                        <span class="displaySmall">{{ price.unit_amount / 100 / 12 | roundDigits }}</span>
                        <span class="bodyMedium colorPrimary80">/month</span>
                        <div class="bodySmall colorNeutral50" *ngSwitchCase="'year'">
                          ${{ price.unit_amount / 100 }} per year
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                  <mat-divider></mat-divider>
                  <ul class="features bodySmall">
                    <li>Everything from previous</li>
                    <li>Get {{product.metadata['creditsPerTick']}} credits every {{product.metadata['tickIntervalInMinutes']}} minutes</li>
                    <li>Up to {{product.metadata['maxCredits']}} credits maximum</li>
                    <li *ngFor="let feature of getProductFeatures(product.role)">{{feature}}</li>
                  </ul>
                  <div class="subscribe-button">
                    <button [disabled]="loading || !! currentSubscription()" mat-flat-button color="primary">
                      <ng-container *ngIf="!isCurrentSubscription(product)">Select</ng-container>
                      <ng-container *ngIf="isCurrentSubscription(product)">Current plan</ng-container>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <a href="mailto:contact@symbiotai.com" class="product">
        <div class="titleLarge colorNeutral80">Enterprise</div>
        <mat-divider></mat-divider>
        <div class="price-container">
          <div class="price">
            <span class="headlineSmall colorPrimary80">Contact us</span>
          </div>
          <mat-divider></mat-divider>
          <ul class="features bodySmall">
            <li>Custom contract</li>
            <li>Charge AI models per usage</li>
            <li>Advanced support</li>
          </ul>
          <div class="subscribe-button">
            <a href="mailto:contact@symbiotai.com" mat-flat-button color="primary">
              Contact us
            </a>
          </div>
        </div>
      </a>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>

