export type Credits = {
  lastUpdatedTick: Date;
  remainingCredits: number;
  subscriptionPlan: CreditsSubscriptionPlan;
};

export function isCredits(obj: any): obj is Credits {
  return (
    obj.lastUpdatedTick !== undefined &&
    obj.remainingCredits !== undefined &&
    obj.subscriptionPlan !== undefined
  );
}

export type CreditsSubscriptionPlan = {
  maxCredits: number;
  creditsPerTick: number;
  tickIntervalInMinutes: number;
};

export function isCreditsSubscriptionPlan(obj: any): obj is CreditsSubscriptionPlan {
  return (
    obj.maxCredits !== undefined &&
    obj.creditsPerTick !== undefined &&
    obj.tickIntervalInMinutes !== undefined
  );
}

export const freePlanCredits: CreditsSubscriptionPlan = {
  maxCredits: 15,
  creditsPerTick: 0.04,
  tickIntervalInMinutes: 10,
};
