import {Injectable} from "@angular/core";
import {Subject, takeUntil} from "rxjs";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Destroyer} from "../components/_helpers/Destroyer";
import {AuthService} from "../components/auth/auth.service";
import {AiPromptService, PromptNotFoundError} from "./ai-prompt.service";
import {AlertService} from "./alert.service";
import {newLoadingState} from "../../../.common/observable-helpers";
import {AiPrompt, isAiPrompt} from "../../../.common";
import {AiApi} from "../../../.common/ai-models";
import {aiApis} from "../../../.common/ai-models/all-api";

@Injectable({
  providedIn: "root",
})
export class AiApiService extends Destroyer {
  public prompt$ = newLoadingState<AiPrompt>(null);
  public selectedAiApi$ = newLoadingState<AiApi>(null);
  private availableAiApis: AiApi[] = Object.values(aiApis);
  public readonly parameters: Subject<Params> = new Subject<Params>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private aiPromptService: AiPromptService,
    private alertService: AlertService,
  ) {
    super();
    this.parameters.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params["promptId"]) {
        this.prompt$.next("loading");
        this.selectedAiApi$.next("loading");
        this.loadPrompt(params["promptId"]);
      } else {
        this.prompt$.next(null);
        this.selectedAiApi$.next(null);
      }
    });
    this.onPrompt();
  }

  private loadPrompt(promptId: string) {
    this.aiPromptService
      .get(promptId)
      .then((prompt) => {
        this.prompt$.next(prompt);
      })
      .catch((e) => {
        if (e instanceof PromptNotFoundError) {
          this.prompt$.next("not-found");
        } else {
          throw e;
        }
      });
  }

  private onPrompt() {
    this.prompt$.pipe(takeUntil(this.destroy$)).subscribe((prompt) => {
      if (isAiPrompt(prompt)) {
        const aiApis = this.availableAiApis.filter((aiApi) => aiApi.id === prompt.api);
        if (aiApis.length === 1) {
          this.selectedAiApi$.next(aiApis[0]);
        } else {
          this.selectedAiApi$.next(null);
          this.alertService.error("No AI API found for prompt", prompt.displayName || prompt.id);
          console.error("No AI API found for prompt", prompt);
        }
      }
    });
  }
}
