import {inject, Injectable} from "@angular/core";
import {NotificationSettings} from "../../../../.common";
import {UserCollectionProvider} from "../../../../.common/collections/user-collection-provider";
import {Store} from "@ngrx/store";
import {authFeature} from "../auth/state/auth.state";

@Injectable({providedIn: "root"})
export class NotificationService {
  #user = inject(Store).selectSignal(authFeature.selectUser);
  #userCollectionProvider = inject(UserCollectionProvider);

  setEmailNotificationPreferences(settings: NotificationSettings) {
    const u = this.#user();
    if (!u) {
      throw new Error("You're not logged in");
    }
    return this.#userCollectionProvider.getUserNotificationDoc(u.id, "email").set(settings);
  }
}
